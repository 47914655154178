import { defineStyleConfig } from '@chakra-ui/react';

export const SpinnerTheme = defineStyleConfig({
  baseStyle: {
   color: 'primary.main',
  },
  variants: {
    bold: {
      borderWidth: '4px',
    },
  },
  defaultProps: {
    size: 'xl',
    variant:'bold'
  },
});
