//CustomeContext.js - Context API for Customer Data Management, loading customer on load using axios api, and has two actions: createCustomer and updateCustomer

import { useToast } from '@chakra-ui/react';
import { useDescope } from '@descope/react-sdk';
import { createContext, useCallback, useEffect, useState } from 'react';

import {
  completeCustomerOnboardingApi,
  getLoggedInCustomer,
  loginCustomerApi,
} from '../api/customerApi';
import { setGACustomer } from '../ga';

export const CustomerContext = createContext();

const CustomerProvider = ({ isAuthenticated, children }) => {
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useDescope();

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const toast = useToast();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      getLoggedInCustomer()
        .then((customer) => {
          setCustomer(customer);
          setGACustomer(customer?.id, customer?.companyName);
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            handleLogout();
            return;
          }
          toast({
            title: 'Error',
            description: 'Failed to load customer.',
            status: 'error',
          });
          throw error;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const loginCustomer = useCallback(() => {
    setIsLoading(true);
    loginCustomerApi()
      .then((customer) => {
        setGACustomer(customer?.id, customer?.companyName);
        setCustomer(customer);
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Failed to login customer.',
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);

  const completeCustomerOnboarding = useCallback(
    ({ customerId, name, companyName, approvedTerms }) => {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        setGACustomer(companyName);
        completeCustomerOnboardingApi({
          customerId,
          name,
          companyName,
          approvedTerms,
        })
          .then((_customer) => {
            setCustomer(_customer);
            resolve(_customer);
          })
          .catch((error) => {
            toast({
              title: 'Error',
              description: 'Failed to update customer.',
              status: 'error',
            });
            reject(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    },

    [customer]
  );

  return (
    <CustomerContext.Provider
      value={{
        customer,
        isCustomerLoading: isLoading,
        loginCustomer,
        completeCustomerOnboarding,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
