if (process.env.REACT_APP_VERSION) {
  console.log(
    `%cTwelve React App: ${process.env.REACT_APP_VERSION}`,
    'color: #C9FE37; font-size: 20px'
  );
}

const checkVersion = async () => {
  const response = await fetch('/version.json');
  const data = await response.json();
  const currentVersion = data.version;
  if (currentVersion !== process.env.REACT_APP_VERSION) {
    // New version detected, reload the page
    window.location.reload();
  }
};

if (window.location.hostname !== 'localhost') {
  setInterval(checkVersion, 60000); // Check every minute
}
