import { createIcon } from '@chakra-ui/react';

export const FundingIcon = createIcon({
  displayName: 'FundingIcon',
  viewBox: '0 0 30 30',

  path: (
    <>
      <path
        d="M8.1532 17.9387H0.661298C0.299907 17.9376 0.00618908 18.2298 0.00512695 18.5912V29.3477C0.00621876 29.7091 0.299918 30.0011 0.661298 30H8.1532C8.51459 30.0011 8.80853 29.7091 8.8096 29.3477V18.5912C8.80851 18.2298 8.51459 17.9376 8.1532 17.9387Z"
        fill="#E0E8F0"
      />
      <path
        d="M29.0172 23.1565L20.6432 27.7657C19.5793 28.3483 18.3864 28.6544 17.1736 28.6562H10.1133V19.4476C11.4422 19.0508 12.8671 19.1209 14.1504 19.6465C14.7896 19.9206 15.2754 20.1586 15.6668 20.3479C17.0134 21.0036 17.294 21.1406 20.0234 20.8373C20.1009 20.8294 20.1793 20.8351 20.2551 20.8534C20.8545 21.0106 21.36 21.4133 21.6473 21.9625C21.7155 22.1106 21.7615 22.2678 21.7845 22.4289L27.1128 19.9206C27.4621 19.7503 27.8517 19.6793 28.2389 19.7155C28.6261 19.7518 28.9955 19.8937 29.3073 20.1261C29.6369 20.3822 29.8683 20.7438 29.9629 21.1504C30.0424 21.5419 29.9935 21.949 29.8229 22.3104C29.6526 22.6718 29.3698 22.9685 29.0172 23.1563L29.0172 23.1565Z"
        fill="#E0E8F0"
      />
      <path
        d="M24.5284 5.0058C24.3238 4.88985 24.0729 4.89312 23.8713 5.0141C19.8237 7.44408 16.7394 5.15472 16.6226 5.06629C12.345 1.60761 7.67611 5.01563 7.62971 5.05056C7.46485 5.17372 7.36768 5.36763 7.36768 5.57355V15.7498C7.36768 15.9966 7.50699 16.2224 7.72754 16.3331C7.94809 16.4436 8.21209 16.4205 8.40993 16.2728C8.44836 16.2442 12.3049 13.4291 15.813 16.2654C17.0764 17.1498 18.5827 17.621 20.1248 17.6147C21.6896 17.5946 23.2186 17.143 24.543 16.3093C24.7395 16.1914 24.8596 15.9791 24.8596 15.7498V5.57355C24.8596 5.33838 24.7332 5.12154 24.5288 5.00582L24.5284 5.0058ZM18.2523 11.6502C18.1886 11.9776 18.0335 12.2802 17.8051 12.523C17.5767 12.7661 17.2841 12.9392 16.9613 13.0229V13.5094H15.6568V12.9659C15.1472 12.7589 14.7209 12.3883 14.4449 11.9125L15.5646 11.2432H15.5644C15.6474 11.4154 15.7789 11.5598 15.9428 11.6585C16.1066 11.757 16.2957 11.8057 16.4868 11.7982C16.6781 11.7725 16.8491 11.6657 16.9561 11.5052C16.9781 11.3063 16.9799 11.2901 16.6765 11.189C16.4446 11.1121 16.2488 11.0567 16.0811 11.0091C15.5535 10.8595 15.0973 10.7304 14.7515 10.2127C14.5228 9.8703 14.4407 9.45059 14.5242 9.04726C14.6106 8.6232 14.8613 8.25044 15.2216 8.01046C15.3557 7.92311 15.5025 7.85739 15.6568 7.81568V7.34794H16.9611V7.89756H16.9614C17.4883 8.09344 17.9241 8.47754 18.1844 8.97562L17.024 9.57198C16.8902 9.32719 16.66 9.14944 16.389 9.08219C16.2438 9.03218 16.0851 9.03764 15.9436 9.09725C15.8715 9.14464 15.8211 9.21866 15.8032 9.30317C15.789 9.3665 15.8005 9.43266 15.8357 9.48725C15.9032 9.5877 15.9536 9.61674 16.4369 9.75366C16.6203 9.80585 16.8345 9.86633 17.0878 9.95062C17.9543 10.2384 18.3463 10.8103 18.2524 11.6501L18.2523 11.6502Z"
        fill="#2084F9"
      />
      <path
        d="M27.5856 12.2322H26.2813L26.2811 3.13877C21.9348 5.13897 18.6858 2.685 18.5399 2.57148C15.0308 -0.264009 11.1737 2.5503 11.1357 2.5789L10.356 1.53315C10.4027 1.49822 15.0715 -1.90914 19.3494 1.54866C19.4662 1.63775 22.5495 3.92732 26.5976 1.49669H26.5978C26.7993 1.37571 27.0502 1.37265 27.2548 1.48839C27.4592 1.60412 27.5857 1.82096 27.5857 2.0559L27.5856 12.2322Z"
        fill="#2084F9"
      />
    </>
  ),
});
