import { AbsoluteCenter, Center } from '@chakra-ui/react';
import WhiteBox from '../components/WhiteBox';
import '../assets/scss/PrivacyPolicy.scss';
import '../assets/scss/TermsOfUse.scss';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div>
        <p class="c32" dir="rtl">
          <span class="c23">- </span>
          <span class="c17 c21">&nbsp;-</span>
        </p>
      </div>
      <p class="c16">
        <span class="c2 c12">TWELVE PRIVACY POLICY </span>
      </p>
      <p class="c16">
        <span class="c2 c30">Last Updated: </span>
        <span class="c4 c30">Jul 6</span>
        <span class="c2 c30">, 20</span>
        <span class="c26 c2">23</span>
      </p>
      <p class="c16">
        <span class="c2">
          In order to ensure transparency and give you more control over your
          personal information, this privacy policy (“
        </span>
        <span class="c2 c12">Privacy Policy</span>
        <span class="c2">
          ”) governs how we, Twelve Cap, Inc. and its affiliated entities
        </span>
        <span class="c4">&nbsp;</span>
        <span class="c2">&nbsp;(“</span>
        <span class="c2 c12">Twelve</span>
        <span class="c2">”, “</span>
        <span class="c2 c12">we</span>
        <span class="c2">”, “</span>
        <span class="c2 c12">our</span>
        <span class="c2">” or “</span>
        <span class="c2 c12">us</span>
        <span class="c2">
          ”) use, collect and store personal information that we collect or
          receive from or about you (“
        </span>
        <span class="c2 c12">you</span>
        <span class="c2">”) in connection with </span>
        <span class="c14 c4">
          <a
            class="c6"
            href="http://www.twelvegrow.com"
          >
            www.twelvegrow.com
          </a>
        </span>
        <span class="c2">&nbsp;(“</span>
        <span class="c2 c12">Website</span>
        <span class="c2">”), and the services provided therein (the “</span>
        <span class="c2 c12">Services</span>
        <span class="c1">”):</span>
      </p>
      <p class="c16">
        <span class="c2">
          Please read this Privacy Policy carefully, so you can fully understand
          our practices in relation to personal information. “
        </span>
        <span class="c19 c2 c12">Important note</span>
        <span class="c2 c12">:</span>
        <span class="c2">
          &nbsp;Nothing in this Privacy Policy is intended to limit in any way
          your statutory rights, including your rights to a remedy or other
          means of enforcement.{' '}
        </span>
      </p>
      <p class="c16">
        <span class="c19 c2 c12">Table of contents</span>
        <span class="c2 c12">: </span>
      </p>
      <ol class="c18 lst-kix_list_2-0 start" start="1">
        <li class="c3 li-bullet-0">
          <span class="c1">
            What information we collect, why we collect it, and how it is used
          </span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c1">
            How we protect and retain your personal information
          </span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c1">How we share your personal information</span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c1">Your privacy rights </span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c2">Use by </span>
          <span class="c4">individuals</span>
          <span class="c1">; children</span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c1">Interaction with third party products</span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c1">Analytic tools/ Cookies</span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c1">Contact us</span>
        </li>
      </ol>
      <p class="c8">
        <span class="c1"></span>
      </p>
      <p class="c16">
        <span class="c4">
          Twelve’s mission is to provide Marketing Capital to eligible
          businesses. Marketing Capital is financing intended for the sole
          purpose of funding a business’s marketing budget to supercharge its
          growth.
        </span>
      </p>
      <p class="c22">
        <span class="c1">
          This Privacy Policy can be updated from time to time and, therefore,
          we ask you to check back periodically for the latest version of this
          Privacy Policy. &nbsp;If we implement material changes in the way we
          use your information, in a manner that is different from that stated
          at the time of collection, we will notify you by posting a notice on
          our Website or by other means and take any additional steps as
          required by applicable law.
        </span>
      </p>
      <p class="c8">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0 start" start="1">
        <li class="c20 li-bullet-1">
          <span class="c17 c2 c12 c11">
            WHAT INFORMATION WE COLLECT, WHY WE COLLECT IT, AND HOW IT IS USED
          </span>
        </li>
      </ol>
      <p class="c8">
        <span class="c17 c2 c12"></span>
      </p>
      <p class="c22">
        <span class="c19 c4 c12">(i) </span>
        <span class="c19 c2 c12">
          We Process the Following Personal Information:{' '}
        </span>
      </p>
      <ol class="c18 lst-kix_list_5-0 start" start="1">
        <li class="c9 li-bullet-1">
          <span class="c2 c12">Information You Provide Directly to Us.</span>
          <span class="c2">
            &nbsp;We collect personal and commercial information that you
            voluntarily provide us with, such
          </span>
          <span class="c2 c11">
            &nbsp;as full business name, email address an
          </span>
          <span class="c1">
            d any other information that you decide to provide us with, when you
            contact us.
          </span>
        </li>
        <li class="c9 li-bullet-1">
          <span class="c2 c12 c11">Information from Other Sources.</span>
          <span class="c2 c11">
            &nbsp;Twelve may also obtain information about you from other
            sources, including publicly - or commercially- available
            information, and through third-party data platforms, partners and
            service providers.{' '}
          </span>
        </li>
        <li class="c9 li-bullet-1">
          <span class="c2 c12 c11">Automatic Data Collection.</span>
          <span class="c2 c11">
            &nbsp;We may automatically collect certain information through your
            use of the Services, such as your Internet protocol (IP) address,
            cookie identifiers
          </span>
          <span class="c4 c11">&nbsp;</span>
          <span class="c1 c11">
            and other device identifiers that are automatically assigned to your
            device, browser type and language, geo-location information,
            hardware type, operating system, internet service provider and other
            information about actions taken through the use of the Services.{' '}
          </span>
        </li>
      </ol>
      <p class="c7">
        <span class="c1 c11"></span>
      </p>
      <p class="c15">
        <span class="c2 c11">(collectively, the</span>
        <span class="c2 c12 c11">&nbsp;“Information</span>
        <span class="c1 c11">”)</span>
      </p>
      <p class="c8">
        <span class="c1 c25"></span>
      </p>
      <p class="c8 c31">
        <span class="c1"></span>
      </p>
      <p class="c22">
        <span class="c19 c4 c12">(ii) </span>
        <span class="c19 c2 c12">
          We process information for the following purposes:
        </span>
        <span class="c1">&nbsp;</span>
      </p>
      <ol class="c18 lst-kix_list_5-0" start="4">
        <li class="c9 li-bullet-1">
          <span class="c2 c12">To provide you with the Services</span>
          <span class="c2">
            . Twelve will use your information to provide you with the Services.
          </span>
          <span class="c23">&nbsp;</span>
          <span class="c1">
            For example, when you contact us, we will use your personal
            information to process and answer your request.{' '}
          </span>
        </li>
      </ol>
      <ol class="c18 lst-kix_list_6-0 start" start="1">
        <li class="c9 li-bullet-1" id="h.gjdgxs">
          <span class="c2 c12">For</span>
          <span class="c2">&nbsp;</span>
          <span class="c2 c12">Administrative Purposes</span>
          <span class="c1">
            . Twelve may use your information (i) to respond to your questions,
            comments, and other requests for customer support, or information,
            including information about potential or future services; (ii) for
            internal quality control purposes; (iii) to establish a business
            relationship; and (iv) to generally administer the Services,
            including but limited to requesting supplementary data to evaluate
            your funding application;{' '}
          </span>
        </li>
        <li class="c9 li-bullet-2">
          <span class="c2 c12">To Market the Services.</span>
          <span class="c1">
            &nbsp;Twelve may use information to market the Services. Such use
            includes (i) notifying you about offers and services that may be of
            interest to you; (ii) tailoring content, advertisements, and offers
            for you, including, targeting and re-targeting practices; (iii)
            conducting market research (iv) developing and marketing new
            products and services, and to measure interest in Twelve's services;
            (v) other purposes disclosed at the time you provide information;
            and (vi) as you otherwise consent;{' '}
          </span>
        </li>
        <li class="c9 li-bullet-2">
          <span class="c2 c12">Security purposes</span>
          <span class="c1">
            . Some of the abovementioned information will be used for detecting,
            taking steps to prevent and prosecuting fraud or other illegal
            activity; to identify and repair errors; to conduct audits; and for
            security purposes. Information may also be used to comply with
            applicable laws, with investigations performed by the relevant
            authorities, law enforcement purposes, and/or to exercise or defend
            legal claims.{' '}
          </span>
        </li>
        <li class="c9 li-bullet-1">
          <span class="c2 c12">
            De-identified and Aggregated Information Use
          </span>
          <span class="c1">
            . In certain cases, we may or will anonymize or de-identify your
            Information and further use it for internal and external purposes,
            including, without limitation, to analyze and improve the Services
            (including through the use of artificial intelligence) and for
            research purposes. We may use this anonymous or de-identified
            information and/or disclose it to third parties without restrictions
            (for example, in order to improve our services and enhance your
            experience with them and/or to develop new product features and
            improve existing offerings).{' '}
          </span>
        </li>
        <li class="c9 li-bullet-1">
          <span class="c2 c12">Cookies and Similar Technologies</span>
          <span class="c2">
            . We, as well as third parties that provide content, advertising, or
            other functionality on the Services, may use cookies, pixel tags,
            local storage, and other technologies (“
          </span>
          <span class="c2 c12">Technologies</span>
          <span class="c1">
            ”) to automatically collect information through the Services. We use
            Technologies that are essentially small data files placed on your
            device that allow us to record certain pieces of information
            whenever you visit or interact with the Services. If you would like
            to opt out of the Technologies we employ on the Services or the
            Website, you may do so by blocking, deleting, or disabling them as
            your browser or device permits.{' '}
          </span>
        </li>
      </ol>
      <p class="c8 c31">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="2">
        <li class="c20 li-bullet-2">
          <span class="c2 c12">
            HOW WE PROTECT AND RETAIN YOUR PERSONAL INFORMATION{' '}
          </span>
        </li>
      </ol>
      <p class="c8 c28">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-1 start" start="1">
        <li class="c13 li-bullet-3">
          <span class="c2 c19">Security</span>
          <span class="c1">
            . We have implemented and maintain reasonable technical,
            organizational and security measures designed to protect the
            Information. However, please note that we cannot guarantee that the
            Information will not be compromised as a result of unauthorized
            penetration to our servers. As the security of information depends
            in part on the security of the computer, device or network you use
            to communicate with us and the security you use to protect your user
            IDs and passwords, please make sure to take appropriate measures to
            protect this information.{' '}
          </span>
        </li>
        <li class="c13 li-bullet-4">
          <span class="c19 c2">Retention of your information</span>
          <span class="c1">
            . The Information will be stored until we delete our records, and we
            proactively delete it, or if you send a valid deletion request.
            Please note that in some circumstances we may store the Information
            for longer periods of time, for example (i) where we are required to
            do so in accordance with legal, regulatory, tax or
            accounting&nbsp;requirements, or (ii) for us to have an accurate
            record of your dealings with us in the event of any complaints or
            challenges, and/or (iii) if we reasonably believe there is a
            prospect of litigation relating to the Information or dealings.{' '}
          </span>
        </li>
      </ol>
      <p class="c8 c28">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="3">
        <li class="c20 li-bullet-1">
          <span class="c2">&nbsp;</span>
          <span class="c2 c12">HOW WE SHARE YOUR PERSONAL INFORMATION </span>
        </li>
      </ol>
      <p class="c8">
        <span class="c17 c2 c12"></span>
      </p>
      <p class="c22">
        <span class="c1">
          In addition to the recipients described above, we may share some
          Information as follows:{' '}
        </span>
      </p>
      <ol class="c18 lst-kix_list_3-1" start="3">
        <li class="c13 li-bullet-3">
          <span class="c1">
            The information Twelve gathers is shared with our partners and other
            third parties.{' '}
          </span>
        </li>
        <li class="c13 li-bullet-3">
          <span class="c1">
            We may also share information with our affiliated companies about
            you.{' '}
          </span>
        </li>
        <li class="c13 li-bullet-5">
          <span class="c1">
            We may use third party service providers to process the Information
            for the purposes outlined above, including, without limitation:{' '}
          </span>
        </li>
      </ol>
      <ol class="c18 lst-kix_list_3-2 start" start="1">
        <li class="c10 li-bullet-6">
          <span class="c1">
            With cloud service providers for hosting purposes;
          </span>
        </li>
        <li class="c10 li-bullet-7">
          <span class="c1">
            With websites and web content creation platforms in order to help us
            manage our Website;
          </span>
        </li>
        <li class="c10 li-bullet-8">
          <span class="c1">
            With email providers, marketing, CRM, other similar tool providers;{' '}
          </span>
        </li>
        <li class="c10 li-bullet-8">
          <span class="c2">
            With analytic companies, in order to help us understand and analyze
            information we collect in accordance with this policy
          </span>
          <span class="c4">.</span>
        </li>
      </ol>
      <ol class="c18 lst-kix_list_3-1" start="6">
        <li class="c13 li-bullet-3">
          <span class="c2">
            Twelve uses Plaid Inc. (“Plaid”) to gather your data from financial
            institutions. By using the Service, you grant Twelve and Plaid the
            right, power, and authority to act on your behalf to access and
            transmit your personal and financial information from your relevant
            financial institution. You agree to your personal and financial
            information being transferred, stored, and processed by Plaid in
            accordance with the Plaid end user{' '}
          </span>
          <span class="c14 c4">
            <a
              class="c6"
              href="https://plaid.com/legal/#end-user-privacy-policy"
            >
              privacy policy
            </a>
          </span>
          <span class="c14 c4">.</span>
        </li>
        <li class="c13 li-bullet-3">
          <span class="c1">
            To the extent necessary, with regulators, courts, banks or competent
            authorities, to comply with applicable laws, regulations and rules
            (including, without limitation, federal, state or local laws), and
            requests of law enforcement, regulatory and other governmental
            agencies or if required to do so by court order, as well as for
            internal compliance procedures and to protect the safety, security,
            and integrity of Twelve, our Services, customers, employees,
            property, and the public.
          </span>
        </li>
        <li class="c13 li-bullet-3">
          <span class="c1">
            If, in the future, we sell or transfer, or we consider selling or
            transferring, some or all of our business, shares or assets to a
            third party, we will disclose the Information to such third party
            (whether actual or potential) in connection with the foregoing
            events (including, without limitation, our current or potential
            investors). In the event that we are acquired by, or merged with, a
            third party entity, or in the event of bankruptcy or a comparable
            event, we reserve the right to transfer, disclose or assign your
            information in connection with the foregoing events.
          </span>
        </li>
        <li class="c13 li-bullet-3">
          <span class="c1">
            Where you have otherwise provided your consent to us for sharing or
            transferring your information.
          </span>
        </li>
      </ol>
      <p class="c8">
        <span class="c17 c2 c12"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="4">
        <li class="c20 li-bullet-1">
          <span class="c2 c12">YOUR PRIVACY RIGHTS</span>
        </li>
      </ol>
      <ol class="c18 lst-kix_list_3-1 start" start="1">
        <li class="c13 li-bullet-9">
          <span class="c2 c12">Rights</span>
          <span class="c2">
            : You can exercise your rights by contacting us at{' '}
          </span>
          <span class="c4">privacy@twelvegrow.com</span>
          <span class="c2">
            . Subject to legal and other permissible considerations, we will
            make every reasonable effort to honor your request promptly in
            accordance with applicable law or inform you if we need further
            information in order to{' '}
          </span>
          <span class="c4">fulfil</span>
          <span class="c1">
            &nbsp;your request. When processing your request, we may ask you for
            additional information to confirm or verify your identity and for
            security purposes, before processing and/or honoring your request.
            We reserve the right to charge a fee where permitted by law, for
            instance if your request is manifestly unfounded or excessive. In
            the event that your request would adversely affect the rights and
            freedoms of others (for example, would impact the duty of
            confidentiality we owe to others) or if we are legally entitled to
            deal with your request in a different way than requested, we will
            address your request to the maximum extent possible, all in
            accordance with applicable law. &nbsp;
          </span>
        </li>
      </ol>
      <p class="c8 c24">
        <span class="c2 c12 c17"></span>
      </p>
      <ol class="c18 lst-kix_list_3-1" start="2">
        <li class="c13 li-bullet-3">
          <span class="c2 c12">Marketing emails – opt-out</span>
          <span class="c1">
            : You may choose not to receive marketing email of this type by
            using the unsubscribe link found at the bottom of the email to opt
            out of receiving future emails, and we will process your request
            within a reasonable time after receipt.{' '}
          </span>
        </li>
      </ol>
      <p class="c22 c24">
        <span class="c17 c2 c12">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="5">
        <li class="c20 li-bullet-1">
          <span class="c2 c12">USE BY INDIVIDUALS</span>
          <span class="c4 c12">; </span>
          <span class="c2 c12">CHILDREN. </span>
          <span class="c2">
            We do not offer our products or services for use by{' '}
          </span>
          <span class="c4">individuals</span>
          <span class="c2">&nbsp;(we only offer </span>
          <span class="c4">Marketing Capital to</span>
          <span class="c2">&nbsp;businesses</span>
          <span class="c4">), including </span>
          <span class="c2">
            children and, therefore, we do not knowingly collect information
            from, and/or about{' '}
          </span>
          <span class="c4">individuals</span>
          <span class="c2">
            , including children under the age of 18. If you are an{' '}
          </span>
          <span class="c4">individual, or </span>
          <span class="c2">
            under the age of 18, do not provide any information to us without
            the involvement of a parent or a guardian. In the event that we
            become aware that you provide information in violation of applicable
            privacy laws, we reserve the right to delete it. If you believe that
            we might have any such information, please contact us at{' '}
          </span>
          <span class="c14 c4">
            <a class="c6" href="mailto:privacy@twelve.com">
              privacy@twelve.com
            </a>
          </span>
          <span class="c1">.</span>
        </li>
      </ol>
      <p class="c8 c24">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="6">
        <li class="c20 li-bullet-1">
          <span class="c2 c12">INTERACTION WITH THIRD PARTY PRODUCTS. </span>
          <span class="c2">
            We enable you to interact with third party websites, mobile software
            applications and products or services that are not owned, or
            controlled, by us (each, a “
          </span>
          <span class="c2 c12">Third Party Service</span>
          <span class="c1">
            ”). We are not responsible for the privacy practices or the content
            of such Third Party Services. Please be aware that Third Party
            Services can collect information from you. Accordingly, we encourage
            you to read the terms and conditions and privacy policies of each
            Third Party Service.
          </span>
        </li>
      </ol>
      <p class="c7">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="7">
        <li class="c27 c24 li-bullet-1">
          <span class="c2 c12">ANALYTIC TOOLS/COOKIES</span>
          <span class="c2">&nbsp;</span>
        </li>
      </ol>
      <ul class="c18 lst-kix_list_4-0 start">
        <li class="c5 li-bullet-2">
          <span class="c2 c12">Google Analytics</span>
          <span class="c2">. The Website uses a tool called “</span>
          <span class="c2 c12">Google Analytics</span>
          <span class="c2">
            ” to collect information about use of the Website. Google Analytics
            collects information such as how often users visit this Website,
            what pages they visit when they do so, and what other websites they
            used prior to coming to this Website. We use the information we get
            from Google Analytics to maintain and improve the Website and our
            products. We do not combine the information collected through the
            use of Google Analytics with information we collect. Google’s
            ability to use and share information collected by Google Analytics
            about your visits to this Website is restricted by the Google
            Analytics Terms of Service, available at{' '}
          </span>
          <span class="c14 c4">
            <a
              class="c6"
              href="https://marketingplatform.google.com/about/analytics/terms/us"
            >
              https://marketingplatform.google.com/about/analytics/terms/us/
            </a>
          </span>
          <span class="c2">
            , and the Google Privacy Policy, available at&nbsp;
          </span>
          <span class="c4 c14">
            <a
              class="c6"
              href="http://www.google.com/policies/privacy"
            >
              http://www.google.com/policies/privacy/
            </a>
          </span>
          <span class="c2">
            . You may learn more about how Google collects and processes data
            specifically in connection with Google Analytics at{' '}
          </span>
          <span class="c14 c4">
            <a
              class="c6"
              href="http://www.google.com/policies/privacy/partners"
            >
              http://www.google.com/policies/privacy/partners/
            </a>
          </span>
          <span class="c2">
            . You may prevent your data from being used by Google Analytics by
            downloading and installing the Google Analytics Opt-out Browser
            Add-on, available at{' '}
          </span>
          <span class="c14 c4">
            <a
              class="c6"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              https://tools.google.com/dlpage/gaoptout/
            </a>
          </span>
          <span class="c2">. </span>
        </li>
      </ul>
      <ul class="c18 lst-kix_list_1-0 start">
        <li class="c5 li-bullet-2">
          <span class="c2">
            We reserve the right to remove or add new analytic tools, cookies,
            pixels and other tracking technologies
          </span>
          <span class="c1">.</span>
        </li>
      </ul>
      <p class="c7">
        <span class="c1"></span>
      </p>
      <ol class="c18 lst-kix_list_3-0" start="8">
        <li class="c24 c27 li-bullet-1">
          <span class="c2 c12">CONTACT US. </span>
          <span class="c2">
            If you have any questions, concerns or complaints regarding our
            compliance with this notice and the data protection laws, or if you
            wish to exercise your rights, we encourage you to first contact us
            at{' '}
          </span>
          <span class="c14 c4">
            <a class="c6" href="mailto:privacy@twelve.com">
              privacy@twelve.com
            </a>
          </span>
          <span class="c2">.</span>
        </li>
      </ol>
    </div>
  );
}

function TermsOfUse() {
  return (
    <div class={'terms-of-use'}>
      <p class="c10">
        <span class="c2 c0">Twelve Terms of Use</span>
      </p>
      <p class="c18">
        <span class="c4">
          Welcome to twelvegrow.com (together with its subdomains, Content,
          Marks and services, the “
        </span>
        <span class="c0">Site</span>
        <span class="c4">
          ”). Please read the following Terms of Use carefully before using this
          Site so that you are aware of your legal rights and obligations with
          respect to{' '}
        </span>
        <span class="c4 c12">Twelve Cap, Inc. and its affiliated entities</span>
        <span class="c4">&nbsp;(“</span>
        <span class="c0">Twelve</span>
        <span class="c4">”, “</span>
        <span class="c0">we</span>
        <span class="c4">”, “</span>
        <span class="c0">our</span>
        <span class="c4">” or “</span>
        <span class="c0">us</span>
        <span class="c4">
          ”). By accessing or using the Site, you expressly acknowledge and
          agree that you are entering a legal agreement with us and have
          understood and agree to comply with, and be legally bound by, these
          Terms of Use, together with our Privacy Policy (as defined below) (the
          “
        </span>
        <span class="c0">Terms</span>
        <span class="c2 c3">
          ”). You hereby waive any applicable rights to require an original
          (non-electronic) signature or delivery or retention of non-electronic
          records, to the extent not prohibited under applicable law. If you do
          not agree to be bound by these Terms please do not access or use the
          Site.{' '}
        </span>
      </p>
      <ol class="c6 lst-kix_list_1-0 start" start="1">
        <li class="c7 li-bullet-0">
          <span class="c0">Background. </span>
          <span class="c4">
            The Site is intended to be a platform for eligible businesses to
            apply for and receive financing from Twelve, intended for the sole
            purpose of funding their marketing budget &nbsp;(“
          </span>
          <span class="c0">Marketing Capital</span>
          <span class="c4">
            ”). . To apply for Marketing Capital, you need to submit an online
            application on this Site. The application includes an onboarding
            questionnaire, which you must answer accurately and truthfully. We
            may request additional information as part of our verification
            process or to better assess your application. Please note that all
            Marketing Capital offers shall be contingent on you and us entering
            into legally bounding agreements specifying the terms and conditions
            of such transactions. Should we decide to offer you Marketing
            Capital, formal documentation of such an offer will follow for your
            approval. For the avoidance of doubt, Twelve shall not be committed
            to provide any financing hereunder.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Modification. </span>
          <span class="c4">
            We reserve the right, at our discretion, to change these Terms at
            any time. Such change will be effective 10 days following posting of
            the revised Terms on the Site, and your continued use of the Site
            thereafter means that you accept those changes.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Ability to Accept Terms.</span>
          <span class="c4">
            &nbsp;The Site is only intended for businesses registered in the
            United States. If you are not a business please do not visit or use
            this Site. If you have business located outside the United States,
            do not use this Site, rather contact us to see if we can accommodate
            your business needs. Marketing Capital offers shall only be provided
            to businesses operating in jurisdictions where Twelve may offer and
            provide Marketing Capital according to applicable rules and
            regulations.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Site Access.</span>
          <span class="c4">
            &nbsp;For such time as these Terms are in effect, we hereby grant
            you permission to visit and use the Site provided that you comply
            with these Terms and applicable law.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Restrictions.</span>
          <span class="c4">
            &nbsp;You shall not: (i) copy, distribute or modify any part of the
            Site without our prior written authorization; (ii) use, modify,
            create derivative works of, transfer (by sale, resale, license,
            sublicense, download or otherwise), reproduce, distribute, display
            or disclose Content (defined below), except as expressly authorized
            herein; (iii) disrupt servers or networks connected to the Site;
            (iv) use or launch any automated system (including without
            limitation, “robots” and “spiders”) to access the Site; and/or (v)
            circumvent, disable or otherwise interfere with security-related
            features of the Site or features that prevent or restrict use or
            copying of any Content or that enforce limitations on use of the
            Site.{' '}
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Account.</span>
          <span class="c4">
            &nbsp;In order to use some of the services of the Site, you may have
            to create an account (“
          </span>
          <span class="c0">Account</span>
          <span class="c4">
            ”). You agree not to create an Account for anyone else or use the
            account of another without their permission. When creating your
            Account, you must provide accurate and complete information. You are
            solely responsible for the activity that occurs in your Account, and
            you must keep your Account password secure. You must notify Twelve
            immediately of any breach of security or unauthorized use of your
            Account. As between you and Twelve, you are solely responsible and
            liable for the activity that occurs in connection with your Account.
            If you wish to delete your Account you may send an email request to
            Twelve at{' '}
          </span>
          <span class="c4">
            <a class="c5" href="mailto:info@twelvegrow.com">
              info@twelvegrow.com
            </a>
          </span>
          <span class="c4">. </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Payments to Twelve.</span>
          <span class="c4">
            &nbsp;Except as expressly set forth in the Terms, your general right
            to access and use the Site is currently for free, but Twelve may in
            the future charge a fee for certain access or usage. You will not be
            charged for any such access or use of the Site unless you first
            agree to such charges, but please be aware that any failure to pay
            applicable charges may result in you not having access to some or
            all of the Site.{' '}
          </span>
        </li>
        <li class="c7 li-bullet-0" id="h.gjdgxs">
          <span class="c0">Intellectual Property Rights</span>
          <span class="c4">&nbsp;</span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-1 start" start="1">
        <li class="c7 li-bullet-0">
          <span class="c9 c4">Content and Marks</span>
          <span class="c4">
            . The: (i) content on the Site, including without limitation, the
            text, documents, articles, brochures, descriptions, products,
            software, graphics, photos, sounds, videos, interactive features,
            and services (collectively, the “
          </span>
          <span class="c0">Content</span>
          <span class="c4">
            ”); and (ii) the trademarks, service marks and logos contained
            therein (“
          </span>
          <span class="c0">Marks</span>
          <span class="c4">
            ”), are the property of Twelve and/or its licensors and may be
            protected by applicable copyright or other intellectual property
            laws and treaties. “Twelve”, the Twelve logo, and other marks are
            Marks of Twelve or its affiliates. All other trademarks, service
            marks, and logos used on the Site are the trademarks, service marks,
            or logos of their respective owners. We reserve all rights not
            expressly granted in and to the Site and the Content.{' '}
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c4 c9">Use of Content</span>
          <span class="c4">
            . Content on the Site is provided to you for your information and
            personal use only and may not be used, modified, copied,
            distributed, transmitted, broadcast, displayed, sold, licensed,
            de-compiled, or otherwise exploited for any other purposes
            whatsoever without our prior written consent. If you download or
            print a copy of the Content you must retain all copyright and other
            proprietary notices contained therein.{' '}
          </span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-0" start="9">
        <li class="c7 li-bullet-0">
          <span class="c0">Information Description.</span>
          <span class="c2 c3">
            &nbsp;We attempt to be as accurate as possible. However, we cannot
            and do not warrant that the Content available on the Site is
            accurate, complete, reliable, current, or error-free. We reserve the
            right to make changes in or to the Content, or any part thereof, in
            our sole judgment, without the requirement of giving any notice
            prior to or after making such changes to the Content. Your use of
            the Content, or any part thereof, is made solely at your own risk
            and responsibility.
          </span>
        </li>
        <li class="c7 li-bullet-0" id="h.30j0zll">
          <span class="c0">Disclosure.</span>
          <span class="c2 c3">
            &nbsp;We reserve the right to access, read, preserve, and disclose
            any information that we obtain in connection with the Site, and your
            use thereof, as we reasonably believe is necessary to: (i) satisfy
            any applicable law, regulation, legal process, subpoena or
            governmental request; (ii) enforce these Terms, including to
            investigate potential violations of them; (iii) detect, prevent or
            otherwise address fraud, security or technical issues; (iv) respond
            to user support requests; or (v) protect the rights, property or
            safety of Twelve, its users or the public.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Links</span>
          <span class="c2 c3">&nbsp;</span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-1 start" start="1">
        <li class="c7 li-bullet-0">
          <span class="c4">
            The Site may contain links, and may enable you to post content, to
            third party websites (“
          </span>
          <span class="c0">Third Party Websites</span>
          <span class="c2 c3">
            ”) that are not owned or controlled by Twelve. We are not affiliated
            with, have no control over, and assume no responsibility for the
            content, privacy policies, or practices of, any Third Party
            Websites. You: (i) are solely responsible and liable for your use of
            and linking to Third Party Websites and any content that you may
            send or post to a Third Party Website; and (ii) expressly release
            Twelve from any and all liability arising from your use of any Third
            Party Website. Accordingly, we encourage you to read the terms and
            conditions and privacy policy of each Third Party Website that you
            may choose to visit.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c4">Twelve </span>
          <span class="c4 c13">permits you to link to the Site </span>
          <span class="c2 c3">
            provided that: (i) you link to but do not replicate any page on this
            Site; (ii) the hyperlink text shall accurately describe the Content
            as it appears on the Site; (iii) you shall not misrepresent your
            relationship with Twelve or present any false information about
            Twelve and shall not imply in any way that we are endorsing any
            services or products, unless we have given you our express prior
            consent; (iv) you shall not link from a Third Party Website which
            prohibits linking to third parties; (v) such Third Party Website
            does not contain content that (a) is offensive or controversial
            (both at our discretion), or (b) infringes any intellectual
            property, privacy rights, or other rights of any person or entity;
            and/or (vi) you, and your website, comply with these Terms and
            applicable law.{' '}
          </span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-0" start="12">
        <li class="c7 li-bullet-0" id="h.1fob9te">
          <span class="c0">Privacy.</span>
          <span class="c4">
            &nbsp;We will use any personal information that we may collect or
            obtain in connection with the Site in accordance with Twelve’s
            privacy policy (“
          </span>
          <span class="c0">Privacy Policy</span>
          <span class="c2 c3">
            ”). You agree that we may use personal information that you provide
            or make available to us in accordance with Privacy Policy.
          </span>
        </li>
        <li class="c7 li-bullet-0" id="h.3znysh7">
          <span class="c0">Warranty Disclaimers</span>
          <span class="c2 c3">&nbsp;</span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-1 start" start="1">
        <li class="c7 li-bullet-0">
          <span class="c2 c3">
            This section applies whether or not the services provided under the
            Site are for payment. Applicable law may not allow the exclusion of
            certain warranties, so to that extent certain exclusions set forth
            herein may not apply.{' '}
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c2 c3">
            THE SITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND
            WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TWELVE
            HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS
            FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING BY
            STATUTE OR FROM A COURSE OF DEALING OR USAGE OF TRADE. TWELVE DOES
            NOT GUARANTEE THAT THE SITE WILL BE FREE OF BUGS, SECURITY BREACHES,
            OR VIRUS ATTACKS. THE SITE MAY OCCASIONALLY BE UNAVAILABLE FOR
            ROUTINE MAINTENANCE, UPGRADING, OR OTHER REASONS. YOU AGREE THAT
            TWELVE WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR
            ANY THIRD PARTY THAT MAY RESULT FROM TECHNICAL PROBLEMS OF THE
            INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR
            OTHER SERVERS. WE DO NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT,
            PRODUCT, OR SERVICE THAT IS FEATURED OR ADVERTISED ON THE SITE BY A
            THIRD PARTY.{' '}
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c2 c3">
            EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, TWELVE DOES NOT
            MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND,
            EXPRESS OR IMPLIED, AS TO THE SECURITY OF ANY INFORMATION YOU MAY
            PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF YOUR USE OF
            THE SITE.{' '}
          </span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-0" start="14">
        <li class="c7 li-bullet-0" id="h.2et92p0">
          <span class="c0">Limitation of Liability</span>
          <span class="c4">&nbsp;</span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-1 start" start="1">
        <li class="c7 li-bullet-0">
          <span class="c4">
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, TWELVE SHALL NOT BE LIABLE
            FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR
            INCIDENTAL DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA, REVENUE,
            PROFITS OR REPUTATION, ARISING UNDER THESE TERMS OR OUT OF YOUR USE
            OF, OR INABILITY TO USE, THE SITE, EVEN IF TWELVE HAS BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES. Some jurisdictions do
            not allow the limitation or exclusion of liability for incidental or
            consequential damages, so the above limitations may not apply to
            you.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c4">
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF TWELVE FOR ANY DAMAGES
            ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR INABILITY TO
            USE, THE SITE EXCEED THE TOTAL AMOUNT OF FEES, IF ANY, PAID BY YOU
            TO TWELVE FOR USING THE SITE DURING THE 3 MONTHS PRIOR TO BRINGING
            THE CLAIM.{' '}
          </span>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_1-0" start="15">
        <li class="c7 li-bullet-0" id="h.tyjcwt">
          <span class="c0">Indemnity.</span>
          <span class="c4">
            &nbsp;You agree to defend, indemnify and hold harmless Twelve and
            our affiliates, and our respective officers, directors, employees
            and agents, from and against any and all claims, damages,
            obligations, losses, liabilities, costs and expenses (including but
            not limited to attorney’s fees) arising from: (i) your use of, or
            inability to use, the Site; (ii) your interaction with any Site
            user; or (iii) your violation of these Terms.
          </span>
          <span class="c0">&nbsp;</span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Term and Termination. </span>
          <span class="c4">
            These Terms are effective until terminated by Twelve or you. Twelve,
            in its sole discretion, has the right to terminate these Terms
            and/or your access to the Site, or any part thereof, immediately at
            any time and with or without cause (including, without any
            limitation, for a breach of these Terms). Twelve shall not be liable
            to you or any third party for termination of the Site, or any part
            thereof. If you object to any term or condition of these Terms, or
            any subsequent modifications thereto, or become dissatisfied with
            the Site in any way, your only recourse is to immediately
            discontinue use of the Site. Upon termination of these Terms, you
            shall cease all use of the Site.
          </span>
          <span class="c0">&nbsp;</span>
          <span class="c4">This Section and Sections ‎8 (</span>
          <span class="c4 c16">Intellectual Property Rights</span>
          <span class="c4">), ‎12 (</span>
          <span class="c16 c4">Privacy</span>
          <span class="c4">), ‎13 (</span>
          <span class="c16 c4">Warranty Disclaimers</span>
          <span class="c4">), ‎14 (</span>
          <span class="c16 c4">Limitation of Liability</span>
          <span class="c4">), ‎15 (</span>
          <span class="c16 c4">Indemnity</span>
          <span class="c4">), and ‎17 (</span>
          <span class="c16 c4">Independent Contractors</span>
          <span class="c4">) to ‎19 (</span>
          <span class="c16 c4">General</span>
          <span class="c4">) shall survive termination of these Terms.</span>
        </li>
        <li class="c7 li-bullet-0" id="h.3dy6vkm">
          <span class="c0">Independent Contractors.</span>
          <span class="c4">
            &nbsp;You and Twelve are independent contractors. Nothing in these
            Terms creates a partnership, joint venture, agency, or employment
            relationship between you and Twelve. You must not under any
            circumstances make, or undertake, any warranties, representations,
            commitments or obligations on behalf of Twelve.
          </span>
        </li>
        <li class="c7 li-bullet-0">
          <span class="c0">Assignment. </span>
          <span class="c4">
            These Terms, and any rights and licenses granted hereunder, may not
            be transferred or assigned by you but may be assigned by Twelve
            without restriction or notification to you.{' '}
          </span>
        </li>
        <li class="c7 li-bullet-0" id="h.1t3h5sf">
          <span class="c0">General. </span>
          <span class="c4">
            Twelve reserves the right to discontinue or modify any aspect of the
            Site at any time. These Terms and the relationship between you and
            Twelve shall be governed by and construed in accordance with the
            laws of the State of New York, without regard to its principles of
            conflict of laws. You agree to submit to the personal and exclusive
            jurisdiction of the courts located in New York and waive any
            jurisdictional, venue, or inconvenient forum objections to such
            courts, provided that Twelve may seek injunctive relief in any court
            of competent jurisdiction. These Terms shall constitute the entire
            agreement between you and Twelve concerning the Site. If any
            provision of these Terms is deemed invalid by a court of competent
            jurisdiction, the invalidity of such provision shall not affect the
            validity of the remaining provisions of these Terms, which shall
            remain in full force and effect. No waiver of any term of these
            Terms shall be deemed a further or continuing waiver of such term or
            any other term, and a party’s failure to assert any right or
            provision under these Terms shall not constitute a waiver of such
            right or provision. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY
            HAVE ARISING OUT OF OR RELATED TO THE SITE MUST COMMENCE WITHIN 1
            YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
            ACTION IS PERMANENTLY BARRED.
          </span>
          <span class="c2 c0">&nbsp;</span>
        </li>
      </ol>
      <p class="c8 c20">
        <span class="c2 c0"></span>
      </p>
      <p class="c8">
        <span class="c0 c12">Last updated: June</span>
        <span class="c0">,</span>
        <span class="c2 c0">&nbsp;2023 </span>
      </p>
      <p class="c11">
        <span class="c2 c3"></span>
      </p>
      <p class="c11">
        <span class="c2 c3"></span>
      </p>
      <div>
        <p class="c21">
          <span class="c2 c17">&nbsp;</span>
        </p>
        <p class="c1">
          <span class="c2 c15"></span>
        </p>
      </div>
    </div>
  );
}

export default function TermsAndPrivacy({ isPrivacy, isTerms }) {
  return (
    <Center>
      <WhiteBox maxW={1260} mt={100}>
        {isPrivacy && <PrivacyPolicy />}
        {isTerms && <TermsOfUse />}
      </WhiteBox>
    </Center>
  );
}
