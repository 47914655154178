export const generateSelectOptions = ({ metaObj, excludeArr, isBool }) => {
  const options = Object.keys(metaObj).map((key) => ({
    label: metaObj[key].label,
    value: isBool ? key === 'true' : key,
  }));

  if (excludeArr) {
    return options.filter((option) => !excludeArr.includes(option.value));
  }

  return options;
};
