export const headingStyles = {
  components: {
    Heading: {
      baseStyle: {},
      variants: {
        sectionHeader: () => ({
          w: 'max',
          //   mx: '20px',
          fontSize: '20px',
          fontWeight: 800,
          borderBottomWidth: '1px',
          pb: 2,
        }),
        tearsheetSection: () => ({
          fontSize: '16px',
          fontWeight: 800,
          bg: '#f1f7fe',
          borderRadius: 6,
          px: '10px',
          py: '5px',
        }),
      },
    },
  },
};
