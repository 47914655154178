import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const controlStyles = {
  boxSize: '18px',
};

const disabledStyle = {
  bg: 'gray.100',
  opacity: 1,
};

const baseStyle = definePartsStyle({
  control: {
    ...controlStyles,
    _checked: {
      ...controlStyles,
      bg: 'primary.link',
      borderColor: 'primary.link',
      _disabled: {
        ...controlStyles,
        color: 'white',
        borderColor: 'primary.link',
        bg: 'primary.link',
        opacity: 0.4,
      },
    }
  },
  label: {
    fontSize: 'sm',
  },
});

const sizes = {
  md: definePartsStyle({
    label: {
      fontSize: 'sm',
    }
  })
   
};

export const CheckboxTheme = defineMultiStyleConfig({ baseStyle, sizes });
