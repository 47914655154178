import { Box, Flex, useRadio, useRadioGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const RadioCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" w={'full'} textAlign={'center'} fontSize={'sm'}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={'4px'}
        boxShadow={'0px 0px 8px 0px rgba(0, 0, 0, 0.12)'}
        color={'secondaryGray.900'}
        py={'6px'}
        px={'16px'}
        _checked={{
          bg: 'white',
          fontWeight: '800',
          _hover: {
            bg: 'white',
          },
        }}
        _hover={{
          bg: 'secondaryGray.400',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

function RadioCardInput({ options, name, value, onChange }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  });

  const group = getRootProps();

  return (
    <Flex {...group} bg={'secondaryGray.100'} p={'2px'} borderRadius={'5px'}>
      {options.map((option) => {
        const { value, label } = option;
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {label}
          </RadioCard>
        );
      })}
    </Flex>
  );
}

RadioCardInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioCardInput;
