import {
  INSTALLMENT_OWNER_TYPE,
  INSTALLMENT_TYPE,
} from './installmentConstants';

export const POLICY_SECTION_ENUM = {
  MINIMAL_REQUIREMENTS: `minimalRequirements`,
  CREDIT_EVALUATION: `creditEvaluation`,
  MARKETING_AND_FINANCIAL_DATA: `marketingAndFinancialData`,
  DEAL_TERMS: `dealTerms`,
  KYC_KYB: `kycKyb`,
  PIER_REQUIREMENTS: `pierRequirements`,
};

export const POLICY_SECTIONS = [
  {
    id: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    name: `Minimal Requirements`,
    policy: 'Business must be ...',
  },
  {
    id: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    name: `Credit Evaluation`,
    policy: 'Business must have ...',
  },
  {
    id: POLICY_SECTION_ENUM.MARKETING_AND_FINANCIAL_DATA,
    name: `Marketing and Financial Data`,
    policy: 'Business must provide and maintain ...',
  },
  {
    id: POLICY_SECTION_ENUM.DEAL_TERMS,
    name: `Deal Terms`,
    policy: 'Deal must be ...',
  },
  {
    id: POLICY_SECTION_ENUM.KYC_KYB,
    name: `KYC/KYB`,
  },
  {
    id: POLICY_SECTION_ENUM.PIER_REQUIREMENTS,
    name: `PIER Requirements`,
  },
];

export const POLICY_QUESTION_ENUM = {
  // MINIMAL_REQUIREMENTS
  REGISTERED_IN: 'registeredIn',
  IN_GOOD_STANDING: 'inGoodStanding',
  FDIC_BANK_ACCOUNT: 'fdicBankAccount',
  NOT_UNDERGOING_BANKRUPTCY: 'notUndergoingBankruptcy',
  NOT_ENGAGED_IN_ILLEGAL_ACTIVITY: 'notEngagedInIllegalActivity',
  NOT_APPEAR_ON_OFAC_LIST: 'notAppearOnOfacList',
  NOT_MANUFACTURING: 'notManufacturing',
  NOT_PROMOTION_CONTRADICTORY_VALUES: 'notPromotionContradictoryValues',
  LIEN_FILED: 'lienFiled',
  // CREDIT_EVALUATION
  MIN_AVG_DAILY_BANK_BALANCE: 'minAvgDailyBankBalance',
  MIN_AVG_MONTHLY_INCOME: 'minAvgMonthlyIncome',
  MIN_ESTIMATED_ANNUAL_INCOME: 'minEstimatedAnnualIncome',
  MIN_ESTIMATE_AVG_MONTHLY_REVENUE_GROWTH: 'minEstimateAvgMonthlyRevenueGrowth',
  MIN_ESTIMATE_CASH_RESERVES_RUNWAY: 'minEstimateCashReservesRunway',
  MAX_ESTIMATED_CAC_PAYBACK: 'maxEstimatedCacPayback',
  MIN_TIME_IN_BUSINESS: 'minTimeInBusiness',
  MIN_TIME_SINCE_LAST_OWNERSHIP_CHANGE: 'minTimeSinceLastOwnershipChange',
  MIN_TIME_SINCE_LAST_BANKRUPTCY_EVENT: 'minTimeSinceLastBankruptcyEvent',
  MAX_EXISTING_ALTERNATIVE_CREDIT_RELATIONSHIPS:
    'maxExistingAlternativeCreditRelationships',
  MAX_DAYS_WITH_NEGATIVE_BANK_EVENTS: 'maxDaysWithNegativeBankEvents',
  QUALIFIES_FOR_MONTHLY_ADVANCE: 'qualifiesForMonthlyAdvance',
  // MARKETING_AND_FINANCIAL_DATA
  MIN_LAST_3_MONTHS_MARKETING_PERFORMANCE: 'minLast3MonthsMarketingPerformance',
  MIN_LAST_3_MONTHS_OPERATIONAL_BANK_ACCOUNT_INFO:
    'minLast3MonthsOperationalBankAccountInfo',
  MIN_LAST_3_MONTHS_SALES_PERFORMANCE: `minLast3MonthsSalesPerformance`,
  MIN_LAST_3_QUARTERLY_FINANCIALS: `minLast3QuarterlyFinancials`,
  // DEAL_TERMS
  BETWEEN_10K_AND_750K: 'between10kAnd750k',
  BETWEEN_2K_AND_2M: 'between2kAnd2m',
  AFTER_RECEIVING_FUNDS: 'afterReceivingFunds',
  PRODUCT_INTEREST_AND_FEES: 'productInterestAndFees',
  // KYC KYB
  PASSED_KYC: 'passedKyc',
  PASSED_KYB: 'passedKyb',
  // Pier Requirements
  SPV_ASSET_MINIMUM: 'spvAssetMinimum',
  SPV_ASSET_INITIAL_MAXIMUM: 'spvAssetInitialMaximum',
  SPV_ASSET_NOT_IN_DEFAULT: 'spvAssetNotInDefault',
  SPV_ASSET_NOT_PAST_DUE: 'spvAssetNotPastDue',
  SPV_ASSET_ORIGINATED_PURSUANT: 'spvAssetOriginatedPursuant',
  SPV_DRAW_AT_LEAST_5M_BY_SEPTEMBER: 'spvDrawAtLeast5mBySeptember',
  SPV_DRAW_MIN_7_DAYS_AFTER_PREVIOUS: 'spvDrawMin7DaysAfterPrevious',
  SPV_DRAW_BETWEEN_100K_AND_1_5M_MONTHLY:
    'spvDrawBetween100kAnd1Point5mMonthly',
  SPV_BORROWING_BASE_CERTIFICATE: 'spvBorrowingBaseCertificate',
  SPV_DACA_CASH_LIMIT_20_PERCENT: 'spvDacaCashLimit20Percent',
  SPV_ELIGIBILITY_CRITERIA: 'spvEligibilityCriteria',
  SPV_NOTICES_TO_500K: 'spvNoticesTo500k',
  SPV_PORTFOLIO_CRITERIA_OUTSTANDING_LIMIT:
    'spvPortfolioCriteriaOutstandingLimit',
  SPV_ASSET_MAX_ADVANCE_500K: 'spvAssetMaxAdvance500k',
  SPV_ASSET_USD_DENOMINATED: 'spvAssetUsdDenominated',
  SPV_EQUITY_CONDITION_SATISFIED: 'spvEquityConditionSatisfied',
  SPV_BORROWER_SOLE_OWNER: 'spvBorrowerSoleOwner',
  SPV_LENDER_VALID_FIRST_PRIORITY_SECURITY_INTEREST:
    'spvLenderValidFirstPrioritySecurityInterest',
  SPV_MERCHANT_NOT_BANKRUPTCY_PAST_10_YEARS:
    'spvMerchantNotBankruptcyPast10Years',
  SPV_MERCHANT_US_PRINCIPAL_PLACE_OF_BUSINESS:
    'spvMerchantUsPrincipalPlaceOfBusiness',
  SPV_MERCHANT_NOT_NATURAL_PERSON: 'spvMerchantNotNaturalPerson',
  SPV_ASSET_LEGAL_BINDING_OBLIGATION: 'spvAssetLegalBindingObligation',
  SPV_ASSET_NO_RESCISSION_SETOFF_DEFENSE: 'spvAssetNoRescissionSetoffDefense',
  SPV_MERCHANT_US_ENTITY_VALID_EXISTING: 'spvMerchantUsEntityValidExisting',
  SPV_MERCHANT_LOCATED_WITHIN_US_JURISDICTION:
    'spvMerchantLocatedWithinUsJurisdiction',
};

const MINIMAL_REQUIREMENTS_QUESTION_TEXT = {
  [POLICY_QUESTION_ENUM.REGISTERED_IN]: `registered in Israel, UK,  EU, USA, Canada, Australia`,
  [POLICY_QUESTION_ENUM.IN_GOOD_STANDING]: `If US business - in good standing`,
  [POLICY_QUESTION_ENUM.FDIC_BANK_ACCOUNT]: `FDIC bank account or international equivalent`,
  [POLICY_QUESTION_ENUM.NOT_UNDERGOING_BANKRUPTCY]: `Not undergoing bankruptcy or dissolution processes`,
  [POLICY_QUESTION_ENUM.NOT_ENGAGED_IN_ILLEGAL_ACTIVITY]: `Not engaged in potentially Illegal activity (local or federal)`,
  [POLICY_QUESTION_ENUM.NOT_APPEAR_ON_OFAC_LIST]: `Not appear on OFAC list`,
  [POLICY_QUESTION_ENUM.NOT_MANUFACTURING]: `Not manufacturing, selling, marketing or promoting any of the following industries:
        1. Money Services Business (MSB) 
        2. Firearms Vendor 
        3. Adult Entertainment/ Materials 
        4. Massage Parlors 
        5. Prostitution or escort services 
        6. Production, sale, or distribution of marijuana 
        7. Production, sale, or distribution of guns, accessories, ammunition, and other weapons 
        8. Sale of counterfeit or "gray market" goods or services 
        9. Drug Dispensaries 
        10. Horoscope/ Fortune Telling 
        11. Government & Non-Profits, Public Administration 
        12. Religious, Civic, or Political Organizations 
        13. Multi-Level Marketing`,
  [POLICY_QUESTION_ENUM.NOT_PROMOTION_CONTRADICTORY_VALUES]: `Not promoting contradictory values to Twelve:
        1. violence, hate, discrimination based on race, gender, ethnicity or sexual orientation
        2. Activities that are illegal or risky (Twelve's discretion as to what is "risky")
        3. Against Twelve terms of Use
    `,
  [POLICY_QUESTION_ENUM.LIEN_FILED]: `Lien filed (in Israel - within 21 days)`,
};

const CREDIT_EVALUATION_QUESTION_TEXT = {
  [POLICY_QUESTION_ENUM.MIN_AVG_DAILY_BANK_BALANCE]: `Min avg daily bank balance of >$15K over the last 3 months`,
  [POLICY_QUESTION_ENUM.MIN_AVG_MONTHLY_INCOME]: `Min avg monthly income of >$10K over the last 3 months`,
  [POLICY_QUESTION_ENUM.MIN_ESTIMATED_ANNUAL_INCOME]:
    'Min estimated annual income requirement of >$120K',
  [POLICY_QUESTION_ENUM.MIN_ESTIMATE_AVG_MONTHLY_REVENUE_GROWTH]:
    'Min estimate avg monthly revenue growth of 2%',
  [POLICY_QUESTION_ENUM.MIN_ESTIMATE_CASH_RESERVES_RUNWAY]:
    'Min estimate cash reserves runway of months',
  [POLICY_QUESTION_ENUM.MAX_ESTIMATED_CAC_PAYBACK]:
    'Max estimated CAC Payback of 18 months',
  [POLICY_QUESTION_ENUM.MIN_TIME_IN_BUSINESS]:
    'Min time in business of 6 months',
  [POLICY_QUESTION_ENUM.MIN_TIME_SINCE_LAST_OWNERSHIP_CHANGE]:
    'Min time since last ownership change (if one occurred) of 6 months',
  [POLICY_QUESTION_ENUM.MIN_TIME_SINCE_LAST_BANKRUPTCY_EVENT]:
    'Min time since last bankruptcy event (if one occurred) of 10 years',
  [POLICY_QUESTION_ENUM.MAX_EXISTING_ALTERNATIVE_CREDIT_RELATIONSHIPS]:
    'Max 4 existing alternative credit relationships',
  [POLICY_QUESTION_ENUM.MAX_DAYS_WITH_NEGATIVE_BANK_EVENTS]:
    'Max 9 days with negative bank events over the last 3 months',
  [POLICY_QUESTION_ENUM.QUALIFIES_FOR_MONTHLY_ADVANCE]:
    'Qualifies for a monthly Advance of at least $10K based on Company evaluation',
};

const MARKETING_AND_FINANCIAL_DATA_QUESTION_TEXT = {
  [POLICY_QUESTION_ENUM.MIN_LAST_3_MONTHS_MARKETING_PERFORMANCE]:
    'Min last 3 months of marketing performance on preapproved platform',
  [POLICY_QUESTION_ENUM.MIN_LAST_3_MONTHS_OPERATIONAL_BANK_ACCOUNT_INFO]:
    'Min last 3 months of operational Bank account info',
  [POLICY_QUESTION_ENUM.MIN_LAST_3_MONTHS_SALES_PERFORMANCE]:
    'Min last 3 months of sales performance on preapproved platform',
  [POLICY_QUESTION_ENUM.MIN_LAST_3_QUARTERLY_FINANCIALS]:
    'Min last 3 quarterly financials',
};

const DEAL_TERMS_QUESTION_TEXT = {
  [POLICY_QUESTION_ENUM.BETWEEN_10K_AND_750K]: 'Advance limit is between $10k and $750k',
  [POLICY_QUESTION_ENUM.BETWEEN_2K_AND_2M]: 'Outstanding balance limit is between $2k and $2M',
  [POLICY_QUESTION_ENUM.AFTER_RECEIVING_FUNDS]:
    'Payment date is between 7 days - 14 months after receiving funding',
  [POLICY_QUESTION_ENUM.PRODUCT_INTEREST_AND_FEES]:
    'Total of the following fees, if applicable, does not exceed the equivalent of 40% APR:  outstanding balance fee, utilization fee, failed repayment fee, delinquency fee, product interest fee and fees based on minimum payment',
};

const KYC_KYB_QUESTION_TEXT = {
  [POLICY_QUESTION_ENUM.PASSED_KYC]: 'Passed KYC',
  [POLICY_QUESTION_ENUM.PASSED_KYB]: 'Passed KYB',
};

const PIER_REQUIREMENTS_QUESTION_TEXT = {
  [POLICY_QUESTION_ENUM.SPV_ASSET_MINIMUM]:
    'The Asset has a minimum fourteen percent (14.00%) initial IRR target',
  [POLICY_QUESTION_ENUM.SPV_ASSET_INITIAL_MAXIMUM]:
    'The Asset has an initial maximum expected term of fourteen (14) months',
  [POLICY_QUESTION_ENUM.SPV_ASSET_NOT_IN_DEFAULT]:
    'The Asset is not in default as defined by the internal underwriting policies of Borrower, Parent, or Servicer which have been reviewed and agreed to by Lender and shall not be amended without Lender’s prior written consent',
  [POLICY_QUESTION_ENUM.SPV_ASSET_NOT_PAST_DUE]:
    'The Asset was not past due at the time of its inclusion as an Eligible Asset under this Agreement',
  [POLICY_QUESTION_ENUM.SPV_ASSET_ORIGINATED_PURSUANT]:
    'The Asset was originated pursuant to Parent’s or Twelve Cap’s underwriting guidelines and in compliance with Applicable Law, and all servicing and collections activities in respect of the Asset by Borrower or Servicer on behalf of Borrower have been undertaken in material compliance with Applicable Law and with Accepted Servicing Practices',
  [POLICY_QUESTION_ENUM.SPV_DRAW_AT_LEAST_5M_BY_SEPTEMBER]:
    'At least $5M draw by September 26, 2024 (270 days post effective date) otherwise commitment amount is $10M',
  [POLICY_QUESTION_ENUM.SPV_DRAW_MIN_7_DAYS_AFTER_PREVIOUS]:
    'At least 7 calendar days after a previous draw (not draw request)',
  [POLICY_QUESTION_ENUM.SPV_DRAW_BETWEEN_100K_AND_1_5M_MONTHLY]:
    'Between $100k each and up to $1.5M / month',
  [POLICY_QUESTION_ENUM.SPV_BORROWING_BASE_CERTIFICATE]:
    'Borrowing base certificate',
  [POLICY_QUESTION_ENUM.SPV_DACA_CASH_LIMIT_20_PERCENT]:
    'DACA cash does not exceed 20% of total outstanding principal of loan',
  [POLICY_QUESTION_ENUM.SPV_ELIGIBILITY_CRITERIA]: 'Eligibility Criteria',
  [POLICY_QUESTION_ENUM.SPV_NOTICES_TO_500K]:
    'Notices - up to $500,000 - 2 business days prior; over $500,000 - 4 business days prior',
  [POLICY_QUESTION_ENUM.SPV_PORTFOLIO_CRITERIA_OUTSTANDING_LIMIT]:
    'Portfolio Criteria - outstanding funded amount to a Merchant can’t exceed $850k',
  [POLICY_QUESTION_ENUM.SPV_ASSET_MAX_ADVANCE_500K]:
    'The Asset has a maximum advance amount of $500,000',
  [POLICY_QUESTION_ENUM.SPV_ASSET_USD_DENOMINATED]:
    'The Asset is denominated and payable only in U.S. dollars',
  [POLICY_QUESTION_ENUM.SPV_EQUITY_CONDITION_SATISFIED]:
    'Equity condition satisfied',
  [POLICY_QUESTION_ENUM.SPV_BORROWER_SOLE_OWNER]:
    'Borrower is the sole owner of all right, title, and interest in and to the Asset, free and clear of any liens or encumbrances',
  [POLICY_QUESTION_ENUM.SPV_LENDER_VALID_FIRST_PRIORITY_SECURITY_INTEREST]:
    'Lender has a valid, perfected, first priority security interest in the Asset.',
  [POLICY_QUESTION_ENUM.SPV_MERCHANT_NOT_BANKRUPTCY_PAST_10_YEARS]:
    'The applicable Merchant did not file a proceeding and was not granted a discharge under the U.S. Bankruptcy Code in the ten (10) years preceding the date of its inclusion as an Eligible Asset under this Agreement',
  [POLICY_QUESTION_ENUM.SPV_MERCHANT_US_PRINCIPAL_PLACE_OF_BUSINESS]:
    'The applicable Merchant has its principal place of business in the United States and is duly organized and validly existing under the laws of its state of organization',
  [POLICY_QUESTION_ENUM.SPV_MERCHANT_NOT_NATURAL_PERSON]:
    'The applicable Merchant is not a natural person',
  [POLICY_QUESTION_ENUM.SPV_ASSET_LEGAL_BINDING_OBLIGATION]:
    'The Asset constitutes the legal, valid, and binding obligation of the applicable Merchant, enforceable in accordance with its terms, subject to receivership, bankruptcy, insolvency, moratorium, and other principles of equity affecting the rights of creditors generally, whether considered in a proceeding at law or in equity',
  [POLICY_QUESTION_ENUM.SPV_ASSET_NO_RESCISSION_SETOFF_DEFENSE]:
    'The Asset is not subject to any right of rescission, set off, valid counterclaim or defense (including, without limitation, the defense of usury), and no such right of rescission, set off, counterclaim, or defense has been asserted with respect thereto, and the operation of any of the terms of such Asset, or the exercise of any right thereunder, will not render such Asset unenforceable, in whole or in part, or subject to any right of rescission, set off, valid counterclaim, or defense, including without limitation the defense of usury',
  [POLICY_QUESTION_ENUM.SPV_MERCHANT_US_ENTITY_VALID_EXISTING]:
    'The Merchant corresponding to the Asset (i) is a duly organized and validly existing U.S. corporate entity, and (ii) has not (a) made a general assignment for the benefit of creditors, (b) filed any voluntary petition in bankruptcy or suffered the filing of any involuntary petition against them, (c) suffered the appointment of a receiver to take possession of all, or substantially all, of such Merchant’s assets, (d) suffered the attachment or other judicial seizure of all, or substantially all, of the Merchant’s assets, (e) admitted in writing their inability to pay their debts as they come due, or (f) made an offer or settlement, extension, or composition to their creditors generally',
  [POLICY_QUESTION_ENUM.SPV_MERCHANT_LOCATED_WITHIN_US_JURISDICTION]:
    'The Merchant is located within the U.S. and within a jurisdiction in which Parent, Twelve Cap, or Borrower, as applicable, has all applicable licenses required in connection with the Asset',
};

export const POLICY_QUESTION_TEXT = {
  ...MINIMAL_REQUIREMENTS_QUESTION_TEXT,
  ...CREDIT_EVALUATION_QUESTION_TEXT,
  ...MARKETING_AND_FINANCIAL_DATA_QUESTION_TEXT,
  ...DEAL_TERMS_QUESTION_TEXT,
  ...KYC_KYB_QUESTION_TEXT,
  ...PIER_REQUIREMENTS_QUESTION_TEXT,
};

const MINIMAL_REQUIREMENTS_QUESTIONS = [
  {
    id: POLICY_QUESTION_ENUM.REGISTERED_IN,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.IN_GOOD_STANDING,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.FDIC_BANK_ACCOUNT,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.NOT_UNDERGOING_BANKRUPTCY,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.NOT_ENGAGED_IN_ILLEGAL_ACTIVITY,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.NOT_APPEAR_ON_OFAC_LIST,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.NOT_MANUFACTURING,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.NOT_PROMOTION_CONTRADICTORY_VALUES,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.LIEN_FILED,
    section: POLICY_SECTION_ENUM.MINIMAL_REQUIREMENTS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
];

const CREDIT_EVALUATION_QUESTIONS = [
  {
    id: POLICY_QUESTION_ENUM.MIN_AVG_DAILY_BANK_BALANCE,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_AVG_MONTHLY_INCOME,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_ESTIMATED_ANNUAL_INCOME,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_ESTIMATE_AVG_MONTHLY_REVENUE_GROWTH,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_ESTIMATE_CASH_RESERVES_RUNWAY,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MAX_ESTIMATED_CAC_PAYBACK,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_TIME_IN_BUSINESS,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_TIME_SINCE_LAST_OWNERSHIP_CHANGE,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_TIME_SINCE_LAST_BANKRUPTCY_EVENT,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME],
  },
  {
    id: POLICY_QUESTION_ENUM.MAX_EXISTING_ALTERNATIVE_CREDIT_RELATIONSHIPS,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MAX_DAYS_WITH_NEGATIVE_BANK_EVENTS,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.QUALIFIES_FOR_MONTHLY_ADVANCE,
    section: POLICY_SECTION_ENUM.CREDIT_EVALUATION,
    installments: [INSTALLMENT_TYPE.FIRST_TIME],
  },
];

const MARKETING_AND_FINANCIAL_DATA_QUESTIONS = [
  {
    id: POLICY_QUESTION_ENUM.MIN_LAST_3_MONTHS_MARKETING_PERFORMANCE,
    section: POLICY_SECTION_ENUM.MARKETING_AND_FINANCIAL_DATA,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_LAST_3_MONTHS_OPERATIONAL_BANK_ACCOUNT_INFO,
    section: POLICY_SECTION_ENUM.MARKETING_AND_FINANCIAL_DATA,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_LAST_3_MONTHS_SALES_PERFORMANCE,
    section: POLICY_SECTION_ENUM.MARKETING_AND_FINANCIAL_DATA,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.MIN_LAST_3_QUARTERLY_FINANCIALS,
    section: POLICY_SECTION_ENUM.MARKETING_AND_FINANCIAL_DATA,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
];

const DEAL_TERMS_QUESTIONS = [
  {
    id: POLICY_QUESTION_ENUM.BETWEEN_10K_AND_750K,
    section: POLICY_SECTION_ENUM.DEAL_TERMS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.BETWEEN_2K_AND_2M,
    section: POLICY_SECTION_ENUM.DEAL_TERMS,
    installments: [INSTALLMENT_TYPE.SUBSEQUENT],
  },
  {
    id: POLICY_QUESTION_ENUM.AFTER_RECEIVING_FUNDS,
    section: POLICY_SECTION_ENUM.DEAL_TERMS,
    installments: [
      INSTALLMENT_TYPE.FIRST_TIME,
      INSTALLMENT_TYPE.SUBSEQUENT_AMENDED,
    ],
  },
  {
    id: POLICY_QUESTION_ENUM.PRODUCT_INTEREST_AND_FEES,
    section: POLICY_SECTION_ENUM.DEAL_TERMS,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.SUBSEQUENT],
  },
];

const KYC_KYB_QUESTIONS = [
  {
    id: POLICY_QUESTION_ENUM.PASSED_KYC,
    section: POLICY_SECTION_ENUM.KYC_KYB,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
  {
    id: POLICY_QUESTION_ENUM.PASSED_KYB,
    section: POLICY_SECTION_ENUM.KYC_KYB,
    installments: [INSTALLMENT_TYPE.FIRST_TIME, INSTALLMENT_TYPE.ANNUAL],
  },
];

const PIER_REQUIREMENTS_QUESTIONS = Object.keys(
  PIER_REQUIREMENTS_QUESTION_TEXT
).map((key) => {
  return {
    id: key,
    section: POLICY_SECTION_ENUM.PIER_REQUIREMENTS,
    text: POLICY_QUESTION_TEXT[POLICY_QUESTION_ENUM[key]],
    installments: [
      INSTALLMENT_TYPE.FIRST_TIME,
      INSTALLMENT_TYPE.SUBSEQUENT,
      INSTALLMENT_TYPE.ANNUAL,
      INSTALLMENT_TYPE.SUBSEQUENT_AMENDED,
      INSTALLMENT_TYPE.ANNUAL_AMENDED,
    ],
    owners: [INSTALLMENT_OWNER_TYPE.SPV],
  };
});

export const POLICY_QUESTIONS = [
  ...MINIMAL_REQUIREMENTS_QUESTIONS,
  ...CREDIT_EVALUATION_QUESTIONS,
  ...MARKETING_AND_FINANCIAL_DATA_QUESTIONS,
  ...DEAL_TERMS_QUESTIONS,
  ...KYC_KYB_QUESTIONS,
  ...PIER_REQUIREMENTS_QUESTIONS,
];
