import { Box, Image } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import logoSvg from '../assets/images/logo.svg';
import bgSvg from '../assets/images/onboarding-bg.svg';

function OnBoardingLayout() {
  return (
    <Box
      bgImage={bgSvg}
      bgRepeat="no-repeat"
      bgSize={'100%'}
      minW={'full'}
      bgColor={'#EFF7FF'}
      minH={'100vh'}
      bgPosition={'contain'}
      pb={5}
    >
      <Image src={logoSvg} position={'absolute'} top={[5, 50]} left={[5, 50]} />

      <Outlet />
    </Box>
  );
}

export default OnBoardingLayout;
