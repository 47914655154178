import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { CONNECTOR_STATUS } from '../../../constants/connectorConstants';

function ConnectorRow({
  name,
  icon,
  imgSrc,
  onConnectClick,
  status,
  detailsElement,
}) {
  return (
    <Box mb={4} py={4}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Flex alignItems={'center'}>
          {imgSrc ? (
            <Image src={imgSrc} boxSize={8} mr={4} />
          ) : (
            <Icon as={icon} boxSize={8} mr={4} />
          )}
          <Box>
            <Text fontSize={'lg'} fontWeight={700} ml={'10px'} m={0}>
              {name}
            </Text>
          </Box>
        </Flex>

        {status === CONNECTOR_STATUS.CONNECTED ? (
          <>
            <Flex align={'center'} gap={1}>
              <Icon
                as={CheckIcon}
                boxSize={4}
                bg={'green.200'}
                color={'white'}
                borderRadius={'50%'}
                p={'3px'}
              />
              <Text fontSize={'md'} fontWeight={700} color={'green.200'}>
                Connected
              </Text>
            </Flex>
          </>
        ) : (
          <Button
            fontSize={'md'}
            onClick={onConnectClick}
            variant={status === CONNECTOR_STATUS.BROKEN ? 'warning' : 'primary'}
          >
            {status === CONNECTOR_STATUS.BROKEN ? 'Reconnect' : 'Connect'}
          </Button>
        )}
      </Flex>
      {detailsElement && <Box ml={12}>{detailsElement}</Box>}
    </Box>
  );
}

ConnectorRow.propTypes = {
  name: PropTypes.string.isRequired,
  detailsElement: PropTypes.element,
  icon: PropTypes.func,
  onConnectClick: PropTypes.func,
  status: PropTypes.string,
};

export default ConnectorRow;
