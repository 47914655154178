import { Icon } from '@chakra-ui/react';

export default function BranchIcon(props) {
  return (
    <Icon width="380" height="380" viewBox="0 0 380 380" {...props}>
      <defs>
        <style>{`
            .cls-1 {
            fill: #333e48;
            }

            .cls-2 {
            fill: #009bde;
            }
            `}</style>
      </defs>
      <g>
        <path
          class="cls-1"
          d="M364.77,190.78C364.77,94,286.05,15.31,189.3,15.31S13.82,94,13.82,190.78c0,91.43,70.29,166.74,159.67,174.75l.43,0q2.2.19,4.41.33l.85.05q2,.12,4.06.18l1.07,0c1.66,0,3.32.08,5,.08s3.17,0,4.75-.07l.67,0q2.09-.06,4.16-.17l.28,0q4.67-.26,9.28-.77h0C296.22,355.64,364.77,281.07,364.77,190.78ZM207.85,348.46l-1.69-48.53,42.38-26a31.95,31.95,0,0,0,50-27.28,32.33,32.33,0,0,0-31-31,31.76,31.76,0,0,0-32.46,37l-29.91,18.5L203,210l41.71-25.51a37.6,37.6,0,1,0-14-21.09L202,181.22l-1.7-48.8a34.19,34.19,0,0,0,21.83-33.31,34.18,34.18,0,1,0-46.46,33.31l-3.1,101.11-31.25-19.91a37.17,37.17,0,0,0-2.81-25.36c-6.25-13-18.85-22.22-33.22-23.12a39.61,39.61,0,1,0,22.82,69.92l43.56,27.56-2.62,85.65c-78-9.95-138.58-76.78-138.58-157.49C30.53,103.24,101.75,32,189.3,32s158.77,71.22,158.77,158.77C348.07,272.05,286.68,339.24,207.85,348.46Z"
        />
        <g>
          <path
            class="cls-2"
            d="M188,79.85a20.73,20.73,0,1,0,20.73,20.73A20.75,20.75,0,0,0,188,79.85Z"
          />
          <path
            class="cls-2"
            d="M266.15,229.23A18.82,18.82,0,1,0,285,248,18.84,18.84,0,0,0,266.15,229.23Z"
          />
          <path
            class="cls-2"
            d="M277.75,174.63a22.15,22.15,0,1,0-29.61-10.21A22.17,22.17,0,0,0,277.75,174.63Z"
          />
          <path
            class="cls-2"
            d="M102.82,180.79a23.88,23.88,0,1,0,23.89,23.89A23.91,23.91,0,0,0,102.82,180.79Z"
          />
        </g>
      </g>
    </Icon>
  );
}
