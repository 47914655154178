import { defineStyleConfig } from '@chakra-ui/react';

export const WhiteBoxTheme = defineStyleConfig({
  // The styles all Cards have in common
  baseStyle: {
    paddingX: 6,
    paddingY: 10,
    bgColor: 'white',
    borderRadius: 12,
    boxShadow: 'box',
    position: 'relative',
  }
});
