import { Icon } from '@chakra-ui/react';

export default function XeroIcon(props) {
  return (
    <Icon viewBox="0 0 400 400" {...props}>
      <defs>
        <style>{`
      .xero-cls-1 {
        fill: #20c0e7;
      }

      .xero-cls-1, .xero-cls-2 {
        fill-rule: evenodd;
      }

      .xero-cls-2 {
        fill: #fff;
      }
        `}</style>
      </defs>
      <title>xero</title>
      <>
        <path
          className="xero-cls-1"
          d="M200,400c110.5,0,200-89.5,200-200S310.5,0,200,0,0,89.5,0,200,89.5,400,200,400"
        />
        <path
          className="xero-cls-2"
          d="M97.4,199.9l34.1-34.2a6.1,6.1,0,1,0-8.7-8.6l-34,34.1L54.6,157.1a6.1,6.1,0,1,0-8.6,8.7l34.1,34.1L46,234a6.1,6.1,0,0,0,4.3,10.5,6,6,0,0,0,4.3-1.8l34.1-34.2,34,34a6.1,6.1,0,1,0,8.7-8.5l-34-34.2Zm202.5,0A11.1,11.1,0,1,0,311,188.8a11.1,11.1,0,0,0-11.1,11.1Zm-21,0A32.1,32.1,0,1,1,311,232.1,32.2,32.2,0,0,1,278.9,199.9Zm-12.6,0A44.8,44.8,0,1,0,311,155.1a44.9,44.9,0,0,0-44.8,44.8Zm-3.2-44.1h-1.9a25.3,25.3,0,0,0-15.6,5.3,6.2,6.2,0,0,0-6-4.8,6,6,0,0,0-6,6.1V238a6.1,6.1,0,0,0,12.2,0V191.5c0-15.5,1.4-21.7,14.6-23.4H263c3.6-.1,6.2-2.6,6.2-6a6.1,6.1,0,0,0-6.1-6.1ZM145.9,192.6c0-.2,0-.4,0-.5a32.3,32.3,0,0,1,62.8.5H145.9Zm75.3-1.2c-2.6-12.5-9.4-22.7-19.8-29.3A44.8,44.8,0,1,0,172,244.6a42.2,42.2,0,0,0,16.1-1.1,44.1,44.1,0,0,0,13.4-5.7,48.4,48.4,0,0,0,11.5-10.9l.2-.2c2.4-3,2-7.3-.7-9.4s-6-2.4-9,1.4l-2.1,2.8a35.3,35.3,0,0,1-8.8,7.1,32.2,32.2,0,0,1-15,3.8c-17.8-.2-27.3-12.6-30.6-21.5a33.1,33.1,0,0,1-1.4-5.2v-.9h63.7c8.7-.2,13.4-6.4,12.1-13.4Z"
        />
      </>
    </Icon>
  );
}
