import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { CONNECTOR_META_DATA, CONNECTOR_TYPE } from '../../../constants/connectorConstants';
import ConnectorRow from './ConnectorRow';

function PlaidRow({ name, status, plaidAccounts, logoBase64, onConnectClick }) {
  const icon = CONNECTOR_META_DATA[CONNECTOR_TYPE.PLAID].icon;

  const AccountsDetails = () => {
    if (!plaidAccounts) {
      return null;
    }
    
    return (
      <Box>
        {plaidAccounts.map((account) => (
          <Box key={account.id} fontSize={'xs'} color={'gray.main'}>
            {account.name} - {account.mask}
          </Box>
        ))}
      </Box>
    );
  };

  const imgSrc = logoBase64 ? `data:image/jpeg;base64,${logoBase64}` : null;

  return (
    <ConnectorRow
      name={name}
      icon={icon}
      imgSrc={imgSrc}
      status={status}
      detailsElement={<AccountsDetails />}
      onConnectClick={onConnectClick}
    />
  );
}

PlaidRow.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  logoBase64: PropTypes.string,
  onConnectClick: PropTypes.func,
  plaidAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      mask: PropTypes.string.isRequired,
    })
  ),
};

export default PlaidRow;
