import { Icon } from '@chakra-ui/react';

export default function AdjustIcon(props) {
  return (
    <Icon
      viewBox="-153 -46 652 652"
      style={{ enableBackground: 'new -153 -46 652 652' }}
      {...props}
    >
      <defs>
        <style>{`.st0{fill:#00BCD4;}`}</style>
      </defs>
      <path
        id="XMLID_7_"
        className="st0"
        d="M123,292.6c0,11.8-7.7,17.1-15.4,17.1c-9.5,0-15.4-7.1-15.4-13.6c0-6.5,3.5-10.3,11-12.1
	l15.9-3.6c1.6-0.4,2.9-0.8,3.9-1.3V292.6z M135.1,293v-30.3c0-15.1-11.6-26-27.5-26C92.2,236.7,80,247.9,80,262v1.1
	c0,3.2,2.3,5.2,6.1,5.2c3.8,0,6.1-1.9,6.1-5.2v-0.8c0-8.2,6.8-14.7,15.4-14.7c8.9,0,15.4,6.4,15.4,15.3v3.8c0,1.1-1.6,2.2-4,2.7
	l-18.6,4.2C86.7,276.8,80,284.1,80,296.1c0,13.8,12.1,24.5,27.6,24.5C123.8,320.6,135.1,309.3,135.1,293 M199.5,292.8
	c0,10.4-6.2,17.1-15.7,17.1c-9.5,0-15.7-6.7-15.7-17.1v-27.8c0-10.4,6.2-17.1,15.7-17.1c9.5,0,15.7,6.7,15.7,17.1V292.8z
	 M211.6,293.5v-76.2c0-3.2-2.3-5.2-6.1-5.2c-3.8,0-6.1,1.9-6.1,5.2v26c-4.3-4-10.5-6.4-17.1-6.4c-13.2,0-26.5,9.4-26.5,27.2v29.3
	c0,18.7,14.4,27.2,27.9,27.2C197.2,320.8,211.6,312.2,211.6,293.5 M235.5,318.6c0,9.9-5,17.9-13.5,21.3c-2.4,1-4.5,3.6-2.9,7.6
	c0.9,2.5,2.9,3.9,5.4,3.9c0.8,0,1.7-0.2,2.4-0.4c13-4.6,20.8-16.4,20.8-31.7v-77.7c0-3.2-2.3-5.2-6.1-5.2c-3.8,0-6.1,1.9-6.1,5.2
	V318.6z M248.2,224.6v-6.1c0-3.7-2.6-6.2-6.4-6.2c-3.8,0-6.4,2.5-6.4,6.2v6.1c0,3.7,2.6,6.2,6.4,6.2
	C245.6,230.8,248.2,228.3,248.2,224.6 M325.5,293.7v-51.9c0-3.2-2.3-5.2-6.1-5.2s-6.1,1.9-6.1,5.2V293c0,10.4-6.2,17.1-15.7,17.1
	c-9.5,0-15.7-6.7-15.7-17.1v-51.2c0-3.2-2.3-5.2-6.1-5.2c-3.8,0-6.1,1.9-6.1,5.2v51.9c0,18.7,14.4,27.2,27.9,27.2
	C311.1,321,325.5,312.5,325.5,293.7 M358.5,258.6c0-6.6,5.6-11.2,13.6-11.2c8.3,0,14.6,5.3,14.6,12.4v0.4c0,3.2,2.3,5.2,6.1,5.2
	c3.8,0,6.1-1.9,6.1-5.2v-1.1c0-12.7-11.8-22.7-26.8-22.7c-14.7,0-25.7,9.6-25.7,22.3c0,12.2,7.8,20.2,23.9,24.2
	c12.2,3,17.4,7.4,17.4,14.8c0,7.2-5.4,11.6-14.2,11.6c-8.1,0-15-5.5-15-12.1v-0.6c0-3.2-2.3-5.2-6.1-5.2c-3.8,0-6.1,1.9-6.1,5.2v0.6
	c0,12.9,11.9,23,27.1,23c15.8,0,26.3-9.1,26.3-22.6c0-13.2-7.9-21.1-25.6-25.5C363.5,269.6,358.5,265.3,358.5,258.6 M439.4,298.1
	v-48.2h22.1c3,0,4.7-2,4.7-5.5c0-4-2.4-5.5-4.7-5.5h-22.1v-21.5c0-3.2-2.3-5.2-6.1-5.2c-3.8,0-6.1,1.9-6.1,5.2v21.5h-10.7
	c-3,0-4.7,2-4.7,5.5c0,3.4,1.8,5.5,4.7,5.5h10.7v48.8c0,13.3,9.8,22.6,23.9,22.6c5.3,0,10.5-1.5,14.3-4.2c2.7-2.1,3.3-5.2,1.3-7.9
	c-1.6-2.3-3.3-2.7-4.5-2.7c-1,0-2.1,0.4-3.1,1.1c-2.2,1.8-5.1,2.7-8.1,2.7C442.5,310.3,439.4,304,439.4,298.1"
      />
      <path
        id="XMLID_2_"
        className="st0"
        d="M-4.5,296.7l-6.5-4.7c-13.1-9.5-25-17.5-35.6-23.8l-2.3-1.6l15.4-34.7L-4.5,296.7z M4.7,319.7
	l10.1-10.1l-40.5-90.5h-15.2l-18.2,41.9l-2-1c-10.9-5.4-19.3-8-25.6-8c-8.2,0-11.9,4.5-13.4,7.1c-4.5,8-1.9,19.2,7.8,34.2
	c3.6,5.5,7.4,10.6,10.2,14.1h14.6l6-13.6l-6.3-14.2l-7.4,16.2l-2.5-3.2c-1.6-2.2-3.2-4.4-4.5-6.5c-8-12.2-9.2-18.5-7.4-20.8
	c1.7-2.1,11.7,0.7,22.7,6.1c6,2.7,12.2,6.1,18.3,10.3c4.4,2.7,9,5.7,13.8,9C-17.1,302.6-1.2,315.1,4.7,319.7 M41.4,275.4
	c0,44.5-36.2,80.8-80.7,80.8c-44.5,0-80.7-36.2-80.7-80.8c0-44.5,36.2-80.8,80.7-80.8C5.2,194.6,41.4,230.9,41.4,275.4 M53.3,275.4
	c0-51.1-41.6-92.6-92.6-92.6c-51.1,0-92.6,41.6-92.6,92.6S-90.4,368-39.3,368C11.7,368,53.3,326.5,53.3,275.4"
      />
    </Icon>
  );
}
