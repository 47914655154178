import { defineStyleConfig } from '@chakra-ui/react';

export const ToolipTheme = defineStyleConfig({
    baseStyle: {
      color: 'black.main',
      bg: 'gray.50',
      p: 4,
      fontSize: 'sm',
      borderRadius: 12,
      boxShadow: 'box',
      border: '1px solid',
      borderColor: 'gray.100',
    },
  });