import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  calcInstallmentAmortizationApi,
  createInstallmentApi,
  getDealsApi,
  getInstallmentApi,
  getInstallmentRepaymentsApi,
  updateInstallmentApi,
} from "api/dealApi";
import { getUnderwritingListApi } from "api/underwritingApi";
import { TwDatePicker } from "components/DatePicker";
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from "components/NumberInput";
import OverlaySpinnerWrapper from "components/OverlaySpinner";
import Card from "components/card/Card";
import SelectInput from "components/inputs/SelectInput";
import BackLink from "components/links/BackLink";
import {
  INSTALLMENT_OWNER_TYPE,
  INSTALLMENT_OWNER_TYPE_META,
  INSTALLMENT_STATUS,
} from "constants/installmentConstants";

import {
  CUSTOMER_SEGMENT_TYPE_META,
  DEAL_TYPE,
  REPAYMENT_FREQUENCY_TYPE_META,
  REVENUE_RECOGNITION_TYPE,
  TOTAL_TYPE_META,
} from "constants/dealConstants";

import { AddIcon } from "@chakra-ui/icons";
import Decimal from "decimal.js";
import { generateSelectOptions } from "helpers/inputHelper";
import { hasObjectAllValues, isObjectEmpty } from "helpers/objectHelper";
import { useTwelveBankAccounts } from "hooks/useTwelveBankAccounts";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import InstallmentRepaymentsTable from "../InstallmentRepaymentsTable";
import InstallmentStatusTag from "../InstallmentStatusTag";
import RevenueRecognitionSelect from "../RevenueRecognitionSelect";
import { useRepaymentSchedule } from "../hooks/useRepaymentSchedule";
import InstallmentSettings from "./InstallmentSettings";
import RepaymentsScheduleSettings from "./RepaymentsScheduleSettings";

const getTotalPurchasePrice = (purchasePrices) => {
  return Decimal.sum(...purchasePrices.map((a) => a.amount || 0)).toNumber();
};

const createFields = ({
  underwritingOptions,
  repaymentDaysOptions,
  daysBeforeEndOfMonthOptions,
  dealOptions,
  formValues,
  isNewInstallment,
  bankAccountOptions,
  onFieldChange,
  dealType,
}) => {
  const fields = [
    {
      label: "Deal",
      name: "dealId",
      element: SelectInput,
      required: true,
      placeholder: "Select Deal",
      options: dealOptions,
      isDisabled: !isNewInstallment,
    },
    {
      label: "Underwriting Report",
      name: "underwritingId",
      element: SelectInput,
      required: true,
      placeholder: "Select Underwriting",
      options: underwritingOptions,
    },
    {
      label: "Owner",
      name: "owner",
      element: SelectInput,
      required: true,
      placeholder: "Select Owner",
      options: generateSelectOptions({ metaObj: INSTALLMENT_OWNER_TYPE_META }),
      formatValue: (value) => {
        return INSTALLMENT_OWNER_TYPE_META[value]?.label;
      },
    },
    {
      label: "Contribution Date",
      name: "contributionDate",
      element: TwDatePicker,
      required: true,
      required: formValues.owner === INSTALLMENT_OWNER_TYPE.SPV,
      isDisabled: formValues.owner !== INSTALLMENT_OWNER_TYPE.SPV,
    },
    {
      label: "Transfer Date",
      name: "transferDate",
      element: TwDatePicker,
      required: true,
    },
    {
      label: "Expected IRR",
      name: "expectedIrrPct",
      element: PercentageNumberInput,
      required: true,
    },
    {
      label: "Payment Frequency",
      name: "repaymentFrequency",
      element: SelectInput,
      required: true,
      placeholder: "Select Payment Frequency",
      options: generateSelectOptions({
        metaObj: REPAYMENT_FREQUENCY_TYPE_META,
      }),
      formatValue: (value) => {
        return REPAYMENT_FREQUENCY_TYPE_META[value]?.label;
      },
    },
    {
      label: "Repayment Days",
      name: "repaymentDays",
      element: SelectInput,
      required: true,
      placeholder: "Select Repayment Days",
      options: repaymentDaysOptions,
      isMulti: true,
      isDisabled: !formValues.repaymentFrequency,
    },
    {
      label: "Days Before End of Month",
      name: "daysBeforeEndOfMonth",
      element: SelectInput,
      required: true,
      isNumber: true,
      options: daysBeforeEndOfMonthOptions,
      isHidden: !formValues?.repaymentDays?.includes(-1),
    },
    {
      label: "Fee",
      name: "fee",
      element: PercentageNumberInput,
      required: true,
    },
    {
      label: "Repayment Terms",
      name: "repaymentTerms",
      element: Input,
      required: true,
    },
    {
      label: "Customer Segment",
      name: "customerSegment",
      element: SelectInput,
      placeholder: "Select Customer Segment",
      options: generateSelectOptions({
        metaObj: CUSTOMER_SEGMENT_TYPE_META,
      }),
      formatValue: (value) => {
        return CUSTOMER_SEGMENT_TYPE_META[value]?.label;
      },
      required: true,
    },
    {
      label: "Expected Duration",
      name: "expectedDuration",
      element: TwNumberInput,
      required: true,
    },
    {
      name: "purchasePrices",
      isCustomField: true,
      element: (
        <PurchasePriceToBankAccountsSection
          formValues={formValues}
          onFieldChange={onFieldChange}
          bankAccountOptions={bankAccountOptions}
          isDisabled={!isNewInstallment || formValues.amortization?.length > 0}
        />
      ),
      required: true,
    },
    {
      label: "First Repayment Date",
      name: "firstRepaymentDate",
      element: TwDatePicker,
      required: true,
    },
    {
      label: "Max Number of Repayments",
      name: "numberOfRepayments",
      element: TwNumberInput,
      required: true,
    },
  ];

  const roasFields = [
    {
      label: "Cohort Start Date",
      name: "cohortStartDate",
      element: TwDatePicker,
      required: dealType === DEAL_TYPE.ROAS,
    },
    {
      label: "Cohort End Date",
      name: "cohortEndDate",
      element: TwDatePicker,
      required: dealType === DEAL_TYPE.ROAS,
    },
    {
      label: "Revenue Share",
      name: "revenueSharePct",
      element: PercentageNumberInput,
      required: dealType === DEAL_TYPE.ROAS,
    },
    {
      label: "Revenue Margin",
      name: "pctFromRevenueShare",
      element: PercentageNumberInput,
      required: dealType === DEAL_TYPE.ROAS,
    },
    {
      label: "Revenue Type",
      name: "revenueTotalType",
      element: SelectInput,
      required: dealType === DEAL_TYPE.ROAS,
      placeholder: "Select Revenue Type",
      options: generateSelectOptions({ metaObj: TOTAL_TYPE_META }),
    },
  ];

  return [...fields, ...roasFields];
};

const installmentSettingsFields = [
  "dealId",
  "underwritingId",
  "owner",
  "contributionDate",
  "transferDate",
  "purchasePrices",
  "expectedIrrPct",
  "repaymentTerms",
  "customerSegment",
  "expectedDuration",
];
const repaymentScheduleFields = [
  "repaymentFrequency",
  "repaymentDays",
  "daysBeforeEndOfMonth",
  "fee",
  "firstRepaymentDate",
  "numberOfRepayments",
];

const repaymentScheduleRoasFields = [
  ...repaymentScheduleFields,
  "cohortStartDate",
  "cohortEndDate",
  "revenueSharePct",
  "pctFromRevenueShare",
  "revenueTotalType",
];

const PurchasePriceToBankAccountsSection = ({
  formValues,
  onFieldChange,
  bankAccountOptions,
  isDisabled,
}) => {
  let { purchasePrices } = formValues;

  if (!purchasePrices) {
    purchasePrices = [{ amount: null, bankAccountId: null }];
  }

  return (
    <>
      <Text>Purchase Price</Text>
      <Box w={200}>
        <CurrencyNumberInput
          value={getTotalPurchasePrice(purchasePrices)}
          isDisabled={true}
          showPrefix={true}
          inputProps={{ fontWeight: 800, w: 200 }}
        />
      </Box>
      <Box></Box>
      <Box></Box>
      {purchasePrices.map((item, index) => {
        return (
          <Fragment key={index}>
            <Box></Box>
            <Box w={200}>
              <CurrencyNumberInput
                value={item.amount}
                onChange={(value) => {
                  purchasePrices[index].amount = value;
                  onFieldChange("purchasePrices", purchasePrices);
                }}
                inputProps={{ fontWeight: 800, w: 200 }}
                showPrefix={true}
                isDisabled={isDisabled}
              />
            </Box>
            <SelectInput
              label={"Bank Account"}
              required={true}
              placeholder={"Select Bank Account"}
              options={bankAccountOptions}
              value={item.bankAccountId}
              onChange={(value) => {
                purchasePrices[index].bankAccountId = value;
                onFieldChange("purchasePrices", purchasePrices);
              }}
              fontWeight={800}
              w={200}
              isDisabled={isDisabled}
            />
            {!isDisabled && index === 0 && (
              <IconButton
                icon={<AddIcon />}
                variant={"outline"}
                borderRadius={8}
                boxSize={8}
                onClick={() => {
                  purchasePrices.push({
                    amount: null,
                    bankAccountId: null,
                  });
                  onFieldChange("purchasePrices", purchasePrices);
                }}
              />
            )}
            {!isDisabled && index > 0 && (
              <Button
                variant={"link"}
                color={"red.500"}
                onClick={() => {
                  purchasePrices.splice(index, 1);
                  onFieldChange("purchasePrices", purchasePrices);
                }}
              >
                Remove
              </Button>
            )}
            {isDisabled && <Box></Box>}
          </Fragment>
        );
      })}
    </>
  );
};

const Installment = () => {
  const [loading, setLoading] = useState(true);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [deals, setDeals] = useState([]);
  const [dealType, setDealType] = useState(null);
  const [underwritingOptions, setUnderwritingOptions] = useState(null);
  const [revenueRecognition, setRevenueRecognition] = useState(
    REVENUE_RECOGNITION_TYPE.INTEREST_FIRST
  );
  const [amortizationData, setAmortizationData] = useState({
    amortization: [],
    amountSold: null,
  });
  const [status, setStatus] = useState(null);
  const [installmentRepayments, setInstallmentRepayments] = useState(null);
  const [installmentRepaymentsLoading, setInstallmentRepaymentsLoading] =
    useState(false);
  const { bankAccountOptions, isTwelveBankAccountsLoading } =
    useTwelveBankAccounts();
  const { handleSubmit, reset, control, watch, setValue } = useForm();

  const toast = useToast();
  const navigate = useNavigate();
  const formValues = watch();
  const { repaymentFrequency } = formValues;

  const {
    repaymentDaysOptions,
    onRepaymentFrequencyFieldChange,
    daysBeforeEndOfMonthOptions,
  } = useRepaymentSchedule({ repaymentFrequency });
  const { customerId, installmentId } = useParams();

  useEffect(() => {
    const init = async () => {
      let promises = [getUnderwritingOptions(), getDeals()];
      if (installmentId) {
        promises.push(getInstallment());
      }
      const [options, _deals, installment] = await Promise.all(promises);
      setUnderwritingOptions(options);
      setDeals(_deals);

      if (installmentId) {
        reset(installment.info);
        setStatus(installment.status);
        setDealType(
          _deals.find((d) => d.id === installment.info.dealId).dealType
        );
        setAmortizationData({
          amortization: installment.info.amortization,
          amountSold: installment.amountSold,
        });
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (installmentId) {
      getInstallmentRepayments();
    }
  }, [revenueRecognition]);

  useEffect(() => {
    setValue("amortization", amortizationData.amortization);
  }, [amortizationData]);

  useEffect(() => {
    setValue("dealType", dealType);
  }, [dealType]);

  const isPageDisabled = status === INSTALLMENT_STATUS.FULLY_PAID;

  const purchasePrice = formValues.purchasePrices
    ? getTotalPurchasePrice(formValues.purchasePrices)
    : Decimal(0);

  const getUnderwritingOptions = async () => {
    setLoading(true);
    try {
      const list = await getUnderwritingListApi({ customerId });
      const options = list
        .filter((u) => !!u.approvedAt)
        .map((u) => ({
          label: `${moment(u.approvedAt).format("MM-DD-YYYY")}`,
          value: u.id,
        }));

      return options;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to load underwriting options",
        status: "error",
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getDeals = async () => {
    try {
      const _deals = await getDealsApi({ customerId });
      return _deals;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to get deals",
        status: "error",
      });
      throw error;
    }
  };

  const getInstallment = async () => {
    try {
      const installment = await getInstallmentApi({
        customerId,
        installmentId,
      });
      return installment;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to get installment data",
        status: "error",
      });
      throw error;
    }
  };

  const getInstallmentRepayments = async () => {
    try {
      setInstallmentRepaymentsLoading(true);
      const _installmentRepayments = await getInstallmentRepaymentsApi({
        customerId,
        installmentId,
        revenueRecognition,
      });
      setInstallmentRepayments(_installmentRepayments);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to get installment repayments",
        status: "error",
      });
      throw error;
    } finally {
      setInstallmentRepaymentsLoading(false);
    }
  };

  const saveInstallment = async (data) => {
    const apiFunc = installmentId
      ? () => updateInstallmentApi({ customerId, installmentId, data })
      : () => createInstallmentApi({ customerId, data });

    try {
      setLoading(true);
      await apiFunc();
      toast({
        title: "Success",
        description: "Installment updated successfully",
        status: "success",
      });
      navigate(`/operations/${customerId}/installment`);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create installment",
        status: "error",
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const calcInstallmentAmortization = async () => {
    const {
      repaymentFrequency,
      repaymentDays,
      daysBeforeEndOfMonth,
      numberOfRepayments,
      fee,
      firstRepaymentDate,
      cohortStartDate,
      cohortEndDate,
      revenueSharePct,
      pctFromRevenueShare,
      revenueTotalType,
    } = formValues;

    try {
      setLoadingOverlay(true);
      const amortizationResponse = await calcInstallmentAmortizationApi({
        customerId,
        dealType,
        purchasePrice,
        repaymentFrequency,
        repaymentDays,
        daysBeforeEndOfMonth,
        numberOfRepayments,
        fee,
        firstRepaymentDate,
        cohortStartDate,
        cohortEndDate,
        revenueSharePct,
        pctFromRevenueShare,
        revenueTotalType,
      });

      setAmortizationData(amortizationResponse);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to calculate installment amortization",
        status: "error",
      });
      throw error;
    } finally {
      setLoadingOverlay(false);
    }
  };

  const onSubmit = (data) => {
    saveInstallment(data);
  };

  const onDealChange = (dealId) => {
    if (dealId) {
      const deal = deals.find((d) => d.id === dealId);

      if (deal) {
        const newFormValues = cloneDeep(formValues);
        newFormValues.repaymentFrequency = deal.repaymentFrequency;
        newFormValues.repaymentDays = deal.repaymentDays;
        newFormValues.daysBeforeEndOfMonth = deal.daysBeforeEndOfMonth;
        newFormValues.customerSegment = deal.customerSegment;
        newFormValues.numberOfRepayments = deal.numberOfRepayments;
        newFormValues.fee = deal.fee;
        newFormValues.repaymentTerms = deal.repaymentTerms;

        if (deal.dealType === DEAL_TYPE.ROAS) {
          newFormValues.revenueSharePct = deal.revenueSharePct;
          newFormValues.pctFromRevenueShare = deal.pctFromRevenueShare;
          newFormValues.revenueTotalType = deal.revenueTotalType;
        } else if (dealType === DEAL_TYPE.FIXED) {
          delete newFormValues.revenueSharePct;
          delete newFormValues.pctFromRevenueShare;
          delete newFormValues.revenueTotalType;
        }

        reset(newFormValues);
        setDealType(deal.dealType);
      }
    }
  };

  const onFieldChange = (name, value) => {
    if (name === "dealId") {
      onDealChange(value);
    } else if (name === "repaymentFrequency") {
      onRepaymentFrequencyFieldChange({ setValue, name, value });
    }
    setValue(name, value);
  };

  function isFormFilled() {
    const values = cloneDeep(formValues);

    const notRequiredFields = fields
      .filter((field) => !field.required)
      .map((field) => field.name);

    notRequiredFields.forEach((field) => {
      delete values[field];
    });

    return (
      !isObjectEmpty(values) &&
      hasObjectAllValues(values) &&
      isAmortizationValid()
    );
  }

  const onAmortizationChange = (newAmortization) => {
    const _amortizationData = cloneDeep(amortizationData);
    newAmortization.sort((a, b) => moment(a.date).diff(moment(b.date)));
    _amortizationData.amortization = newAmortization;
    setAmortizationData(_amortizationData);
  };

  const isAmortizationValid = () => {
    const { amortization, amountSold } = amortizationData;

    if (!amortization || amortization.length === 0) {
      return false;
    }

    let valid = amortization.every((repayment) => {
      return repayment.date && repayment.minAmount;
    });

    const sumOfRepayments = calcAmortizationRepaymentsSum(amortization);
    valid = sumOfRepayments.eq(amountSold);

    if (!valid) {
      return false;
    }

    return true;
  };

  const calcAmortizationRepaymentsSum = (repayments) => {
    return Decimal.sum(
      ...repayments.map(
        (repayment) =>
          repayment.actualPaidAmount ||
          repayment.amountToPay ||
          repayment.minAmount ||
          0
      )
    );
  };

  const isAmortizationBuildDisabled = () => {
    let fieldNames = [
      "repaymentFrequency",
      "repaymentDays",
      "numberOfRepayments",
      "fee",
      "firstRepaymentDate",
    ];

    if (dealType === DEAL_TYPE.ROAS) {
      fieldNames = [
        ...fieldNames,
        "cohortStartDate",
        "cohortEndDate",
        "revenueSharePct",
        "pctFromRevenueShare",
        "revenueTotalType",
      ];
    }

    let values = {};
    fieldNames.forEach((field) => {
      values[field] = formValues[field];
    });

    return !hasObjectAllValues(values) || purchasePrice == 0;
  };

  const fields = useMemo(() => {
    if (!underwritingOptions || !bankAccountOptions) {
      return [];
    }

    const dealOptions = deals.map((deal) => ({
      label: deal.name,
      value: deal.id,
    }));

    return createFields({
      underwritingOptions,
      bankAccountOptions,
      repaymentDaysOptions,
      daysBeforeEndOfMonthOptions,
      dealOptions,
      formValues,
      isNewInstallment: !installmentId,
      onFieldChange,
      purchasePrice,
      dealType,
    });
  });

  if (loading || isTwelveBankAccountsLoading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <OverlaySpinnerWrapper show={loadingOverlay} isFixed={true}>
      <Box minW={1087} maxW={"max"} fontSize={14} mt={-10}>
        <BackLink
          text={"Back to installments table"}
          path={`/operations/${customerId}/installment`}
        />
        <Card mt={"100px"}>
          <Flex align="baseline" gap={10}>
            <Heading
              variant={"sectionHeader"}
              borderColor={"twelve.green.300"}
              mb={10}
            >
              Installment Setup
            </Heading>
            {status && <InstallmentStatusTag status={status} />}
          </Flex>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <VStack spacing={12} align={"left"}>
                <InstallmentSettings
                  fields={fields.filter((field) =>
                    installmentSettingsFields.includes(field.name)
                  )}
                  onFieldChange={onFieldChange}
                  control={control}
                  isPageDisabled={isPageDisabled}
                />
                <RepaymentsScheduleSettings
                  fields={fields.filter((field) =>
                    (dealType === DEAL_TYPE.ROAS
                      ? repaymentScheduleRoasFields
                      : repaymentScheduleFields
                    ).includes(field.name)
                  )}
                  onFieldChange={onFieldChange}
                  control={control}
                  isPageDisabled={isPageDisabled}
                  onAmortizationChange={onAmortizationChange}
                  isAmortizationBuildDisabled={
                    isPageDisabled || isAmortizationBuildDisabled()
                  }
                  onBuildAmortization={calcInstallmentAmortization}
                  amortization={amortizationData.amortization}
                  amountSold={amortizationData.amountSold}
                  dealType={dealType}
                  calcAmortizationRepaymentsSum={calcAmortizationRepaymentsSum}
                />
              </VStack>
              <Flex justify={"end"} mt={20}>
                <Button
                  type={"submit"}
                  variant={"brand"}
                  w={200}
                  isDisabled={isPageDisabled || !isFormFilled()}
                >
                  Apply
                </Button>
              </Flex>
            </FormControl>
          </form>
          {installmentRepayments?.length > 0 && (
            <>
              <Divider my={10} />
              <Flex justify={"space-between"} align={"baseline"}>
                <Heading
                  variant={"sectionHeader"}
                  borderColor={"twelve.green.300"}
                  mt={2}
                >
                  Installment Repayments
                </Heading>
                <Box>
                  <RevenueRecognitionSelect
                    value={revenueRecognition}
                    onChange={setRevenueRecognition}
                  />
                </Box>
              </Flex>
              <Box mt={10} mx={-5} position={"relative"}>
                <OverlaySpinnerWrapper show={installmentRepaymentsLoading}>
                  <InstallmentRepaymentsTable
                    repayments={installmentRepayments}
                    bankAccountOptions={bankAccountOptions}
                  />
                </OverlaySpinnerWrapper>
              </Box>
            </>
          )}
        </Card>
      </Box>
    </OverlaySpinnerWrapper>
  );
};

Installment.propTypes = {};

export default Installment;
