import {
	Box,
	Button,
	Flex,
	FormLabel,
	Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ContentBox from '../../components/ContentBox';
import { UploadFileIcon } from '../../components/icons/connectors/UploadFileIcon';
import { bytesToSize } from '../../helpers/stringHelper';

function UploadFiles({ onUploadFiles, onSelectFiles }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setError('');

    if (fileRejections.length > 0) {
      setError(
        'File type not supported, please upload a file type of the following: .pdf, .csv, .xls, .xlsx, .jpeg, .jpg'
      );
      return;
    }

    if (acceptedFiles.length > 5) {
      setError('Only up to 5 files can be uploaded at a time');
      return;
    }

    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 104857600) {
        setError('File size cannot exceed 100MB');
        return;
      }
    }

    setSelectedFiles(acceptedFiles);
    onSelectFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'application/pdf': [],
      'text/csv': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
  });

  const getFilesDescripitionsSummary = () => {
    return selectedFiles.map((file, index) => {
      return (
        <Text key={`selectedFile_${index}`}>
          {file.name} - {bytesToSize(file.size)}
        </Text>
      );
    });
  };

  const onUpload = () => {
    onUploadFiles(selectedFiles);
    setSelectedFiles([]);
  };

  return (
    <ContentBox
      title={'Upload File'}
      subTitle={'Upload files to receive funding'}
      w={480}
    >
      <FormLabel>Upload Files</FormLabel>
      <Box bg={'gray.50'} fontSize={'sm'} color={'gray.main'} pl={5} py={3}>
        {selectedFiles.length > 0
          ? getFilesDescripitionsSummary()
          : 'Attached file description'}
      </Box>
      <Box
        {...getRootProps()}
        lineHeight={'68px'}
        bg={'gray.50'}
        color={'primary.main'}
        border={1}
        borderColor={'primary.main'}
        borderStyle={'dashed'}
        borderRadius={6}
        mt={5}
      >
        <input {...getInputProps()} />
        <Flex alignItems={'center'} fontSize={'sm'}>
          <UploadFileIcon boxSize={9} mr={3} ml={6} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag and drop some files here, or click to select files</p>
          )}
        </Flex>
      </Box>
      <Text mt={1} fontSize={'sm'} color={'red.main'}>{error}</Text>
      <Button w="full" mt="10" onClick={onUpload} isDisabled={error}>
        Upload
      </Button>
    </ContentBox>
  );
}

UploadFiles.propTypes = {
  onUploadFiles: PropTypes.func.isRequired,
  onSelectFiles: PropTypes.func.isRequired,
};

export default UploadFiles;
