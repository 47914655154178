import { QuestionIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';

export function HelperTooltip({ content }) {
  return (
    <Tooltip label={content} placement={'right'}>
      <QuestionIcon color={'secondaryGray.600'} mx={1} boxSize={'14px'} verticalAlign={'text-top'} />
    </Tooltip>
  );
}
