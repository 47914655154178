import { Icon } from '@chakra-ui/react';

export default function AppsflyerIcon(props) {
  return (
    <Icon viewBox="0 0 360 360" {...props}>
      <defs>
        <style>{`.af-cls-1{fill:#75b82c;}.af-cls-2{fill:#3d90d5;}`}</style>
      </defs>
      <title>appsflyer</title>
      <g id="g10">
        <g id="g12">
          <path
            id="path14"
            className="af-cls-1"
            d="M68.07,133.8l37.29,71c2.55,4.84,8.82,8.77,14,8.75h0l76.07-.16c5.19,0,7.33-3.95,4.79-8.79h0l-37.29-71c-2.54-4.85-8.82-8.76-14-8.75h0L72.86,125h-.35c-4.93.19-6.93,4-4.44,8.79"
          />
          <path
            id="path16"
            className="af-cls-1"
            d="M212.55,216.56l22.58,42.13a10.43,10.43,0,0,0,8.39,5.15h0l45.32-.48c3.1,0,4.34-2.39,2.81-5.26h0L269.08,216a10.43,10.43,0,0,0-8.39-5.15h0l-45.32.48h-.16c-3,.11-4.17,2.43-2.66,5.26"
          />
          <path
            id="path18"
            className="af-cls-2"
            d="M212.82,56.58,167.5,114.74c-3.09,4-3.5,11.1-.92,15.93h0l37.89,70.71c2.58,4.82,7.19,5.51,10.28,1.55h0l45.32-58.18c3.09-4,3.5-11.09.91-15.92h0L223.1,58.13c-1.5-2.8-3.67-4.2-5.83-4.13h0a6,6,0,0,0-4.45,2.58"
          />
          <path
            id="path20"
            className="af-cls-2"
            d="M202.21,217.27l-27,34.65c-1.85,2.37-2.09,6.62-.55,9.49h0l22.57,42.13c1.55,2.88,4.29,3.29,6.13.92h0l27-34.65c1.85-2.37,2.09-6.62.56-9.49h0l-22.58-42.13c-.89-1.67-2.19-2.51-3.48-2.46h0a3.54,3.54,0,0,0-2.64,1.54"
          />
        </g>
      </g>
    </Icon>
  );
}
