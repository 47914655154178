import { mode } from '@chakra-ui/theme-tools';

const disabledStyles = (props) => {
  return {
    // bg: mode('secondaryGray.600', 'brand.400')(props),
    bgGradient: 'linear-gradient(-260deg, secondaryGray.600 3.49%, secondaryGray.600 96.73%)',
    color: mode('white', 'brand.500')(props),
    opacity: 0.3,
    cursor: 'not-allowed',
  };
};

export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: '16px',
        boxShadow: 'unset',
        transition: '.25s all ease',
        boxSizing: 'border-box',
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
      },
      variants: {
        outline: (props) => ({
          bg: mode('white', 'brand.400')(props),
          color: 'secondaryGray.900',
          borderColor: mode('brand.700', 'brand.400')(props),
          // _focus: {
          //   color: mode('brand.200', 'brand.400')(props),
          //   borderColor: mode('brand.200', 'brand.400')(props),
          // },
          _active: {
            color: mode('brand.200', 'brand.400')(props),
            borderColor: mode('brand.200', 'brand.400')(props),
          },
          _hover: {
            color: mode('brand.200', 'brand.400')(props),
            borderColor: mode('brand.200', 'brand.400')(props),
            _disabled: {
              color: 'secondaryGray.700',
              borderColor: mode('brand.700', 'brand.400')(props),
            },
          },
        }),
        brand: (props) => ({
          // bg: mode('brand.500', 'brand.400')(props),
          bgGradient: 'linear-gradient(-260deg, #868cff 3.49%, #4318ff 96.73%)',
          color: 'white',
          _focus: {
            // bg: mode('brand.500', 'brand.400')(props),
            bgGradient:
              'linear-gradient(-260deg, #868cff 3.49%, #4318ff 96.73%)',
          },
          _active: {
            // bg: mode('brand.500', 'brand.400')(props),
            bgGradient:
              'linear-gradient(-260deg, #868cff 3.49%, #4318ff 96.73%)',
          },
          _hover: {
            _disabled: { ...disabledStyles(props) },
            bgGradient:
              'linear-gradient(-260deg, #4318ff 3.49%, #4318ff 96.73%)',
            // bg: mode('brand.600', 'brand.400')(props),
          },
          _disabled: { ...disabledStyles(props) },
        }),
        darkBrand: (props) => ({
          bg: mode('brand.900', 'brand.400')(props),
          color: 'white',
          _focus: {
            bg: mode('brand.900', 'brand.400')(props),
          },
          _active: {
            bg: mode('brand.900', 'brand.400')(props),
          },
          _hover: {
            bg: mode('brand.800', 'brand.400')(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
          color: mode('brand.500', 'white')(props),
          _focus: {
            bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
          },
          _active: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          },
          _hover: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
          },
        }),
        light: (props) => ({
          bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          color: mode('secondaryGray.900', 'white')(props),
          _focus: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          },
          _active: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
          },
          _hover: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
          },
        }),
        action: (props) => ({
          fontWeight: '500',
          borderRadius: '50px',
          bg: mode('secondaryGray.300', 'brand.400')(props),
          color: mode('brand.500', 'white')(props),
          _focus: {
            bg: mode('secondaryGray.300', 'brand.400')(props),
          },
          _active: { bg: mode('secondaryGray.300', 'brand.400')(props) },
          _hover: {
            bg: mode('secondaryGray.200', 'brand.400')(props),
          },
        }),
        setup: (props) => ({
          fontWeight: '500',
          borderRadius: '50px',
          bg: mode('transparent', 'brand.400')(props),
          border: mode('1px solid', '0px solid')(props),
          borderColor: mode('secondaryGray.400', 'transparent')(props),
          color: mode('secondaryGray.900', 'white')(props),
          _focus: {
            bg: mode('transparent', 'brand.400')(props),
          },
          _active: { bg: mode('transparent', 'brand.400')(props) },
          _hover: {
            bg: mode('secondaryGray.100', 'brand.400')(props),
          },
        }),
        link: (props) => ({
          bg: 'transparent',
          fontSize: 'xs',
          color: mode('blue.300', 'brand.400')(props),
          _focus: {
            bg: 'transparent',
          },
          _active: { bg: 'transparent' },
          _hover: {
            bg: 'transparent',
          },
        }),
        red: (props) => ({
          bg: mode('horizonRed.400', 'brand.400')(props),
          color: mode('white', 'brand.500')(props),
          _focus: {
            bg: mode('horizonRed.400', 'brand.400')(props),
          },
          _active: { bg: mode('horizonRed.400', 'brand.400')(props) },
          _hover: {
            bg: mode('horizonRed.500', 'brand.400')(props),
          },
        }),
      },
    },
  },
};
