export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const hasObjectAllValues = (obj) => {
  return Object.values(obj).every((value) => {

    if (Array.isArray(value)) {
       return value.length > 0; 
    }
    
    return value !== '' && value !== null && value !== undefined;
  });
};
