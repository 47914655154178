import { createIcon } from "@chakra-ui/react";

export const UploadFileIcon = createIcon({
  displayName: 'UploadFileIcon',
  viewBox:"0 0 37 36",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M3 36C2.2 36 1.5 35.7 0.9 35.1C0.3 34.5 0 33.8 0 33V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H21.95C22.3833 0 22.7417 0.141667 23.025 0.425C23.3083 0.708333 23.45 1.06667 23.45 1.5C23.45 1.93333 23.3083 2.29167 23.025 2.575C22.7417 2.85833 22.3833 3 21.95 3H3V33H33V14.1C33 13.6667 33.1417 13.3083 33.425 13.025C33.7083 12.7417 34.0667 12.6 34.5 12.6C34.9333 12.6 35.2917 12.7417 35.575 13.025C35.8583 13.3083 36 13.6667 36 14.1V33C36 33.8 35.7 34.5 35.1 35.1C34.5 35.7 33.8 36 33 36H3ZM30.5 11.1C30.0667 11.1 29.7083 10.9583 29.425 10.675C29.1417 10.3917 29 10.0333 29 9.6V7.05H26.45C26.0167 7.05 25.6583 6.90833 25.375 6.625C25.0917 6.34167 24.95 5.98333 24.95 5.55C24.95 5.11667 25.0917 4.75833 25.375 4.475C25.6583 4.19167 26.0167 4.05 26.45 4.05H29V1.5C29 1.06667 29.1417 0.708333 29.425 0.425C29.7083 0.141667 30.0667 0 30.5 0C30.9333 0 31.2917 0.141667 31.575 0.425C31.8583 0.708333 32 1.06667 32 1.5V4.05H34.55C34.9833 4.05 35.3417 4.19167 35.625 4.475C35.9083 4.75833 36.05 5.11667 36.05 5.55C36.05 5.98333 35.9083 6.34167 35.625 6.625C35.3417 6.90833 34.9833 7.05 34.55 7.05H32V9.6C32 10.0333 31.8583 10.3917 31.575 10.675C31.2917 10.9583 30.9333 11.1 30.5 11.1Z"
      fill="currentColor"
    />
  ),
});