import { Circle, Grid, GridItem, Icon, Text } from '@chakra-ui/react';
import { Currency } from 'components/Number';
import Card from 'components/card/Card';
import PropTypes from 'prop-types';
import { FaDollarSign } from 'react-icons/fa6';
import { MdRedo } from 'react-icons/md';

const cardProps = {
  px: '30px',
  py: '20px',
  h: '100%',
};

const titleProps = {
  color: 'secondaryGray.600',
  fontSize: 'sm',
};

const valueProps = {
  fontSize: 24,
  fontWeight: 800,
};

const KpiIcon = ({ icon, iconSize }) => (
  <Circle
    size={'60px'}
    bgGradient={'linear-gradient(-260deg, #4318ff 3.49%,  #868cff 96.73%)'}
    color={'white'}
    position={'absolute'}
    top={'-40px'}
    left={'30px'}
    zIndex={1}
  >
    {' '}
    <Icon as={icon} boxSize={iconSize} />
  </Circle>
);

const OperationsKpis = ({
  totalPurchase,
  totalSold,
  principalDueOutstanding,
  feeDueOutstanding,
  principalRepaid,
  feeRepaid,
}) => {
  return (
    <Grid
      templateRows="repeat(2, auto)"
      templateColumns="repeat(3, auto)"
      gridGap={'20px 40px'}
      w={'max'}
    >
      <GridItem>
        <Card {...cardProps} pl={'100px'}>
          <Text {...titleProps}>Total Purchase Price</Text>
          <Text {...valueProps}>
            <Currency value={totalPurchase} maximumFractionDigits={2} />
          </Text>
        </Card>
      </GridItem>
      <GridItem position={'relative'}>
        <Card {...cardProps}>
          <Text {...titleProps}>Principal Due Outstanding</Text>
          <Text {...valueProps}>
            <Currency value={principalDueOutstanding} maximumFractionDigits={2} />
          </Text>
        </Card>
      </GridItem>
      <GridItem position={'relative'}>
        <Card {...cardProps} pl={'100px'}>
          <Text {...titleProps}>Principal Repaid</Text>
          <Text {...valueProps}>
            <Currency value={principalRepaid} maximumFractionDigits={2} />
          </Text>
        </Card>
      </GridItem>
      <GridItem position={'relative'}>
        <KpiIcon icon={FaDollarSign} iconSize={'24px'} />
        <Card {...cardProps} pl={'100px'}>
          <Text {...titleProps}>Total Amount Sold</Text>
          <Text {...valueProps}>
            <Currency value={totalSold} maximumFractionDigits={2} />
          </Text>
        </Card>
      </GridItem>
      <GridItem>
        <Card {...cardProps}>
          <Text {...titleProps}>Fee Due Outstanding</Text>
          <Text {...valueProps}>
            <Currency value={feeDueOutstanding} maximumFractionDigits={2} />
          </Text>
        </Card>
      </GridItem>
      <GridItem position={'relative'}>
        <KpiIcon icon={MdRedo} iconSize={'30px'} />
        <Card {...cardProps} pl={'100px'}>
          <Text {...titleProps}>Fee Repaid</Text>
          <Text {...valueProps}>
            <Currency value={feeRepaid} maximumFractionDigits={2} />
          </Text>
        </Card>
      </GridItem>
    </Grid>
  );
};

OperationsKpis.propTypes = {
  totalSold: PropTypes.number,
  amountDueOutstanding: PropTypes.number,
  principalDueOutstanding: PropTypes.number,
  feeDueOutstanding: PropTypes.number,
  principalRepaid: PropTypes.number,
  feeRepaid: PropTypes.number,
};

export default OperationsKpis;
