import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import useScrollPosition from '@react-hook/window-scroll';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import approvedIconSvg from '../../assets/images/menu-approved.svg';
import customersIconSvg from '../../assets/images/menu-customers.svg';
import expensesIconSvg from '../../assets/images/menu-expenses.svg';
import financeIconSvg from '../../assets/images/menu-finance.svg';
import fundingIconSvg from '../../assets/images/menu-funding.svg';
import generalIconSvg from '../../assets/images/menu-general.svg';
import insightsIconSvg from '../../assets/images/menu-insights.svg';
import premiumIconSvg from '../../assets/images/menu-premium.svg';
import suggestedIconSvg from '../../assets/images/menu-suggested.svg';

const homeItems = [
  {
    name: 'Insights',
    path: 'insights',
    icon: insightsIconSvg,
  },
  {
    name: 'Expenses',
    path: 'expenses',
    icon: expensesIconSvg,
  },
  ,
  {
    name: 'Funding',
    path: 'funding',
    icon: fundingIconSvg,
  },
];

const performanceItems = [
  {
    name: 'Finance',
    path: 'finance',
    icon: financeIconSvg,
  },
  {
    name: 'Customers',
    path: 'customers',
    icon: customersIconSvg,
  },
  ,
  {
    name: 'General',
    path: 'general',
    icon: generalIconSvg,
  },
];

const partnerStoreItems = [
  {
    name: 'Suggested',
    path: 'suggested',
    icon: suggestedIconSvg,
  },
  {
    name: 'Premium',
    path: 'premium',
    icon: premiumIconSvg,
  },
  ,
  {
    name: 'Approved',
    path: 'approved',
    icon: approvedIconSvg,
  },
];

function SideNavigationBar() {
  let items = [];
  const location = useLocation();
  switch (location.pathname) {
    case '/':
      items = homeItems;
      break;
    case '/performance':
      items = performanceItems;
      break;
    case '/partner-store':
      items = partnerStoreItems;
      break;
    default:
      break;
  }

  const [fixed, setFixed] = useState(false);
  const scrollY = useScrollPosition();

  useEffect(() => {
    if (scrollY > 110) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, [scrollY]);

  return (
    <Box
      bg={'white'}
      px={4}
      pt={4}
      ml={5}
      minW={160}
      h={770}
      borderRadius={20}
      boxShadow={'box'}
      top={fixed ? 5 : 130}
      position={fixed ? 'fixed' : 'absolute'}
    >
      {items.map((item, index) => (
        <Link
          key={item.name}
          as={ScrollLink}
          to={item.path}
          opacity={0.4}
          _activeLink={{ fontWeight: 'bold', opacity: 1 }}
          color={'black.main'}
          fontSize={'sm'}
          lineHeight={'48px'}
          spy={true}
          activeStyle={{ fontWeight: 'bold', opacity: 1 }}
          smooth={true}
          offset={index === 0 ? -190 : -20}
        >
          <Flex px={5} mt={3}>
            <Image src={item.icon} display={'inline'} />{' '}
            <Text ml={'10px'}>{item.name}</Text>
          </Flex>
        </Link>
      ))}
    </Box>
  );
}

export default SideNavigationBar;
