import axios from 'axios';

export const writeLogApi = async ({ error, level, message, customerId }) => {
  const response = await axios.post(
    `/log`,
    {
      error,
      level,
      message,
      customerId,
    },
    {
      baseURL: process.env.REACT_APP_API_URL,
    }
  );
  return response.data;
};
