import {
  Flex,
  Image,
  Link,
  Text,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { useDescope } from '@descope/react-sdk';

import { NavLink } from 'react-router-dom';

import { topNavigationItems } from './topNavigationItems';

import { IoMdSettings } from 'react-icons/io';
import { MdLogout } from 'react-icons/md';

import logoSvg from '../../assets/images/logo.svg';
import { useCallback } from 'react';

function TopNavigationBar({ user }) {
  const { logout } = useDescope();

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  if (!user) {
    return null;
  }

  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      position={'absolute'}
      top={5}
      left={5}
      right={5}
      bg="white"
      h={76}
      borderRadius={['20px', '50px']}
      pl={'30px'}
      pr={'24px'}
    >
      <Image src={logoSvg} />

      <Flex>
        {topNavigationItems.map((item) => (
          <Link
            color={'black.main'}
            key={item.name}
            as={NavLink}
            to={item.path}
            mx={10}
            opacity={0.4}
            _activeLink={{ fontWeight: 'bold', opacity: 1 }}
            fontSize={'sm'}
          >
            <Flex align={'center'}>
              {typeof item.icon === 'string' ? (
                <Image src={item.icon} />
              ) : (
                <Icon as={item.icon} boxSize={4} />
              )}
              <Text ml={'10px'}>{item.name}</Text>
            </Flex>
          </Link>
        ))}
      </Flex>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          icon={<IoMdSettings size={20} />}
          aria-label="Settings"
          color={'black.main'}
          variant={'link'}
        />
        <MenuList
          color={'gray.main'}
          borderRadius={12}
          shadow={'0px 4px 30px rgba(170, 169, 184, 0.4);'}
          lineHeight={'48px'}
        >
          <MenuItem
            as={Text}
            _hover={{ bg: 'white' }}
            borderBottomWidth={0.5}
            borderBottomColor={'gray.border'}
            fontSize={'xs'}
          >
            {user.email || user.username}
          </MenuItem>
          <MenuItem onClick={handleLogout} icon={<MdLogout size={15} />}>
            Log out
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}

export default TopNavigationBar;
