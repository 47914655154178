import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, useToast } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { getSofrApi } from 'api/adminApi';
import DataTable from 'components/DataTable';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor('date', {
    cell: (info) => moment(info.getValue()).format('LL'),
    header: 'Date',
  }),
  columnHelper.accessor('value', {
    cell: (info) => `${info.getValue()}%`,
    header: 'Sofr',
  }),
  columnHelper.accessor('isFromPreviousDay', {
    cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    header: 'From Previous Day',
  }),
];

const defaultSorting = [{ id: 'date', desc: true }];

const csvHeaders = [
  { label: 'Date', key: 'date' },
  {
    label: 'Sofr',
    key: 'value',
  },
];

function Sofr() {
  const [sofrData, setSofrData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const toast = useToast();

  useEffect(() => {
    loadSofrData();
  }, []);

  const loadSofrData = () => {
    setLoading(true);
    getSofrApi()
      .then((_sofrData) => {
        _sofrData = _sofrData.map((item) => {
          const { year, month, day } = item.date;
          item.date = moment({ year, month: month - 1, day });
          return item;
        });
        setSofrData(_sofrData);
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Failed to load sofr data',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateCsvData = (event, done) => {
    const _csvData = sofrData
      .sort((a, b) => b.date - a.date)
      .map((item) => {
        const { date, value } = item;
        return {
          date: moment(date).format('LL'),
          value: `${value}%`,
        };
      });
    setCsvData(_csvData);
    done(true);
  };

  return (
    <>
      <Box align={'right'}>
        <Button variant={'brand'} leftIcon={<DownloadIcon />}>
          <CSVLink
            style={{ color: 'white' }}
            data={csvData}
            headers={csvHeaders}
            filename="sofr.csv"
            onClick={generateCsvData}
            asyncOnClick={true}
          >
            Download CSV
          </CSVLink>
        </Button>
      </Box>
      <Box minH={300} maxH={'calc(100vh - 380px)'} overflow={'auto'}>
        <OverlaySpinnerWrapper show={loading}>
          <DataTable
            data={sofrData}
            columns={columns}
            defaultSorting={defaultSorting}
          />
        </OverlaySpinnerWrapper>
      </Box>
    </>
  );
}

export default Sofr;
