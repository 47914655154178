import {
  isCockpitApp,
  isCustomerApp,
  isDemoApp,
  isLenderApp,
} from '../../helpers/envHelper';

import { BsBank } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import connectorsIconSvg from '../../assets/images/menu-connectors.svg';
import homeIconSvg from '../../assets/images/menu-home.svg';
import partnerStoreIconSvg from '../../assets/images/menu-partner-store.svg';
import performanceIconSvg from '../../assets/images/menu-performance.svg';
import twelveConnectIconSvg from '../../assets/images/menu-twelve-connect.svg';

const getItems = () => {
  if (isCustomerApp()) {
    return [
      {
        name: 'Home',
        path: '/home',
        icon: homeIconSvg,
      },
      {
        name: 'Twelve connect',
        path: '/twelve-connect',
        icon: twelveConnectIconSvg,
      },
      ,
      {
        name: 'Connectors',
        path: '/connectors',
        icon: connectorsIconSvg,
      },
    ];
  }
  if (isDemoApp()) {
    return [
      {
        name: 'Home',
        path: '/',
        icon: homeIconSvg,
      },
      {
        name: 'Performance',
        path: '/performance',
        icon: performanceIconSvg,
      },
      {
        name: 'Partner store',
        path: '/partner-store',
        icon: partnerStoreIconSvg,
      },
      {
        name: 'Twelve connect',
        path: '/twelve-connect',
        icon: twelveConnectIconSvg,
      },
      ,
      {
        name: 'Connectors',
        path: '/connectors',
        icon: connectorsIconSvg,
      },
    ];
  }
  if (isLenderApp()) {
    return [];
  }
  if (isCockpitApp()) {
    return [
      {
        name: 'Performance',
        path: '/',
        icon: homeIconSvg,
      },
      {
        name: 'Customers',
        path: '/customers',
        icon: FaUsers,
      },
      {
        name: 'Banking & Finance',
        path: '/banking-and-finance',
        icon: BsBank,
      },
      {
        name: 'Funding',
        path: '/funding',
        icon: performanceIconSvg,
      },
      {
        name: 'Lenders',
        path: '/lenders',
        icon: connectorsIconSvg,
      },
      {
        name: 'Simulation',
        path: '/simulation',
        icon: connectorsIconSvg,
      },
    ];
  }
};

export const topNavigationItems = getItems();
