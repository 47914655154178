import { defineStyleConfig } from '@chakra-ui/react';

export const NumberInputTheme = defineStyleConfig({
  baseStyle: {
    field: {
      bg: 'gray.50',
    },
    stepper: {
      border: 'none',
      _active: {
        bg: 'primary.main',
      },
    }
  },
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
        h: '10',
      },
    },
  },
  variants: {},
  defaultProps: {
    variant: null,
  },
});
