export const drawerStyles = {
  components: {
    Drawer: {
      baseStyle: {
        dialog: {
          my: 20,
          me: {
            base: 4,
          },
          borderRadius: '16px',
          borderWidth: 1,
          borderColor: 'secondaryGray.100',
          boxShadow: '-20px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        },
        body: { px: '32px', pt: '40px' },
      },
    },
  },
};
