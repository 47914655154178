import axios from 'axios';

export const uploadTwelveConnectFileApi = (
  customerId,
  file,
  onUploadProgress
) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`/customer/${customerId}/twelve-connect-upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const getTwelveConnectFilesApi = async (customerId) => {
  const response = await axios.get(
    `/customer/${customerId}/twelve-connect-files`
  );
  return response.data;
};
