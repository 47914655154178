export const TIME_PERIOD = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

export const TIME_PERIOD_META = {
  [TIME_PERIOD.WEEKLY]: {
    label: 'Weekly',
    vectorStartOf: 'isoWeek',
    intervalType: 'week',
    dateFormat: 'L',
  },
  [TIME_PERIOD.MONTHLY]: {
    label: 'Monthly',
    vectorStartOf: 'month',
    intervalType: 'month',
    dateFormat: 'MMM YYYY',
  },
};

export const MONEY_TYPE = {
  REVENUE: 'revenue',
  CASHFLOW: 'cashflow',
};

export const MONEY_TYPE_META = {
  [MONEY_TYPE.REVENUE]: {
    label: 'Revenue',
  },
  [MONEY_TYPE.CASHFLOW]: {
    label: 'Cashflow',
  },
};

export const TOTAL_TYPE = {
  GROSS: 'gross',
  NET: 'net',
};

export const TOTAL_TYPE_META = {
  [TOTAL_TYPE.GROSS]: {
    label: 'Gross',
  },
  [TOTAL_TYPE.NET]: {
    label: 'Net',
  },
};

export const TREND_TYPE = {
  MOM: 'mom',
  SOM: 'som',
};

export const TREND_TYPE_META = {
  [TREND_TYPE.MOM]: {
    label: 'MoM',
  },
  [TREND_TYPE.SOM]: {
    label: 'SoM',
  },
};

export const DATA_SOURCE = {
  HISTORICAL: 'historical',
  PROJECTIONS: 'projections',
};

export const DATA_SOURCE_META = {
  [DATA_SOURCE.HISTORICAL]: {
    label: 'Historical',
  },
  [DATA_SOURCE.PROJECTIONS]: {
    label: 'Projections',
  },
};

export const SUBSCRIPTION_TYPE = {
  ALL: 'all',
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
};

export const SUBSCRIPTION_TYPE_META = {
  [SUBSCRIPTION_TYPE.ALL]: {
    label: 'All',
  },
  [SUBSCRIPTION_TYPE.MONTHLY]: {
    label: 'Monthly',
  },
  [SUBSCRIPTION_TYPE.ANNUALLY]: {
    label: 'Annually',
  },
};

export const CURRENT_MONTH_TYPE = {
  BAKED: 'baked',
  NOT_BAKED: 'notBaked',
  LINEAR_PREDICTION: 'linearPrediction',
  MARKOV_CHAIN_PREDICTION: 'markovChainPrediction'
};

export const CURRENT_MONTH_TYPE_META = {
  [CURRENT_MONTH_TYPE.BAKED]: {
    label: 'Baked',
  },
  [CURRENT_MONTH_TYPE.NOT_BAKED]: {
    label: 'Not Baked',
  },
  [CURRENT_MONTH_TYPE.LINEAR_PREDICTION]: {
    label: 'Linear Prediction',
  },
  [CURRENT_MONTH_TYPE.MARKOV_CHAIN_PREDICTION]: {
    label: 'Markov Chain Prediction',
  },
};

export const COMPARISON_PERIOD_OPTIONS = [
  { value: 1, label: '1 Month' },
  { value: 3, label: '3 Months' },
  { value: 6, label: '6 Months' },
  { value: 12, label: '12 Months' },
];


export const ERROR_CODES = {
  MISSING_CAC: 5001
}