import axios from 'axios';

export const getPlaidLinkApi = async (customerId) => {
  const response = await axios.get(
    `/customer/${customerId}/connectors/plaid-link`
  );
  return response.data;
};

export const getPlaidUpdateLinkApi = async (customerId, itemId) => {
  const response = await axios.get(
    `/customer/${customerId}/connectors/plaid-update-link/${itemId}`
  );
  return response.data;
};

export const setPlaidPublicTokenApi = async (customerId, publicToken) => {
  const response = await axios.post(
    `/customer/${customerId}/connectors/plaid-public-token`,
    { publicToken }
  );
  return response.data;
};

export const setPlaidUpdateLinkCompleteApi = async (customerId, itemId) => {
  const response = await axios.post(
    `/customer/${customerId}/connectors/plaid-update-link-complete/${itemId}`
  );

  return response.data;
};

export const getFivetranLinkApi = async ({
  customerId,
  connectorId,
  redirectUrl,
}) => {
  const response = await axios.get(
    `/customer/${customerId}/connectors/fivetran-link?connectorId=${connectorId}&redirectUrl=${redirectUrl}`
  );
  return response.data;
};

export const createConnectorApi = async (
  customerId,
  connectorType,
  extraParams
) => {
  const response = await axios.post(`/customer/${customerId}/connector`, {
    connectorType,
    extraParams,
  });
  return response.data;
};

export const deleteConnectorApi = async (
  customerId,
  connectorType,
  connectorId
) => {
  const response = await axios.delete(
    `/customer/${customerId}/connector?id=${connectorId}&connectorType=${connectorType}`
  );
  return response.data;
};

export const getConnectorsApi = async (customerId) => {
  const response = await axios.get(`/customer/${customerId}/connectors`);
  return response.data;
};

export const getPlaidIdentityApi = async (customerId, itemId) => {
  const response = await axios.get(
    `/customer/${customerId}/connectors/plaid/${itemId}/identity`
  );
  return response.data;
};

export const getPlaidAccountsApi = async (customerId, itemId) => {
  const response = await axios.get(
    `/customer/${customerId}/connectors/plaid/${itemId}/accounts`
  );
  return response.data;
}
