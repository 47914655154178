import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { HelperTooltip } from '../../../components/Tooltip';

function ExtraPropsFormModalContent({ fields, onSubmit, docsLink }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  return (
    <ModalContent
      h={docsLink ? 'calc(100% - 150px)' : 'auto'}
      w={docsLink ? 'calc(100% - 150px)' : 'auto'}
      maxW={docsLink ? 1920 : 'auto'}
    >
      <ModalHeader>Setup Shopify Connector</ModalHeader>
      <ModalCloseButton />

      <Flex grow={1} gap={8}>
        <Box w={450}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl px={6} py={4}>
              <Stack spacing={5}>
                {fields.map((field, index) => {
                  return (
                    <Box key={`field${index}`}>
                      <FormLabel>
                        {field.label}
                        {field.tooltip && (
                          <HelperTooltip content={field.tooltip} />
                        )}
                      </FormLabel>
                      <Input {...register(field.name, { required: true })} />
                    </Box>
                  );
                })}
              </Stack>
              <Flex mt={6} justify={'flex-end'}>
                <Button
                  variant={'primary'}
                  type={'submit'}
                  isDisabled={!isValid}
                >
                  Add
                </Button>
              </Flex>
            </FormControl>
          </form>
        </Box>
        {docsLink && (
          <Box bgColor={'#f8f8f8'} flex={1}>
            <iframe src={docsLink} width="100%" height="100%" />
          </Box>
        )}
      </Flex>
    </ModalContent>
  );
}

export function ShopifyExtraPropsModalContent({ onSubmit }) {
  return (
    <ExtraPropsFormModalContent
      onSubmit={onSubmit}
      fields={[
        {
          label: 'Shopify Shop Name',
          name: 'shopifyFivetran.shopName',
          tooltip: `The Shopify Shop name is the unique name you gave your store and can be found in the subdomain of your Shopify URL. For example if the url is: https://twelve.myshopify.com/, then the shop name is “twelve”.`,
        },
      ]}
    />
  );
}

export function ShopifyPartnerExtraPropsModalContent({ onSubmit }) {
  const fields = [
    { label: 'Organization Id', name: 'shopifyPartner.organizationId' },
    { label: 'App Id', name: 'shopifyPartner.appId' },
    { label: 'Access Token', name: 'shopifyPartner.accessToken' },
  ];
  return <ExtraPropsFormModalContent onSubmit={onSubmit} fields={fields} />;
}

export function ShopifyStoreExtraPropsModalContent({ onSubmit }) {
  const fields = [
    { label: 'Shop Name', name: 'shopifyStore.shopName' },
    { label: 'Access Token', name: 'shopifyStore.accessToken' },
  ];
  return (
    <ExtraPropsFormModalContent
      onSubmit={onSubmit}
      fields={fields}
      docsLink={'docs/connectors/shopify-store/index.html'}
    />
  );
}
