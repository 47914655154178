import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { hasObjectAllValues, isObjectEmpty } from '../../helpers/objectHelper';

import ContentBox from '../../components/ContentBox';
import { CustomerContext } from '../../context/customerContext';
import { sendOnBoardingCompletedGAEvent, sendOnBoardingStartedGAEvent } from '../../ga/userGA';

function OnBoardingForm() {
  const { customer, completeCustomerOnboarding, isCustomerLoading } =
    useContext(CustomerContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customer) {
      const defaultValues = {
        name: customer.name,
        companyName: customer.companyName,
        approvedTerms: customer.approvedTerms,
      };
      reset(defaultValues);
      sendOnBoardingStartedGAEvent();
    }
  }, [customer]);

  const { register, handleSubmit, watch, reset } = useForm();

  const formValues = watch();
  const navigate = useNavigate();
  const toast = useToast();

  function fromHasAllValues(values) {
    return hasObjectAllValues(values) && values.approvedTerms;
  }

  function isFormFilled() {
    return !isObjectEmpty(formValues) && fromHasAllValues(formValues);
  }

  async function onSubmit({ name, companyName, approvedTerms }) {
    setLoading(true);
    try {
      await completeCustomerOnboarding({customerId: customer.id, name, companyName, approvedTerms});
      sendOnBoardingCompletedGAEvent(companyName);
      navigate('/');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update customer.',
        status: 'error',
      });
      return;
    }

    setLoading(false);
  }

  return (
    <AbsoluteCenter>
      <ContentBox
        title={'Company details'}
        subTitle={'Enter your company details.'}
        loading={isCustomerLoading || loading}
        minH={530}
        w={[320, 480]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Stack spacing={5}>
              <Box>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register('name', {
                    required: 'this is required',
                  })}
                />
              </Box>
              <Box>
                <FormLabel>Company Name</FormLabel>
                <Input
                  {...register('companyName', {
                    required: 'this is required',
                  })}
                />
              </Box>
              <Box>
                <Checkbox
                  {...register('approvedTerms', {
                    required: 'this is required',
                  })}
                >
                  I agree with the <Link target={'_blank'} href={'/terms-of-use'}>Terms of Use</Link> and{' '}
                  <Link target={'_blank'} href={'/privacy-policy'}>
                  Privacy Policy
                  </Link>
                </Checkbox>
              </Box>
            </Stack>
            <Button w="full" mt="12" type="submit" isDisabled={!isFormFilled()}>
              Next
            </Button>
          </FormControl>
        </form>
      </ContentBox>
    </AbsoluteCenter>
  );
}

export default OnBoardingForm;
