import { createIcon } from '@chakra-ui/react';

export const ServiceFeesIcon = createIcon({
  displayName: 'ServiceFeesIcon',
  viewBox: '0 0 41 30',

  path: (
    <>
      <path
        d="M36.4424 16.7628H25.2691C24.7103 16.7628 24.2808 16.3329 24.2808 15.7744V4.60117C24.2808 4.04242 23.808 3.56972 23.2493 3.61284C19.3818 3.91381 16.0298 5.89047 13.795 8.81287C12.5057 8.08235 11.0018 7.65249 9.41155 7.65249C4.34075 7.65249 0.258057 11.7782 0.258057 16.806C0.258057 21.834 4.38373 25.9595 9.41155 25.9595C11.0446 25.9595 12.5486 25.4867 13.8807 24.7562C16.2872 27.8932 20.0259 29.9562 24.1515 29.999C31.1993 30.0851 36.9578 24.7133 37.4735 17.8374C37.5167 17.2354 37.0443 16.7628 36.4424 16.7628ZM2.53576 16.8056C2.53576 13.0238 5.58708 9.97283 9.36857 9.97283C13.1504 9.97283 16.2014 13.0241 16.2014 16.8056C16.2014 20.5874 13.1501 23.6385 9.36857 23.6385C5.58708 23.6385 2.53576 20.5443 2.53576 16.8056Z"
        fill="#2084F9"
      />
      <path
        d="M28.8789 0.00270457C28.3201 -0.0401614 27.8474 0.432569 27.8474 0.991033V12.1643C27.8474 12.7231 28.2773 13.1526 28.8357 13.1526H40.009C40.5678 13.1526 41.0405 12.6799 40.9973 12.1212C40.5249 5.67537 35.3678 0.518164 28.8786 0.00247448L28.8789 0.00270457Z"
        fill="#E0E8F0"
      />
      <path
        d="M9.75548 15.9462H9.02496C8.81003 15.9462 8.63826 15.8601 8.46621 15.6884C8.33731 15.5166 8.25127 15.3445 8.29444 15.1296C8.33731 14.7429 8.68114 14.4851 9.06782 14.4851H9.88441C10.0993 14.4851 10.2711 14.614 10.3143 14.829C10.3571 15.0007 10.486 15.1299 10.701 15.1299H11.6464C11.7753 15.1299 11.8613 15.0871 11.9474 15.001C12.0334 14.915 12.0334 14.8293 12.0334 14.7001C11.9474 13.7117 11.174 12.938 10.2285 12.8091V12.0357C10.2285 11.8208 10.0567 11.649 9.84178 11.649H8.89635C8.68142 11.649 8.50966 11.8208 8.50966 12.0357L8.50906 12.852C7.30578 13.1098 6.44638 14.2273 6.5753 15.4734C6.7042 16.7195 7.77857 17.6651 9.0678 17.6651H9.66942C9.88435 17.6651 10.0561 17.7511 10.2282 17.9228C10.3571 18.0946 10.4431 18.2667 10.3999 18.4816C10.3571 18.8683 10.0132 19.1261 9.62656 19.1261H8.80998C8.59504 19.1261 8.42328 18.9972 8.38011 18.7823C8.33724 18.6105 8.20835 18.4813 7.99341 18.4813H7.04798C6.91908 18.4813 6.83305 18.5242 6.74702 18.6102C6.66099 18.6962 6.66098 18.782 6.66098 18.9112C6.74702 19.8995 7.52039 20.6732 8.4659 20.8021V21.5755C8.4659 21.7904 8.63766 21.9622 8.8526 21.9622H9.79803C10.013 21.9622 10.1847 21.7904 10.1847 21.5755V20.7589C11.2162 20.5011 11.9896 19.6845 12.1185 18.6531C12.2045 17.9654 11.9467 17.2778 11.5169 16.7621C11.0876 16.2469 10.4431 15.9462 9.75548 15.9462Z"
        fill="#2084F9"
      />
    </>
  ),
});
