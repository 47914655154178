import { Box, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getConnectorsApi, setPlaidPublicTokenApi, setPlaidUpdateLinkCompleteApi } from '../../api/connectorsApi';
import OverlaySpinnerWrapper from '../../components/OverlaySpinner';
import WhiteBox from '../../components/WhiteBox';
import {
  CONNECTOR_INTEGRATION_MAPPING,
  CONNECTOR_INTEGRATION_TYPE,
  CONNECTOR_META_DATA,
  CONNECTOR_STATUS,
} from '../../constants/connectorConstants';
import { CustomerContext } from '../../context/customerContext';
import AddConnectorModal from './AddConnectorsModal/AddConnectorModal';
import PlaidRow from './ConnectorRows/PlaidRow';
import {
  PlaidLink,
  connectTwelve,
  getFivetranLink,
  getPlaidLinkToken,
} from './connectIntegrations';

import {
  sendConnectorFivetranCompletedGAEvent,
  sendConnectorFivetranStartedGAEvent,
  sendConnectorPlaidCompletedGAEvent,
  sendConnectorPlaidStartedGAEvent,
  sendConnectorTwelveCompletedGAEvent,
  sendConnectorTwelveStartedGAEvent,
  sendExistingConnectorConnectClickGAEvent,
} from '../../ga/connectorsGA';
import ConnectorRow from './ConnectorRows/ConnectorRow';

function Connectors() {
  const { customer } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const customerId = customer?.id;

  const loadConnectors = () => {
    setLoading(true);
    getConnectorsApi(customerId)
      .then((data) => {
        const _connectors = data.connectors;
        setConnectors(
          _connectors.sort((a, b) => b.createdAtMillis - a.createdAtMillis)
        );
        checkConnectorFlowCompleted(
          _connectors,
          'fivetran_connector_id_start',
          (connector) => sendConnectorFivetranCompletedGAEvent(connector.type)
        );

        if (data.failedIntegrations?.length > 0) {
          toast({
            title: 'Error',
            description:
              'There was an error displaying some of the connectors.',
            status: 'error',
          });
        }
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Failed to load connectors.',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadConnectors();
  }, [customerId]);

  const checkConnectorFlowCompleted = (
    _connectors,
    sessionStorageKey,
    onComplete
  ) => {
    const startedConnectorId = sessionStorage.getItem(sessionStorageKey);
    if (!startedConnectorId) {
      return;
    }
    const completedConnectorId = searchParams.get('id');
    if (!completedConnectorId) {
      return;
    }

    const connector = _connectors.find(
      (connector) => connector.id === completedConnectorId
    );
    if (!connector || connector.setupStatus !== CONNECTOR_STATUS.CONNECTED) {
      return;
    }

    sessionStorage.removeItem(sessionStorageKey);
    onComplete && onComplete(connector);
  };

  const onConnectError = (error) => {
    toast({
      title: 'Error',
      description: 'Failed to connect.',
      status: 'error',
    });
    setLoading(false);
  };

  const startConnectionFlow = ({
    customerId,
    connectorId,
    connectorType,
    data,
    connectLink,
  }) => {
    setLoading(true);

    switch (CONNECTOR_INTEGRATION_MAPPING[connectorType]) {
      case CONNECTOR_INTEGRATION_TYPE.FIVETRAN:
        {
          const onComplete = ({ link, connectorId }) => {
            sessionStorage.setItem('fivetran_connector_id_start', connectorId);
            sendConnectorFivetranStartedGAEvent(connectorType);
            window.location.href = link;
          };
          getFivetranLink({
            customerId,
            connectorId,
            connectorType,
            extraParams: data,
            onComplete: onComplete,
            onError: onConnectError,
          });
        }
        break;
      case CONNECTOR_INTEGRATION_TYPE.SHOPIFY:
        {
          const onComplete = () => {
            sendConnectorTwelveCompletedGAEvent(connectorType);
            loadConnectors();
          };

          sendConnectorTwelveStartedGAEvent(connectorType);

          connectTwelve({
            customerId,
            connectorType,
            extraParams: data,
            onComplete,
            onError: onConnectError,
          });
        }
        break;
      case CONNECTOR_INTEGRATION_TYPE.PLAID:
        {
          const itemId = connectorId;
          itemId
            ? sessionStorage.setItem('plaid_item_id_start', itemId)
            : sessionStorage.removeItem('plaid_item_id_start');

          getPlaidLinkToken({
            customerId,
            itemId,
            onComplete: (linkToken) => {
              setPlaidLinkToken(linkToken);
              setLoading(false);
            },
            onError: onConnectError,
          });
        }
        break;
      default:
        throw new Error('Invalid connector type');
    }
  };

  const onConnectClick = ({ id, type }) => {
    sendExistingConnectorConnectClickGAEvent(type);
    startConnectionFlow({
      customerId,
      connectorId: id,
      connectorType: type,
    });
  };

  const renderConnectorList = () => {
    return connectors.map((connector) => {
      const { id, name, type, setupStatus, plaidAccounts, logoBase64 } =
        connector;
      if (connector.integrationType === CONNECTOR_INTEGRATION_TYPE.PLAID) {
        return (
          <PlaidRow
            key={id}
            name={name}
            status={setupStatus}
            plaidAccounts={plaidAccounts}
            logoBase64={logoBase64}
            onConnectClick={() => onConnectClick({ id, type })}
          />
        );
      }

      const { displayName, icon } = CONNECTOR_META_DATA[type] || {};
      return (
        <ConnectorRow
          key={id}
          name={displayName}
          icon={icon}
          status={setupStatus}
          onConnectClick={() => onConnectClick({ id, type })}
        />
      );
    });
  };

  return (
    <WhiteBox minH={530} maxW={700} mx={'auto'} position={'relative'}>
      <OverlaySpinnerWrapper show={loading}>
        <Flex justify={'space-between'}>
          <Heading as="h1" size="l">
            Connectors
          </Heading>
          <AddConnectorModal
            customerId={customerId}
            onConnectorSelected={startConnectionFlow}
          />
        </Flex>
        <Text color="gray.main" fontSize="sm" my={1}>
          Connect to services and receive more funding.
        </Text>
        <Box mt={10}>{renderConnectorList()}</Box>
      </OverlaySpinnerWrapper>
      <PlaidLink
        customerId={customerId}
        token={plaidLinkToken}
        onPlaidSuccessLoading={() => {
          setLoading(true);
        }}
        onPlaidSuccessComplete={(publicToken, metadata) => {
          sendConnectorPlaidCompletedGAEvent(metadata.institution.name);
          const itemId = sessionStorage.getItem('plaid_item_id_start');

          const apiFunc = itemId
            ? () => setPlaidUpdateLinkCompleteApi(customerId, itemId)
            : () => setPlaidPublicTokenApi(customerId, publicToken);

          setLoading(true);
          apiFunc()
            .then(() => {
              loadConnectors();
            })
            .catch((error) => {
              toast({
                title: 'Error',
                description: 'Failed to connect.',
                status: 'error',
              });
              setLoading(false);
            })
        }}
        onPlaidOpen={() => {
          sendConnectorPlaidStartedGAEvent();
        }}
      />
    </WhiteBox>
  );
}

export default Connectors;
