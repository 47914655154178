export const BUSINESS_MODEL_TYPE = {
  SUBSCRIPTION: 'subscription',
  NON_SUBSCRIPTION: 'nonSubscription',
  ECOMMERCE: 'ecommerce',
  GAMING: 'gaming',
};

export const BUSINESS_MODEL_TYPE_META = {
  [BUSINESS_MODEL_TYPE.SUBSCRIPTION]: {
    label: 'Subscription',
  },
  [BUSINESS_MODEL_TYPE.NON_SUBSCRIPTION]: {
    label: 'Non-Subscription',
  },
  [BUSINESS_MODEL_TYPE.ECOMMERCE]: {
    label: 'Ecommerce',
  },
  [BUSINESS_MODEL_TYPE.GAMING]: {
    label: 'Gaming',
  },
};

export const BUSINESS_PAYMENT_SERVICE_TYPE = {
  APPLE: 'apple',
  GOOGLE: 'google',
  SHOPIFY: 'shopify',
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
  AMAZON: 'amazon',
};

export const BUSINESS_PAYMENT_SERVICE_TYPE_META = {
  [BUSINESS_PAYMENT_SERVICE_TYPE.APPLE]: {
    label: 'Apple',
  },
  [BUSINESS_PAYMENT_SERVICE_TYPE.GOOGLE]: {
    label: 'Google',
  },
  [BUSINESS_PAYMENT_SERVICE_TYPE.SHOPIFY]: {
    label: 'Shopify',
  },
  [BUSINESS_PAYMENT_SERVICE_TYPE.STRIPE]: {
    label: 'Stripe',
  },
  [BUSINESS_PAYMENT_SERVICE_TYPE.PAYPAL]: {
    label: 'Paypal',
  },
  [BUSINESS_PAYMENT_SERVICE_TYPE.AMAZON]: {
    label: 'Amazon',
  },
};

export const BUSINESS_PLATFORM_TYPE = {
  APP_STORE: 'appStore',
  GOOGLE: 'google',
  SHOPIFY: 'shopify',
  SHOPIFY_PARTNER: 'shopifyPartner',
  DESKTOP: 'desktop',
};

export const BUSINESS_PLATFORM_TYPE_META = {
  [BUSINESS_PLATFORM_TYPE.APP_STORE]: {
    label: 'App Store',
  },
  [BUSINESS_PLATFORM_TYPE.GOOGLE]: {
    label: 'Google',
  },
  [BUSINESS_PLATFORM_TYPE.SHOPIFY]: {
    label: 'Shopify',
  },
  [BUSINESS_PLATFORM_TYPE.SHOPIFY_PARTNER]: {
    label: 'Shopify Partner',
  },
  [BUSINESS_PLATFORM_TYPE.DESKTOP]: {
    label: 'Desktop',
  },
};

