import React, { useRef, useState } from 'react';
import moment from 'moment';
import { Flex, Box, useToast } from '@chakra-ui/react';
import UploadFiles from './UploadFiles';
import {
  getTwelveConnectFilesApi,
  uploadTwelveConnectFileApi,
} from '../../api/twelveConnectApi';
import { useContext, useEffect } from 'react';
import { CustomerContext } from '../../context/customerContext';
import FilesSummary from './FilesSummary';

function TwelveConnect() {
  const { customer } = useContext(CustomerContext);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const progressInfosRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    setLoading(true);
    getTwelveConnectFilesApi(customer?.id).then((_files) => {
      _files.sort((a, b) => {
        return moment(b.lastModified) - moment(a.lastModified);
      });
      setFiles(_files);
    }).finally(() => {
      setLoading(false);
    });
  };

  const onSelectFiles = (_selectedFiles) => {
    setSelectedFiles(_selectedFiles);
    setProgressInfos({ val: [] });
  };

  const upload = (index, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return uploadTwelveConnectFileApi(customer?.id, file, (event) => {
      _progressInfos[index].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
      .then(() => {
        _progressInfos[index].done = true;
        setProgressInfos({ val: _progressInfos });
      })
      .catch(() => {
        _progressInfos[index].percentage = 0;
        setProgressInfos({ val: _progressInfos });
        toast({
          title: 'Error',
          description: 'Failed to upload file.',
          status: 'error',
        });
      });
  };

  const onUploadFiles = () => {
    const _selectedFiles = Array.from(selectedFiles);

    let _progressInfos = _selectedFiles.map((file) => ({
      percentage: 0,
      name: file.name,
      lastModified: new Date(),
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = _selectedFiles.map((file, i) => upload(i, file));

    Promise.all(uploadPromises).then(() => {
      const _files = [...progressInfosRef.current.val, ...files];
      setFiles(_files);

      setProgressInfos({ val: [] });
    });
  };

  return (
    <Flex>
      <Box>
        <UploadFiles onUploadFiles={onUploadFiles} onSelectFiles={onSelectFiles} />
      </Box>
      <Box ml={5} flex={1}>
        <FilesSummary files={files} progressInfos={progressInfos.val} loading={loading} />
      </Box>
    </Flex>
  );
}

export default TwelveConnect;
