import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { getCustomerUsersApi } from 'api/adminApi';
import DataTable from 'components/DataTable';
import Card from 'components/card/Card';
import moment from 'moment';
import { useEffect, useState } from 'react';
import AddUserModal from '../AddUserModal';
const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: 'Email',
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) =>
      info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
    header: 'Created At',
  }),
  columnHelper.accessor('lastLoginAt', {
    cell: (info) =>
      info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
    header: 'Last Login',
  }),
];

function CustomerUsers({ customerId }) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const toast = useToast();

  useEffect(() => {
    const init = async () => {
      await loadUsers();
    };

    init();
  }, []);

  const loadUsers = async () => {
    try {
      const _users = await getCustomerUsersApi(customerId);
      setUsers(_users);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load customer users',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box minW={'container.lg'} maxW={'max'}>
      <Card>
        <Heading variant={'sectionHeader'} borderColor={'secondaryGray.600'} mb={5}>
          Users
        </Heading>
        <DataTable data={users} columns={columns} />
        <Flex justify={'flex-end'} align={'center'} mt={20}>
          <Button variant={'brand'} onClick={() => setShowAddUserModal(true)}>Add User</Button>
        </Flex>
      </Card>
      <AddUserModal
        show={showAddUserModal}
        header={'Add New User'}
        selectedCustomerId={customerId}
        // companyNames={companyNames}
        onSubmitSuccess={async () => {
          setShowAddUserModal(false);
          await loadUsers();
          toast({
            title: 'Success',
            description: 'User created successfully',
            status: 'success',
          });
        }}
        onModalClose={() => setShowAddUserModal(false)}
      />
    </Box>
  );
}

export default CustomerUsers;
