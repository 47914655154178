import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const toggle = definePartsStyle((props) => {
  return {
    track: {
      bg: props.color,
      _checked: {
        bg: props.color,
      },
    },
  };
});

export const SwitchTheme = defineMultiStyleConfig({ variants: { toggle } });
