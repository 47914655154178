export const INSTALLMENT_TYPE = {
  FIRST_TIME: 'firstTime',
  SUBSEQUENT: 'subsequent',
  SUBSEQUENT_AMENDED: 'subsequentAmended',
  ANNUAL: 'annual',
  ANNUAL_AMENDED: 'annualAmended',
};

export const INSTALLMENT_TYPE_META = {
  [INSTALLMENT_TYPE.FIRST_TIME]: {
    label: 'First Time',
  },
  [INSTALLMENT_TYPE.SUBSEQUENT]: {
    label: 'Subsequent',
  },
  [INSTALLMENT_TYPE.SUBSEQUENT_AMENDED]: {
    label: 'Subsequent Amended',
  },
  [INSTALLMENT_TYPE.ANNUAL]: {
    label: 'Annual',
  },
  [INSTALLMENT_TYPE.ANNUAL_AMENDED]: {
    label: 'Annual Amended',
  },
};

export const INSTALLMENT_OWNER_TYPE = {
  TWELVE: 'twelve',
  SPV: 'spv',
};

export const INSTALLMENT_OWNER_TYPE_META = {
  [INSTALLMENT_OWNER_TYPE.TWELVE]: {
    label: 'Twelve',
  },
  [INSTALLMENT_OWNER_TYPE.SPV]: {
    label: 'SPV',
  },
};



export const INSTALLMENT_STATUS = {
  PENDING: 'pending',
  PARTIALLY_PAID: 'partiallyPaid',
  FULLY_PAID: 'fullyPaid',
}

export const INSTALLMENT_STATUS_META = {
  [INSTALLMENT_STATUS.PENDING]: {
    label: 'Open',
    color: '#E5FAF4',
  },
  [INSTALLMENT_STATUS.PARTIALLY_PAID]: {
    label: 'Partially Paid',
    color: '#ECE5FA',
  },
  [INSTALLMENT_STATUS.FULLY_PAID]: {
    label: 'Fully Paid',
    color: '#B3CEFF',
  },
}

