import {
  Box,
  CloseButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { CalendarIcon } from '@chakra-ui/icons';
import { dateFormat } from 'helpers/propTypesHelper';
import moment from 'moment';
import { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/scss/DatePicker.scss';

const convertToCurrentOffset = (date) => {
  if (!date) {
    return null;
  }

  const today = moment().format('YYYY-MM-DD');

  const now = moment(today);
  const nowUtc = moment.utc(today);

  let timezoneDiff = Math.abs(nowUtc.diff(now, 'minutes'));

  const newDate = moment(date).add(timezoneDiff, 'minutes').toDate();

  return newDate;
};

const CustomInput = forwardRef(
  (
    { elmId, value, onClick, isDisabled, inputProps, onClear, hideCloseButton },
    ref
  ) => {
    return (
      <InputGroup>
        <Input
          ref={ref}
          id={elmId}
          onClick={onClick}
          value={value}
          isDisabled={isDisabled}
          {...inputProps}
          onChange={() => {}}
        />
        <InputRightElement mr={hideCloseButton ? 0 : 3}>
          {!hideCloseButton && (
            <CloseButton
              size={'sm'}
              visibility={value ? 'visible' : 'hidden'}
              mr={'2px'}
              onClick={onClear}
            />
          )}
          <CalendarIcon boxSize={3} />
        </InputRightElement>
      </InputGroup>
    );
  }
);

export const BasePicker = forwardRef(
  (
    {
      id,
      value,
      onChange,
      isDisabled,
      hideCloseButton,
      dateFormat,
      pickerProps,
      inputProps,
      excludeDates,
    },
    ref
  ) => {

    const onPickerChange = (_date) => {
      const dateStr = moment(_date).format('YYYY-MM-DD');
      onChange(dateStr);
    };

    const onClear = () => {
      onChange(null);
    };

    return (
      <Box className={'tw-date-picker tw-date-picker__full-width'}>
        <DatePicker
          {...pickerProps}
          selected={convertToCurrentOffset(value)}
          onChange={onPickerChange}
          calendarClassName={'tw-calendar'}
          customInput={
            <CustomInput
              elmId={id}
              ref={ref}
              isDisabled={isDisabled}
              inputProps={inputProps}
              onClear={onClear}
              hideCloseButton={hideCloseButton}
            />
          }
          disabledKeyboardNavigation
          showPopperArrow={false}
          dateFormat={dateFormat || 'MMMM d yyyy'}
          excludeDates={excludeDates}
        />
      </Box>
    );
  }
);

export const TwDatePicker = forwardRef(
  (
    {
      id,
      value,
      onChange,
      isDisabled,
      hideCloseButton,
      dateFormat,
      minDate,
      maxDate,
      inputProps,
      excludeDates,
    },
    ref
  ) => {
    return (
      <BasePicker
        id={id}
        value={value}
        dateFormat={dateFormat}
        onChange={onChange}
        isDisabled={isDisabled}
        hideCloseButton={hideCloseButton}
        ref={ref}
        pickerElement={DatePicker}
        inputProps={inputProps}
        pickerProps={{
          minDate: convertToCurrentOffset(minDate),
          maxDate: convertToCurrentOffset(maxDate),
        }}
        excludeDates={excludeDates?.map((date) => convertToCurrentOffset(date))}
      />
    );
  }
);

TwDatePicker.propTypes = {
  id: PropTypes.string,
  value: dateFormat,
  dateFormat: PropTypes.string,
  isDisabled: PropTypes.bool,
  excludeDates: PropTypes.arrayOf(dateFormat),
  hideCloseButton: PropTypes.bool,
  minDate: dateFormat,
  maxDate: dateFormat,
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
};
