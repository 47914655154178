import { defineStyleConfig } from '@chakra-ui/react';

const disabledStyle = {
  bg: 'gray.100',
  opacity: 1,
};
export const ButtonTheme = defineStyleConfig({
  variants: {
    primary: {
      bg: 'primary.main',
      color: 'white',
      _active: {
        bg: 'primary.main',
      },
      _hover: {
        bg: '#0669db',
        _disabled: {
          ...disabledStyle,
        },
      },
      _disabled: {
        ...disabledStyle,
      },
    },
    red: {
      bg: 'red.500',
      color: 'white',
      _active: {
        bg: 'red.500',
      },
      _hover: {
        bg: 'red.600',
        _disabled: {
          ...disabledStyle,
        },
      },
      _disabled: {
        ...disabledStyle,
      },
    },
    gray: {
      bg: 'gray.main',
      color: 'white',
      _active: {
        bg: 'gray.main',
      },
      _hover: {
        bg: 'gray.200',
        _disabled: {
          ...disabledStyle,
        },
      },
      _disabled: {
        ...disabledStyle,
      },
    },
    warning: {
      bg: 'yellow.300',
      color: 'white',
      _active: {
        bg: 'yellow.300',
      },
      _hover: {
        bg: 'yellow.400',
        _disabled: {
          ...disabledStyle,
        },
      },
      _disabled: {
        ...disabledStyle,
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
});
