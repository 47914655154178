import { createIcon } from '@chakra-ui/react';

export const PinkArrowIcon = createIcon({
  displayName: 'PinkArrowIcon',
  viewBox: "0 0 31 17",
  
  path: (
    <>
      <path
        d="M19.9772 13.4891V3.18139L23.4448 3.18139V0L30.6665 8.3351L23.4448 16.6702V13.4888L19.9772 13.4891Z"
        fill="#F322F8"
      />
      <path
        d="M17.7183 13.4891L17.7183 3.18139L7.88811 3.18139V0L0.666454 8.3351L7.88811 16.6702L7.88811 13.4888L17.7183 13.4891Z"
        fill="#E0E8F0"
      />
    </>
  ),
});
