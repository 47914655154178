import { Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import DataTable from 'components/DataTable';
import { Currency } from 'components/Number';
import moment from 'moment';
import PropTypes from 'prop-types';

const columnHelper = createColumnHelper();

const getCurrencyCellValue = (info) => {
  const value = info.getValue();
  return value === null ? 'N/A' : <Currency value={value} />;
};

const createColumns = (bankAccountOptions) => {
  const columns = [
    columnHelper.accessor('transferDate', {
      cell: (info) =>
        info.getValue() === null ? (
          'N/A'
        ) : (
          <Text whiteSpace={'nowrap'}>
            {moment(info.getValue()).format('MMM DD, YYYY')}
          </Text>
        ),
      header: 'Transfer Date',
    }),
    columnHelper.accessor('amount', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Amount',
    }),
    columnHelper.accessor('principal', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Principal',
    }),
    columnHelper.accessor('interest', {
      cell: (info) => getCurrencyCellValue(info),
      header: 'Interest',
    }),
    columnHelper.accessor('bankAccountId', {
      cell: (info) => {
        const bankAccountId = info.getValue();
        const bankAccount = bankAccountOptions.find(
          (option) => option.value === bankAccountId
        );

        return bankAccount ? bankAccount.label : 'N/A';
      },
      header: 'Bank Account',
    }),
  ];

  return columns;
};

const InstallmentRepaymentsTable = ({ repayments, bankAccountOptions }) => {
  return (
    <DataTable
      columns={createColumns(bankAccountOptions)}
      data={repayments}
      defaultSortBy={{ id: 'transferDate', desc: true }}
      rowHeight={'100px'}
    />
  );
};

InstallmentRepaymentsTable.propTypes = {
  repayments: PropTypes.array.isRequired,
  bankAccountOptions: PropTypes.array.isRequired,
};

export default InstallmentRepaymentsTable;
