import { sendGACustomEvent } from '.';

export const sendAddNewConnectorClickGAEvent = (connectorType) => {
  sendGACustomEvent('add_new_connector_click', {
    connector_type: connectorType,
  });
};

export const sendExistingConnectorConnectClickGAEvent = (connectorType) => {
  sendGACustomEvent('connect_existing_connector_click', {
    connector_type: connectorType,
  });
};

export const sendConnectorPlaidStartedGAEvent = () => {
  sendGACustomEvent('connector_plaid_started');
};

export const sendConnectorPlaidCompletedGAEvent = (institutionName) => {
  sendGACustomEvent('connector_plaid_completed', {
    institution_name: institutionName,
  });
};

export const sendConnectorFivetranStartedGAEvent = (connectorType) => {
  sendGACustomEvent('connector_fivetran_started', {
    connector_type: connectorType,
  });
};

export const sendConnectorFivetranCompletedGAEvent = (connectorType) => {
  sendGACustomEvent('connector_fivetran_completed', {
    connector_type: connectorType,
  });
};

export const sendConnectorTwelveStartedGAEvent = (connectorType) => {
  sendGACustomEvent('connector_twelve_started', {
    connector_type: connectorType,
  });
};

export const sendConnectorTwelveCompletedGAEvent = (connectorType) => {
  sendGACustomEvent('connector_twelve_completed', {
    connector_type: connectorType,
  });
};