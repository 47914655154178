import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from '@descope/react-sdk';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-dm-sans';
import 'typeface-roboto';
import 'version';
import App from './apps/App';
import { initGA } from './ga';
import './index.css';
import reportWebVitals from './reportWebVitals';
import themeV1 from './themes/v1/theme';
import themeV2 from './themes/v2/theme';


initGA()
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = process.env.REACT_APP_IS_NEW_DESIGN === 'true' ? themeV2 : themeV1;

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID} baseUrl={process.env.REACT_APP_DESCOPE_BASE_URL}>
        <ChakraProvider theme={theme} toastOptions={{defaultOptions : {position: 'top', duration: 5000, isClosable: true}}}>
            <App />
        </ChakraProvider>
      </AuthProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
