import { Icon } from '@chakra-ui/react';

export default function FacebookIcon(props) {
  return (
    <Icon viewBox="0 0 400 400" {...props}>
      <defs>
        <style>
          {`.fb-cls-1 {
        fill: #3c5a99;
      }

      .fb-cls-2 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
        </style>
      </defs>
      <rect
        className="fb-cls-1"
        x="0.8"
        y="0.8"
        width="398.3"
        height="398.35"
        rx="22"
        ry="22"
      />
      <path
        className="fb-cls-2"
        d="M275.7,399.2V244.9h51.8l7.8-60.1H275.7V146.4c0-17.4,4.8-29.3,29.8-29.3h31.8V63.4A426,426,0,0,0,290.9,61c-45.9,0-77.3,28-77.3,79.5v44.3H161.7v60.1h51.9V399.2Z"
      />
    </Icon>
  );
}
