import {
  AbsoluteCenter,
  Box,
  Flex,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  getCustomerBusinessInfoApi,
  upsertCustomerBusinessInfoApi,
} from 'api/underwritingApi';
import Card from 'components/card/Card';
import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditCustomerSettings from './EditCustomerSettings';

import { TwDatePicker } from 'components/DatePicker';
import { currencyFormat } from 'components/Number';
import { CurrencyNumberInput, TwNumberInput } from 'components/NumberInput';
import SelectInput from 'components/inputs/SelectInput';
import {
  BUSINESS_MODEL_TYPE_META,
  BUSINESS_PAYMENT_SERVICE_TYPE_META,
  BUSINESS_PLATFORM_TYPE_META,
} from 'constants/underwritingConstants';
import { CockpitCustomerContext } from 'context/CockpitCustomerContext';
import { generateSelectOptions } from 'helpers/inputHelper';

const fields = [
  {
    label: 'Legal Name',
    name: 'legalName',
    element: Input,
    section: 1,
  },
  {
    label: 'HQ',
    name: 'headquarters',
    element: Input,
    section: 1,
  },
  {
    label: 'Founded',
    name: 'foundedDate',
    element: TwDatePicker,
    section: 1,
    formatValue: (value) => {
      return moment(value).format('MMMM DD, YYYY');
    },
  },
  {
    label: 'Employees',
    name: 'numberOfEmployees',
    element: TwNumberInput,
    section: 1,
  },
  {
    label: 'Founders / mgmt.',
    name: 'founders',
    element: Input,
    section: 1,
  },
  {
    label: 'Business Model',
    name: 'model',
    element: SelectInput,
    placeholder: 'Select Business Model',
    options: generateSelectOptions({ metaObj: BUSINESS_MODEL_TYPE_META }),
    section: 2,
    formatValue: (value) => {
      return BUSINESS_MODEL_TYPE_META[value]?.label;
    },
  },
  {
    label: 'Business Start Date',
    name: 'businessStartDate',
    element: TwDatePicker,
    section: 2,
    formatValue: (value) => {
      return moment(value).format('MMMM DD, YYYY');
    },
  },
  {
    label: 'Product',
    name: 'productName',
    element: Input,
    section: 2,
  },
  {
    label: 'Platforms Mix',
    name: 'platformTypes',
    options: generateSelectOptions({
      metaObj: BUSINESS_PLATFORM_TYPE_META,
    }),
    element: SelectInput,
    isMulti: true,
    placeholder: 'Select Platforms',
    section: 2,
    formatValue: (value) => {
      return value
        ?.map((platform) => {
          return BUSINESS_PLATFORM_TYPE_META[platform]?.label;
        })
        .join(', ');
    },
  },
  {
    label: 'Payment Processor',
    name: 'paymentServices',
    element: SelectInput,
    placeholder: 'Select Payment Processor',
    options: generateSelectOptions({
      metaObj: BUSINESS_PAYMENT_SERVICE_TYPE_META,
    }),
    isMulti: true,
    section: 3,
    formatValue: (value) => {
      return value
        .map((service) => {
          return BUSINESS_PAYMENT_SERVICE_TYPE_META[service]?.label;
        })
        .join(', ');
    },
  },
  {
    label: 'Raised to Date',
    name: 'totalRaisedAmount',
    element: CurrencyNumberInput,
    section: 3,
    formatValue: (value) => currencyFormat(value),
  },
  {
    label: 'Last Round',
    name: 'lastRoundDate',
    element: TwDatePicker,
    section: 3,
    formatValue: (value) => {
      return moment(value).format('MMMM DD, YYYY');
    },
  },
  {
    label: 'VC Tier',
    name: 'vcTier',
    element: TwNumberInput,
    section: 3,
  },
  {
    label: 'Fundraising History',
    name: 'fundraisingHistory',
    element: Input,
    section: 3,
  },
  {
    label: 'Notable Investors',
    name: 'notableInvestors',
    element: Input,
    section: 3,
  },

];

const FieldsSection = ({ fields, data }) => {
  return fields.map((field) => {
    let value = data?.[field.name];
    if (!!value && field.formatValue) {
      value = field.formatValue(value);
    }
    return (
      <Fragment key={field.name}>
        <Text color={'secondaryGray.600'} fontWeight={500}>
          {field.label}
        </Text>
        <Text>{value}</Text>
      </Fragment>
    );
  });
};

const CustomerSettings = (props) => {
  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const { customerId } = useParams();
  const { updateBusinessModel } = useContext(CockpitCustomerContext);

  useEffect(() => {
    getBusinessInfo();
  }, []);

  const getBusinessInfo = async () => {
    setLoading(true);
    try {
      const _businessInfo = await getCustomerBusinessInfoApi({
        customerId,
      });
      setBusinessInfo(_businessInfo);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load customer settings',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const saveBusinessInfo = async (_data) => {
    setLoading(true);
    try {
      await upsertCustomerBusinessInfoApi({
        customerId,
        data: _data,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save customer settings',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onSave = async (_data) => {
    await saveBusinessInfo(_data);
    if (_data.model && businessInfo?.model != _data.model) {
      updateBusinessModel(_data.model);
    }
    setBusinessInfo(_data);
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box minW={'container.lg'} maxW={'max'}>
      <Flex justifyContent={'end'}>
        <EditCustomerSettings
          fields={fields}
          onSave={onSave}
          data={businessInfo}
        />
      </Flex>
      <Card>
        <Heading variant={'sectionHeader'} borderColor={'secondaryGray.600'}>
          Customer settings
        </Heading>
        <SimpleGrid
          gridTemplateColumns={'min-content min-content'}
          whiteSpace={'nowrap'}
          spacingY={0}
          spacingX={10}
          fontSize={'sm'}
          mt={45}
        >
          <FieldsSection
            fields={fields.filter((field) => field.section === 1)}
            data={businessInfo}
          />
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
          <FieldsSection
            fields={fields.filter((field) => field.section === 2)}
            data={businessInfo}
          />
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
          <FieldsSection
            fields={fields.filter((field) => field.section === 3)}
            data={businessInfo}
          />
        </SimpleGrid>
      </Card>
    </Box>
  );
};

CustomerSettings.propTypes = {};

export default CustomerSettings;
