import { Descope, useSession } from '@descope/react-sdk';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { AbsoluteCenter, Spinner } from '@chakra-ui/react';

function Login({ onLoginSuccess }) {
  const { isSessionLoading } = useSession();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const onSuccess = useCallback((event) => {
    if (event.detail?.refreshJwt) {
      const cookies = new Cookies();
      const domain =
        window.location.hostname === 'localhost'
          ? ''
          : process.env.REACT_APP_API_URL.split('//')[1];

      cookies.set('DSR', event.detail.refreshJwt, {
        domain,
        path: '/',
        sameSite: 'none',
        secure: true,
      });
    }

    onLoginSuccess && onLoginSuccess();
    // navigate('/');
  }, []);

  const onError = useCallback(() => {
    setErrorMessage('Something went wrong');
  }, [setErrorMessage]);

  if (isSessionLoading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <AbsoluteCenter mt={[50, 0]} minH={290} w={[320, 480]}>
      <Descope
        flowId={'sign-in'}
        onSuccess={onSuccess}
        onError={(error) => {
          onError();
        }}
      />
      {errorMessage && (
        <div
          className="error"
          style={{
            margin: 'auto',
            color: 'red',
          }}
        >
          {errorMessage}
        </div>
      )}
    </AbsoluteCenter>
  );
}

Login.propTypes = {
  loading: PropTypes.bool,
  onLoginSuccess: PropTypes.func,
};

export default Login;
