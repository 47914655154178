import { Box, useStyleConfig } from '@chakra-ui/react';

function WhiteBox(props) {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('WhiteBox', { variant });

  // Pass the computed styles into the `__css` prop
  return <Box __css={styles} {...rest} />;
}

export default WhiteBox;
