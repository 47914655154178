import { mode } from '@chakra-ui/theme-tools';
export const textareaStyles = {
  components: {
    Textarea: {
      baseStyle: {
        field: {
          fontWeight: 400,
          borderRadius: '8px',
        },
      },

      variants: {
        comment: (props) => ({
          bg: mode('transparent', 'navy.800')(props),
          border: '1px solid',
          color: mode('secondaryGray.600', 'white')(props),
          borderColor: mode('#eee', 'whiteAlpha.100')(props),
          borderRadius: '12px',
          fontSize: 'sm',
          p: '2px 2px 2px 10px;',
          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
        }),
      },
    },
  },
};
