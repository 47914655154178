import {
  Flex,
  InputGroup,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react';
import Decimal from 'decimal.js';
import React, { forwardRef } from 'react';
import { numberFormat } from './Number';

export const TwNumberInput = forwardRef(
  (
    {
      isPercentage,
      onChange,
      onBlur,
      value,
      isDisabled,
      showPrefix,
      w,
      max,
      min,
      step,
      precision,
      readOnly,
      inputProps,
      isCurrency,
      placeholder,
    },
    ref
  ) => {
    const [values, setValues] = React.useState({
      strValue: value,
      numValue:
        value === null || value === undefined || value === ''
          ? null
          : Number(value),
    });

    let patternOnChange = '^-?\\d*(\\.\\d{0,3})?$';
    let pattern = '^-?\\d{1,3}(,\\d{3})*(\\.\\d{1,3})?$';
    if (precision === 0) {
      patternOnChange = '^-?\\d*$';
      pattern = '^-?\\d{1,3}(,\\d{3})*$';
    }

    const format = (value) => {
      if (value === '-') {
        return value;
      }

      if (
        isNaN(value) ||
        value === null ||
        value === undefined ||
        value === ''
      ) {
        return '';
      }

      let newValue = value;

      if (isPercentage) {
        if (!(typeof value === 'string' && value.endsWith('.'))) {
          newValue = value * 100;
        }
      }

      newValue = numberFormat(newValue, {
        maximumFractionDigits: precision || 2,
      });

      if (typeof value === 'string' && value.endsWith('.')) {
        return newValue + '.';
      }

      return newValue;
    };

    const onValueChange = (strValue, numValue) => {
      if (strValue.match(patternOnChange) === null) {
        return;
      }

      let value = numValue;

      if (strValue.endsWith('.') || strValue === '-') {
        value = strValue;
      } else if (isPercentage && !strValue.endsWith('.')) {
        numValue = Decimal.div(numValue,100).toNumber();
        strValue = numValue.toString();
        value = numValue;
      }

      setValues({ strValue, numValue });
      onChange && onChange(value);
    };

    const elm = (
      <InputGroup w={w || 'full'}>
        <NumberInput
          isDisabled={isDisabled}
          w={'full'}
          pattern={pattern}
          value={format(value)}
          step={step}
          max={max}
          min={min}
          precision={precision}
          readOnly={readOnly}
          onChange={(strValue, numValue) => {
            onValueChange(strValue, numValue);
          }}
          onBlur={() => {
            onChange && onChange(values.numValue);
            onBlur && onBlur(values.numValue);
          }}
        >
          <NumberInputField {...inputProps} placeholder={placeholder} ref={ref} />
        </NumberInput>
      </InputGroup>
    );

    if (showPrefix) {
      return (
        <Flex gap={'5px'} alignItems={'center'} justifyContent={'flex-end'}>
          <Text>{isCurrency ? '$' : isPercentage ? '%' : ''}</Text>
          {elm}
        </Flex>
      );
    }
    
    return elm;
  }
);

export const CurrencyNumberInput = forwardRef((props, ref) => {
  return <TwNumberInput {...props} isCurrency={true} precision={2} ref={ref} />;
});

export const PercentageNumberInput = forwardRef((props, ref) => {
  return (
    <TwNumberInput {...props} isPercentage={true} precision={3} ref={ref} />
  );
});
