import { Icon } from '@chakra-ui/react';

export default function InstagramIcon(props) {
  return (
    <Icon viewBox="0 0 256 256" width={'400px'} height={'400px'} {...props}>
      <defs>
        <radialGradient
          cx="19.38"
          cy="42.035"
          r="44.899"
          gradientUnits="userSpaceOnUse"
          id="color-1"
        >
          <stop offset="0" stopColor="#ffdd55" />
          <stop offset="0.328" stopColor="#ff543f" />
          <stop offset="0.348" stopColor="#fc5245" />
          <stop offset="0.504" stopColor="#e64771" />
          <stop offset="0.643" stopColor="#d53e91" />
          <stop offset="0.761" stopColor="#cc39a4" />
          <stop offset="0.841" stopColor="#c837ab" />
        </radialGradient>
        <radialGradient
          cx="11.786"
          cy="5.5403"
          r="29.813"
          gradientUnits="userSpaceOnUse"
          id="color-2"
        >
          <stop offset="0" stopColor="#4168c9" />
          <stop offset="0.999" stopColor="#4168c9" stopOpacity="0" />
        </radialGradient>
      </defs>
      <g transform="translate(-42.66667,-42.66667) scale(1.33333,1.33333)">
        <g
          fill="none"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
          // style="mix-blend-mode: normal"
        >
          <g transform="scale(5.33333,5.33333)">
            <path
              d="M34.017,41.99l-20,0.019c-4.4,0.004 -8.003,-3.592 -8.008,-7.992l-0.019,-20c-0.004,-4.4 3.592,-8.003 7.992,-8.008l20,-0.019c4.4,-0.004 8.003,3.592 8.008,7.992l0.019,20c0.005,4.401 -3.592,8.004 -7.992,8.008z"
              fill="url(#color-1)"
            />
            <path
              d="M34.017,41.99l-20,0.019c-4.4,0.004 -8.003,-3.592 -8.008,-7.992l-0.019,-20c-0.004,-4.4 3.592,-8.003 7.992,-8.008l20,-0.019c4.4,-0.004 8.003,3.592 8.008,7.992l0.019,20c0.005,4.401 -3.592,8.004 -7.992,8.008z"
              fill="url(#color-2)"
            />
            <path
              d="M24,31c-3.859,0 -7,-3.14 -7,-7c0,-3.86 3.141,-7 7,-7c3.859,0 7,3.14 7,7c0,3.86 -3.141,7 -7,7zM24,19c-2.757,0 -5,2.243 -5,5c0,2.757 2.243,5 5,5c2.757,0 5,-2.243 5,-5c0,-2.757 -2.243,-5 -5,-5z"
              fill="#ffffff"
            />
            <circle cx="31.5" cy="16.5" r="1.5" fill="#ffffff" />
            <path
              d="M30,37h-12c-3.859,0 -7,-3.14 -7,-7v-12c0,-3.86 3.141,-7 7,-7h12c3.859,0 7,3.14 7,7v12c0,3.86 -3.141,7 -7,7zM18,13c-2.757,0 -5,2.243 -5,5v12c0,2.757 2.243,5 5,5h12c2.757,0 5,-2.243 5,-5v-12c0,-2.757 -2.243,-5 -5,-5z"
              fill="#ffffff"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}
