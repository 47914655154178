import { defineStyleConfig } from "@chakra-ui/react";

export const HeadingTheme = defineStyleConfig({
  sizes: {
    l: {
     fontSize:"24px",
     lineHeight:"24px"
    },
  }
});
