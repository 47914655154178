import {
	Box,
	Flex,
	Grid,
	GridItem,
	Icon,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tr,
} from '@chakra-ui/react';
import Moment from 'react-moment';
import WhiteBox from './WhiteBox';

import moment from 'moment';

import 'assets/scss/CustomerPerformance.scss';
import { pluralize } from 'helpers/languageHelper';
import { IoMdMore } from 'react-icons/io';
import { Currency, Percentage } from './Number';
import { FundingIcon } from './icons/connectors/FundingIcon';
import { GreenArrowIcon } from './icons/connectors/GreenArrowIcon';
import { PinkArrowIcon } from './icons/connectors/PinkArrowIcon';
import { ServiceFeesIcon } from './icons/connectors/ServiceFeesIcon';

function StatsTitle(props) {
  return (
    <Box
      mb="5"
      color={'gray.main'}
      fontSize={{ base: '14px', lg: '16px' }}
      fontWeight={500}
      textTransform={'uppercase'}
      {...props}
    >
      {props.children}
    </Box>
  );
}

function StatsValue(props) {
  return (
    <Box
      color={'black.main'}
      fontSize={{ base: '18px', lg: '22px' }}
      fontWeight={700}
      lineHeight={7}
      {...props}
    >
      {props.children}
    </Box>
  );
}

function SmallGrayText(props) {
  return (
    <Text
      fontSize={'12px'}
      fontWeight="400"
      color="gray.main"
      lineHeight={'5'}
      {...props}
    >
      {props.children}
    </Text>
  );
}

function NextFundingBox({ nextData, title, icon }) {
  const { date, amount, daysLeft } = nextData;

  let daysLeftText = '';
  let diff = daysLeft ? daysLeft : moment().diff(moment(date), 'days');

  const daysText = pluralize({
    count: Math.abs(diff),
    singular: 'day',
    plural: 'days',
  });

  if (diff != 0) {
    daysLeftText = diff > 0 ? `(${daysText} ago)` : `(in ${daysText})`;
  } else {
    daysLeftText = '(Today)';
  }

  return (
    <WhiteBox
      gap="4"
      rounded={20}
      py={{ base: '4', lg: '30px' }}
      px={{ base: '4', lg: '6' }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'start'}
      >
        <Box>
          <StatsTitle>{title}</StatsTitle>
          <Flex flexWrap={'wrap'} rowGap="3">
            <StatsValue display={'flex'} alignItems={'end'}>
              <Moment format="l">{date}</Moment>&nbsp;
              <SmallGrayText>{daysLeftText}</SmallGrayText>
            </StatsValue>
            <StatsValue ml={5}>
              <Currency value={amount} />
            </StatsValue>
          </Flex>
        </Box>
        <Icon as={icon} w={8} />
      </Box>
    </WhiteBox>
  );
}

function Transactions({ transactions }) {
  return (
    <WhiteBox rounded={20} mt="5" p={0} mb={8}>
      <Box py={5} pl={6} textTransform={'uppercase'} fontWeight={700}>
        Balance Transactions
      </Box>
      <TableContainer className="transaction-table" overflow={'auto'}>
        <Table variant="simple">
          <Tbody>
            {transactions.map((transaction, index) => {
              const isPositive = transaction.amount > 0;
              return (
                <Tr key={`tr${index}`}>
                  <Td>
                    <Box display={'flex'} alignItems={'center'} gap="32px">
                      <Icon
                        as={isPositive ? GreenArrowIcon : PinkArrowIcon}
                        w="4"
                        h="5"
                      />
                      <Text color={'black.main'} fontWeight={700}>
                        {' '}
                        {isPositive ? 'Funding' : 'Repayment'}{' '}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Moment format="L">{transaction.date}</Moment>
                  </Td>
                  <Td>
                    <Currency value={transaction.amount} />
                  </Td>
                  <Td textAlign={'end'}>
                    <Icon
                      as={IoMdMore}
                      color={'gray.main'}
                      ml={'auto'}
                      boxSize={5}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </WhiteBox>
  );
}

function CustomerPerformance({ data }) {
  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '3' }}>
          <WhiteBox
            gap="4"
            rounded={20}
            py={{ base: '4', lg: '30px' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Box>
                <StatsTitle>
                  Total funding <br /> to date
                </StatsTitle>
                <StatsValue>
                  <Currency value={data.totalFunding} />
                </StatsValue>
              </Box>
              <Icon as={FundingIcon} w={8} h={8} />
            </Box>
          </WhiteBox>
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '8', xl: '9' }}>
          <WhiteBox
            bg="white"
            shadow={'box'}
            rounded={20}
            py={{ base: '4', lg: '30px' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box
              display={'flex'}
              flexWrap={{ base: 'wrap', lg: 'nowrap' }}
              alignItems={'center'}
              gap={{ base: '8', lg: '60px' }}
            >
              <Box>
                <StatsTitle>
                  Total <br /> obligations
                </StatsTitle>
                <StatsValue>
                  <Currency value={data.obligation.total} />
                </StatsValue>
              </Box>
              <Box width="100%">
                <Flex flexWrap={'wrap'} gridGap={'30px'} rowGap={3}>
                  <Flex alignItems={'center'} gap={'10px'}>
                    <Box w={5} h={5} rounded={'full'} bg={'pink.main'}></Box>
                    <Box
                      color="gray.main"
                      fontSize={{ base: '12px', lg: '14px' }}
                    >
                      Paid <Currency value={data.obligation.amountPaid} /> (
                      <Percentage value={data.obligation.pctPaid} />)
                    </Box>
                  </Flex>
                  <Flex alignItems={'center'} gap={'10px'}>
                    <Box w={5} h={5} rounded={'full'} bg={'green.200'}></Box>
                    <Box
                      color="gray.main"
                      fontSize={{ base: '12px', lg: '14px' }}
                    >
                      Left to pay{' '}
                      <Currency value={data.obligation.amountLeft} /> (
                      <Percentage value={data.obligation.pctLeft} />)
                    </Box>
                  </Flex>
                </Flex>
                <Box rounded={'full'} display={'flex'} mt="5">
                  <Box
                    bg={'pink.main'}
                    w={`${data.obligation.pctPaid * 100}%`}
                    h="5"
                    borderLeftRadius="full"
                  ></Box>
                  <Box
                    bg={'green.200'}
                    w={`${data.obligation.pctLeft * 100}%`}
                    h="5"
                    borderRightRadius="full"
                  ></Box>
                </Box>
              </Box>
            </Box>
          </WhiteBox>
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '4' }}>
          <NextFundingBox
            nextData={data.nextFunding}
            title={'Next Funding'}
            icon={GreenArrowIcon}
          />
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '4' }}>
          <NextFundingBox
            nextData={data.nextPayment}
            title={'Next Payment'}
            icon={PinkArrowIcon}
          />
        </GridItem>
        <GridItem colSpan={{ base: '12', lg: '4', xl: '4' }}>
          <WhiteBox
            gap="4"
            rounded={20}
            py={{ base: '4', lg: '30px' }}
            px={{ base: '4', lg: '6' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              <Box>
                <StatsTitle mb="5">Service fees</StatsTitle>
                <Flex flexWrap={'wrap'} gap="9">
                  <StatsValue display={'flex'} alignItems={'end'}>
                    <Percentage value={data.serviceFees} />
                    <SmallGrayText>&nbsp;(of used funds)</SmallGrayText>
                  </StatsValue>
                </Flex>
              </Box>
              <Icon as={ServiceFeesIcon} w={10} h={8} />
            </Box>
          </WhiteBox>
        </GridItem>
      </Grid>
      <Transactions transactions={data.transactions} />
    </>
  );
}

export default CustomerPerformance;
