import { createIcon } from '@chakra-ui/react';

export const GreenArrowIcon = createIcon({
  displayName: 'GreenArrowIcon',
  viewBox: '0 0 31 17',

  path: (
    <>
      <path
        d="M19.6442 13.4891V3.18136L23.1118 3.18136V-3.05176e-05L30.3335 8.33506L23.1118 16.6702V13.4888L19.6442 13.4891Z"
        fill="#E0E8F0"
      />
      <path
        d="M17.3853 13.4891L17.3853 3.18136L7.5551 3.18136V-3.05176e-05L0.333447 8.33506L7.5551 16.6702L7.5551 13.4888L17.3853 13.4891Z"
        fill="#10DC49"
      />
    </>
  ),
});
