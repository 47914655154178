import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  createUserAndCustomerApi,
  createUserForExistingCustomerApi,
} from 'api/adminApi';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import { ERROR_API_CODE } from 'constants/apiErrorsConstatns';
import { hasObjectAllValues, isObjectEmpty } from 'helpers/objectHelper';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

function AddUserModal({
  show,
  header,
  selectedCustomerId,
  onSubmitSuccess,
  onModalClose,
  companyNames,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  useEffect(() => {
    if (show) {
      onOpen();
    }
  }, [show]);

  useEffect(() => {
    let defaultValues = {
      email: '',
    };

    if (!selectedCustomerId) {
      defaultValues = {
        ...defaultValues,
        companyName: '',
      };
    }
    reset(defaultValues);
  }, [selectedCustomerId]);

  const isFormFilled = () => {
    return !isObjectEmpty(formValues) && hasObjectAllValues(formValues);
  };

  const close = () => {
    onClose();
    onModalClose();
  };

  const onSubmit = (data) => {
    const apiFunc = selectedCustomerId
      ? () =>
          createUserForExistingCustomerApi({
            customerId: selectedCustomerId,
            email: data.email,
          })
      : () =>
          createUserAndCustomerApi({
            email: data.email,
            companyName: data.companyName,
          });

    setLoading(true);
    apiFunc()
      .then((data) => {
        onSubmitSuccess({customerId: data?.customerId});
        close();
      })
      .catch((error) => {
        if (
          error.response?.data?.errorCode === ERROR_API_CODE.USER_ALREADY_EXISTS
        ) {
          setError('email', {
            type: 'manual',
            message: 'Email already exists',
          });
        } else {
          toast({
            title: 'Error',
            description: 'Error creating user',
            status: 'error',
          });
        }
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <OverlaySpinnerWrapper show={loading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={errors.email || errors.companyName}>
                <Stack spacing={5}>
                  <Box>
                    <FormLabel>Email</FormLabel>
                    <Input
                      {...register('email', {
                        required: true,
                        validate: (value) => {
                          return validator.isEmail(value) || 'Email is not valid';
                        },
                      })}
                      type={'email'}
                    />
                    {errors.email?.message && (
                      <FormErrorMessage>
                        {errors.email.message}
                      </FormErrorMessage>
                    )}
                  </Box>
                  {!selectedCustomerId && (
                    <Box>
                      <FormLabel>Company Name</FormLabel>
                      <Input
                        {...register('companyName', {
                          required: true,
                          validate: (value) => {
                            return (
                              !companyNames.includes(value) ||
                              'Company name already exists'
                            );
                          },
                        })}
                      />
                      {errors.companyName?.message && (
                        <FormErrorMessage>
                          {errors.companyName.message}
                        </FormErrorMessage>
                      )}
                    </Box>
                  )}
                </Stack>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                variant={'brand'}
                type={'submit'}
                isDisabled={!isFormFilled()}
              >
                Create
              </Button>
            </ModalFooter>
          </form>
        </OverlaySpinnerWrapper>
      </ModalContent>
    </Modal>
  );
}

AddUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  selectedCustomerId: PropTypes.string,
  companyNames: PropTypes.arrayOf(PropTypes.string),
  onSubmitSuccess: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default AddUserModal;
