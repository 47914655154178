import PropTypes from 'prop-types';
import {
  Box,
  TableContainer,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Progress,
} from '@chakra-ui/react';
import ContentBox from '../../components/ContentBox';
import Moment from 'react-moment';

function FilesSummary({ files, progressInfos, loading }) {
  const isEmptyState = files.length === 0 && progressInfos.length === 0;

  return (
    <ContentBox
      title={'Twelve connect files'}
      subTitle={isEmptyState ? 'No files uploaded yet' : ''}
      w={'full'}
      minH={446}
      loading={loading}
    >
      {!isEmptyState && (
        <TableContainer maxW={'none'} mx={-6} mt={6}>
          <Table>
            <Thead bg={'gray.100'}>
              <Tr>
                <Th fontSize={'md'} color={'gray.main'}>
                  File name
                </Th>
                <Th w={25} fontSize={'md'} color={'gray.main'}>
                  Upload date
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {progressInfos.map((file, index) => {
                return (
                  <Row
                    index={index}
                    leftCell={file.name}
                    rightCell={
                      file.done ? (
                        <Moment format="L">{file.lastModified}</Moment>
                      ) : (
                        <Progress hasStripe={true} value={file.percentage} />
                      )
                    }
                  />
                );
              })}
              {files.map((file, index) => {
                return(
                  <Row
                    index={index}
                    leftCell={file.name}
                    rightCell={<Moment format="l">{file.lastModified}</Moment>}
                  />
                );
                // return (
                //   <Tr key={`file_${index}`} color={'gray.main'}>
                //     <Td py={7}>{file.name}</Td>
                //     <Td>
                //       <Moment format="l">{file.lastModified}</Moment>
                //     </Td>
                //   </Tr>
                // );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </ContentBox>
  );
}

function Row({ index, leftCell, rightCell }) {
  return (
    <Tr key={`file_${index}`} color={'gray.main'}>
      <Td py={7}>{leftCell}</Td>
      <Td>{rightCell}</Td>
    </Tr>
  );
}

FilesSummary.propTypes = {
  files: PropTypes.array,
  progressInfos: PropTypes.array,
  loading: PropTypes.bool,
};

export default FilesSummary;
