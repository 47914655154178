import { get as _get } from 'lodash';

import { currencyFormat, numberFormat } from 'components/Number';
import {
  MONEY_TYPE,
  SUBSCRIPTION_TYPE,
  TIME_PERIOD,
  TOTAL_TYPE,
  TREND_TYPE,
} from 'constants/salesConstants';
import moment from 'moment';

const refundPropMap = {
  true: 'with_refunds',
  false: 'without_refunds',
};

const trendTypePropMap = {
  [TREND_TYPE.MOM]: 'month_over_month',
  [TREND_TYPE.SOM]: 'start_over_month',
};

const moneyTypePropMap = {
  [`${SUBSCRIPTION_TYPE.ALL}_${MONEY_TYPE.REVENUE}`]: 'total_spread',
  [`${SUBSCRIPTION_TYPE.ALL}_${MONEY_TYPE.CASHFLOW}`]: 'total',
  [`${SUBSCRIPTION_TYPE.MONTHLY}_${MONEY_TYPE.REVENUE}`]: 'mrr',
  [`${SUBSCRIPTION_TYPE.MONTHLY}_${MONEY_TYPE.CASHFLOW}`]: 'mrr',
  [`${SUBSCRIPTION_TYPE.ANNUALLY}_${MONEY_TYPE.REVENUE}`]: 'arr_spread',
  [`${SUBSCRIPTION_TYPE.ANNUALLY}_${MONEY_TYPE.CASHFLOW}`]: 'arr',
};

const totalTypePropMap = {
  [TOTAL_TYPE.GROSS]: 'gross',
  [TOTAL_TYPE.NET]: 'net',
};

const VECTOR_TYPE = {
  AVG: 'avg',
  AVG_3_MONTHS: 'avg3months',
  AVG_6_MONTHS: 'avg6months',
  RUNOFF: 'runoff',
  RUNWAY: 'runway',
};

const VECTOR_TYPE_META = {
  [VECTOR_TYPE.AVG]: {
    label: 'Average',
    prop: 'avg.base',
  },
  [VECTOR_TYPE.AVG_3_MONTHS]: {
    label: 'Average 3 Months',
    prop: 'avg.three_months',
  },
  [VECTOR_TYPE.AVG_6_MONTHS]: {
    label: 'Average 6 Months',
    prop: 'avg.six_months',
  },
  [VECTOR_TYPE.RUNOFF]: {
    label: 'Runoff',
    prop: 'runoff_prospective',
  },
  [VECTOR_TYPE.RUNWAY]: {
    label: 'Runway',
    prop: 'runway_prospective',
  },
};

export const transformCohorts = (cohortsData, filters) => {
  const refundProp = refundPropMap[filters.withRefunds];

  const getMoneyTypeProp = () => {
    return moneyTypePropMap[`${filters.subscriptionType}_${filters.moneyType}`];
  };

  const totalTypeProp = totalTypePropMap[filters.totalType];

  const totalMoneyTypeProp = `${totalTypeProp}_${getMoneyTypeProp()}`;

  const isSettingsPredicted = filters.withPrediction;

  const dataSourceProp = isSettingsPredicted ? 'predicted' : 'actual';

  const dataConfig = [
    {
      key: 'arpu',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'arpu',
          vectorProp,
        });
      },
    },
    {
      key: 'arppu',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'arppu',
          vectorProp,
        });
      },
    },
    {
      key: 'revenue',
      vectors: isSettingsPredicted
        ? [VECTOR_TYPE.RUNOFF, VECTOR_TYPE.RUNWAY]
        : null,
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'revenue',
          vectorProp,
        });
      },
      getTotalFunc: ({ row }) => {
        return (
          row.metrics.revenue.total[dataSourceProp][totalMoneyTypeProp][
            refundProp
          ] || 0
        );
      },
    },
    {
      key: 'revenueRetention',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'revenue',
          isRetention: true,
          vectorProp,
        });
      },
    },
    {
      key: 'users',
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'user_retention',
          vectorProp,
        });
      },
    },
    {
      key: 'userRetention',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'user_retention',
          isRetention: true,
          vectorProp,
        });
      },
    },
    {
      key: 'cumulativeByArpu',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'cumulative',
          isByArpu: true,
          vectorProp,
        });
      },
    },
    {
      key: 'cumulativeByArppu',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'cumulative',
          isByArpu: false,
          vectorProp,
        });
      },
    },
    {
      key: 'roasByArpu',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'roas',
          isByArpu: true,
          vectorProp,
        });
      },
    },
    {
      key: 'roasByArppu',
      vectors: [
        VECTOR_TYPE.AVG,
        VECTOR_TYPE.AVG_3_MONTHS,
        VECTOR_TYPE.AVG_6_MONTHS,
      ],
      getValueFunc: ({ cell, vectorProp }) => {
        return getValueFromCell({
          cell,
          cohortType: 'roas',
          isByArpu: false,
          vectorProp,
        });
      },
    },
  ];

  const getValueFromCell = ({
    cell,
    cohortType,
    isRetention,
    isByArpu,
    vectorProp,
  }) => {
    let rootCell = cell[cohortType];

    if (isByArpu !== undefined) {
      const arpuProp = isByArpu ? 'arpu' : 'arppu';
      rootCell = rootCell[arpuProp];
    }

    if (vectorProp) {
      rootCell = _get(rootCell, vectorProp);
    }

    let retentionTypeProp = 'base';
    if (isRetention) {
      retentionTypeProp = trendTypePropMap[filters.trendType];
    }

    if (cohortType === 'user_retention') {
      const usersProp = `${getMoneyTypeProp()}_users`;
      return rootCell[retentionTypeProp][usersProp][refundProp] || 0;
    }

    let moneyTypeProp = `${
      totalTypePropMap[filters.totalType]
    }_${getMoneyTypeProp()}`;

    return rootCell[retentionTypeProp][moneyTypeProp][refundProp] || 0;
  };

  const getCohorts = (rows) => {
    return rows.map((row, index) => {
      const { cohort_date, cells, metrics } = row;

      const cac = row.cac || 0;

      let totalUaProp = `${getMoneyTypeProp()}_ua_spend`;
      const totalUa = row[totalUaProp]
        ? Math.round(row[totalUaProp][refundPropMap[filters.withRefunds]]) || 0
        : 0;

      let prefixColumns = [];
      const date = moment({
        year: cohort_date.year,
        month: cohort_date.month - 1,
        day: cohort_date.day,
      });
      const dateStr = date.format(
        filters.timePeriod === TIME_PERIOD.MONTHLY ? 'MMMM YYYY' : 'L'
      );
      //date
      prefixColumns.push(dateStr);

      //CAC
      prefixColumns.push(currencyFormat(cac, { maximumFractionDigits: 1 }));

      //users
      let usersProp = `first_period_${getMoneyTypeProp()}_users`;
      prefixColumns.push(
        numberFormat(row[usersProp][refundPropMap[filters.withRefunds]] || 0)
      );

      //total ua
      prefixColumns.push(currencyFormat(totalUa));

      let reducedObj = dataConfig.reduce((obj, config) => {
        let filteredCells = cells;

        if (!obj.data) {
          obj.data = {};
        }

        obj.data[config.key] = filteredCells.map((cell) => {
          return {
            value: config.getValueFunc({ cell: cell.metrics }),
            isPredicted: cell.isPredicted,
          };
        });

        if (config.getTotalFunc) {
          if (!obj.postfixColumns) {
            obj.postfixColumns = {};
          }

          obj.postfixColumns[config.key] = [];
          obj.postfixColumns[config.key].push(
            currencyFormat(config.getTotalFunc({ row }))
          );
        }

        return obj;
      }, {});

      let { data, postfixColumns } = reducedObj;

      data.cac = cac;
      data.totalUa = totalUa;

      return {
        dateStr,
        date,
        prefixColumns,
        postfixColumns,
        data,
        metrics,
      };
    });
  };

  const getVectors = (vectors) => {
    if (!vectors) {
      return [];
    }

    const result = {};

    dataConfig.forEach((config) => {
      if (!config.vectors) {
        return;
      }

      result[config.key] = config.vectors.map((vectorType) => {
        return {
          label: VECTOR_TYPE_META[vectorType].label,
          data: vectors.map((item) => {
            return config.getValueFunc({
              cell: item,
              vectorProp: VECTOR_TYPE_META[vectorType].prop,
            });
          }),
        };
      });
    });

    return result;
  };

  const getHeader = (rows) => {
    const createHeader = (rows, config) => {
      let header = [];
      header.push('Date');
      header.push('CAC');
      header.push('Users');
      header.push('Total UA');

      const cohortLabels =
        filters.timePeriod === TIME_PERIOD.MONTHLY
          ? rows[0].cells.map((cell) => cell.period || 0)
          : rows[0].cells.map((cell) => {
              const day = ((cell.period || 0) + 1) * 7;
              return `D${day}`;
            });

      let postfixLabels = [];
      if (config.getTotalFunc) {
        postfixLabels.push('Total');
      }

      return [...header, ...cohortLabels, ...postfixLabels];
    };

    let cohortHeader = dataConfig.reduce((obj, config) => {
      obj[config.key] = createHeader(rows, config);

      return obj;
    }, {});

    return cohortHeader;
  };

  const { rows, vectors, kpis } = cohortsData;

  return {
    header: rows && getHeader(rows),
    rows: rows && getCohorts(rows),
    vectors: vectors && getVectors(vectors),
    kpis: kpis,
  };
};

export const transformCohortsHistogramData = (data, filters) => {
  const getMoneyTypeProp = () => {
    return moneyTypePropMap[`${filters.subscriptionType}_${filters.moneyType}`];
  };

  const refundProp = refundPropMap[filters.withRefunds];

  const getValue = ({ cell }) => {
    let moneyTypeProp = `${
      totalTypePropMap[filters.totalType]
    }_${getMoneyTypeProp()}`;

    return cell[moneyTypeProp][refundProp] || 0;
  };

  const histogram = data.periods.map((row, index) => {
    return {
      period: row.period || 0,
      actualOverPercentTarget: getValue({
        cell: row.actual.over_percent_target,
      }),
      actualUnderPercentTarget: getValue({
        cell: row.actual.under_percent_target,
      }),
      predictedOverPercentTarget: getValue({
        cell: row.predicted.over_percent_target,
      }),
      predictedUnderPercentTarget: getValue({
        cell: row.predicted.under_percent_target,
      }),
    };
  });

  const { cohorts } = data;

  const cohortsSummary = {
    total: cohorts.total_cohorts,
    totalOver: getValue({ cell: cohorts.cohorts_number.over_percent_target }),
    totalUnder: getValue({ cell: cohorts.cohorts_number.under_percent_target }),
    avgOver: getValue({ cell: cohorts.avg_months.over_percent_target }),
    avgUnder: getValue({ cell: cohorts.avg_months.under_percent_target }),
    stdDevOver: getValue({
      cell: cohorts.std_deviation.over_percent_target,
    }),
    stdDevUnder: getValue({
      cell: cohorts.std_deviation.under_percent_target,
    }),
  };

  return { histogram, cohortsSummary };
};
