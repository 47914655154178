import { sendGAEvent, sendGACustomEvent } from '.';

export const sendOnBoardingStartedGAEvent = () => {
  sendGACustomEvent('onboarding_started');
};

export const sendOnBoardingCompletedGAEvent = (companyName) => {
  sendGACustomEvent('onboarding_completed', {
    company_name: companyName,
  });
};
