import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const filledVaraint = defineStyle({
  background: 'gray.50',
  _hover: {
    background: 'gray.50',
  },
  _focus: {
    background: 'gray.50',
  },
})

export const TextareaTheme = defineStyleConfig({
  baseStyle: {
    bg: 'gray.50',
  },
  variants: {
    filled: filledVaraint,
  },
  defaultProps: {
    variant: 'filled',
  },
});


