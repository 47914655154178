import { defineStyleConfig } from '@chakra-ui/react';

export const InputTheme = defineStyleConfig({
  baseStyle: {
    field: {
      bg: 'gray.50',
    },
    addon: {
      bg: 'gray.100'
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
        h: '10',
      },
    },
  },
  variants: {},
  defaultProps: {
    variant: null,
  },
});
