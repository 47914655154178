export const DEAL_TYPE = {
  FIXED: 'fixed',
  ROAS: 'roas',
  //   RBF: 'rbf',
};

export const DEAL_TYPE_META = {
  [DEAL_TYPE.FIXED]: {
    label: 'Fixed',
  },
  [DEAL_TYPE.ROAS]: {
    label: 'ROAS',
  },
  // [DEAL_TYPE.RBF]: {
  //   label: 'RBF',
  // },
};

export const REPAYMENT_FREQUENCY_TYPE = {
  WEEKLY: 'weekly',
  BI_MONTHLY: 'biMonthly',
  MONTHLY: 'monthly',
};

export const REPAYMENT_FREQUENCY_TYPE_META = {
  [REPAYMENT_FREQUENCY_TYPE.WEEKLY]: {
    label: 'Weekly',
  },
  [REPAYMENT_FREQUENCY_TYPE.BI_MONTHLY]: {
    label: 'Bi-Monthly',
  },
  [REPAYMENT_FREQUENCY_TYPE.MONTHLY]: {
    label: 'Monthly',
  },
};

export const REVENUE_RECOGNITION_TYPE = {
  INTEREST_FIRST: 'interestFirst',
  PRINCIPAL_FIRST: 'principalFirst',
  PRORATE: 'prorate',
};

export const REVENUE_RECOGNITION_TYPE_META = {
  [REVENUE_RECOGNITION_TYPE.INTEREST_FIRST]: {
    label: 'Fee First',
  },
  [REVENUE_RECOGNITION_TYPE.PRINCIPAL_FIRST]: {
    label: 'Principal First',
  },
  [REVENUE_RECOGNITION_TYPE.PRORATE]: {
    label: 'Prorate',
  },
};

export const CUSTOMER_SEGMENT_TYPE = {
  MOBILE_APP: 'Mobile App',
  DTC: 'DTC',
  GAMING: 'Gaming',
  LOW_TOUCH_SAAS: 'Low-touch SaaS',
  I_GAMING: 'iGaming',
};

export const CUSTOMER_SEGMENT_TYPE_META = {
  [CUSTOMER_SEGMENT_TYPE.MOBILE_APP]: {
    label: 'Mobile App',
  },
  [CUSTOMER_SEGMENT_TYPE.DTC]: {
    label: 'DTC',
  },
  [CUSTOMER_SEGMENT_TYPE.GAMING]: {
    label: 'Gaming',
  },
  [CUSTOMER_SEGMENT_TYPE.LOW_TOUCH_SAAS]: {
    label: 'Low-touch SaaS',
  },
  [CUSTOMER_SEGMENT_TYPE.I_GAMING]: {
    label: 'iGaming',
  },
};

export const TOTAL_TYPE = {
  GROSS: 'gross',
  NET: 'net',
};

export const TOTAL_TYPE_META = {
  [TOTAL_TYPE.GROSS]: {
    label: 'Gross',
  },
  [TOTAL_TYPE.NET]: {
    label: 'Net',
  },
};
