export const spinnerStyles = {
  components: {
    Spinner: {
      baseStyle: {
        color: 'brand.500',
        borderLeftColor: 'white',
        borderWidth: 3,
      },
      defaultProps: {
        size: 'xl',
        // borderWidth: 10,
      },
    },
  },
};
