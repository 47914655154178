import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const noPaddingVariant = definePartsStyle((props) => {
  return {
    td: {
      p: 0,
      verticalAlign: 'top',
    },
    th: {
      p: 0,
    },
  };
});

export const tableStyles = {
  components: {
    Table: {
      variants: {
        noPadding: noPaddingVariant,
      },
    },
  },
};
