import { defineStyleConfig } from '@chakra-ui/react';

export const LinkTheme = defineStyleConfig({
    baseStyle: {
      color: 'primary.link',
    },
    variants: {
      tableActionIcon: {
        color: 'black.main',
        boxSize: 5,
      },
    }
  });