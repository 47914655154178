import { Tag } from '@chakra-ui/react';
import { INSTALLMENT_STATUS_META } from 'constants/installmentConstants';
import PropTypes from 'prop-types';

const InstallmentStatusTag = ({status}) => {
  return (
    <Tag
      fontSize={'inherit'}
      px={8}
      py={2}
      bg={INSTALLMENT_STATUS_META[status].color}
      whiteSpace={'nowrap'}
      size={'md'}
    >
      {INSTALLMENT_STATUS_META[status].label}
    </Tag>
  );
};

InstallmentStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default InstallmentStatusTag;
