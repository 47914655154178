export const radioStyles = {
  components: {
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            borderColor: 'brand.300',
            bg: 'transparent',
            _before: {
              bg: 'brand.300',
            },
            _hover: {
              bg: 'transparent',
            },
          },
        },
      },
    },
  },
};
