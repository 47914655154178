import axios from 'axios';

export const getLoggedInCustomer = async () => {
  const overrideCustomerId = sessionStorage.getItem('override_customer_id');

  const headers = overrideCustomerId
    ? { 'x-override-customer-id': overrideCustomerId }
    : {};

  const response = await axios.get(`/customer`, {
    headers,
  });
  return response.data;
};

export const getCustomerApi = async ({customerId}) => {
  const response = await axios.get(`/customer/${customerId}`);
  return response.data;
};

export const loginCustomerApi = async () => {
  const response = await axios.post(`/login-customer`);
  return response.data;
};

//updateCustomer
export const completeCustomerOnboardingApi = async ({customerId, name, companyName, approvedTerms}) => {
  const response = await axios.put(`/customer/${customerId}/onboarding`, {name, companyName, approvedTerms});
  return response.data;
};

export const getCustomerPerformanceApi = async (customerId) => {
  const response = await axios.get(`/customer/${customerId}/performance`);
  return response.data;
};
