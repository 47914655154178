import ReactGA from 'react-ga4';

let _isIntialized = false;
let _customerId;
let _companyName;

export const initGA = () => {
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  if (measurementId && !_isIntialized) {
    _isIntialized = true;
    ReactGA.initialize(measurementId);
  }
};

export const setGACustomer = (customerId, companyName) => {
  if (!_isIntialized) {
    return;
  }

  ReactGA.set({ user_id: customerId });
  _customerId = customerId;
  _companyName = companyName;
};

export const sendGACustomEvent = (eventName, options) => {
  options = options || {};
  options.customer_id = _customerId;
  options.company_name = _companyName;
  ReactGA.event(eventName, options);
};
