import { Icon } from '@chakra-ui/react';

export default function GoogleAdsIcon(props) {
  return (
    <Icon viewBox="0 0 48 48" {...props}>
      <polygon
        fill="#ffc107"
        points="30.129,15.75 18.871,9.25 5.871,31.25 17.129,37.75"
      />
      <path
        fill="#1e88e5"
        d="M31.871,37.75c1.795,3.109,5.847,4.144,8.879,2.379c3.103-1.806,4.174-5.77,2.379-8.879l-13-22 c-1.795-3.109-5.835-4.144-8.879-2.379c-3.106,1.801-4.174,5.77-2.379,8.879L31.871,37.75z"
      />
      <circle cx="11.5" cy="34.5" r="6.5" fill="#43a047" />
    </Icon>
  );
}
