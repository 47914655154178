const locale = 'en-US';

export function Currency({ value, maximumFractionDigits }) {
  if (isNaN(value) || value === null) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits || 0,
    minimumFractionDigits: 0,
  }).format(value);
}

export function Percentage({ value, maximumFractionDigits }) {
  if (isNaN(value) || value === null) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: isNaN(maximumFractionDigits) ? 3 : maximumFractionDigits,
  }).format(value);
}

export function Number({ value, maximumFractionDigits, isAccountingFormat, isCompact }) {
  if (isNaN(value) || value === null) {
    return null;
  }

  maximumFractionDigits =
    maximumFractionDigits === undefined ? 2 : maximumFractionDigits;

  const output = new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
    notation: isCompact ? 'compact' : 'standard',
  }).format(value);

  if (isAccountingFormat && value < 0) {
    return `(${output.replace('-', '')})`;
  }

  return output;
}

export function numberFormat(value, { maximumFractionDigits, isAccountingFormat, isCompact } = {}) {
  if (isNaN(value) || value === null) {
    return null;
  }

  maximumFractionDigits =
    maximumFractionDigits === undefined ? 0 : maximumFractionDigits;
  const output = new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
    notation: isCompact ? 'compact' : 'standard',
  }).format(value);

  if (isAccountingFormat && value < 0) {
    return `(${output.replace('-', '')})`;
  }

  return output;
}

export function currencyFormat(
  value,
  { maximumFractionDigits, isCompact } = {}
) {
  if (isNaN(value) || value === null) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits || 0,
    minimumFractionDigits: 0,
    notation: isCompact ? 'compact' : 'standard',
  }).format(value);
}

export function percentageFormat(value, { maximumFractionDigits } = {}) {
  if (isNaN(value) || value === null) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: isNaN(maximumFractionDigits) ? 3 : maximumFractionDigits,
  }).format(value);
}
