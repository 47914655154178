import {
  Box,
  Heading,
  Table,
  TableContainer,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { getBankingProfitLossApi } from 'api/bankingApi';
import { numberFormat, percentageFormat } from 'components/Number';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import moment from 'moment';
import DateInputHeader from 'pages/Cockpit/Customer/Performance/DateInputHeader';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';

const SECTION_TYPE = {
  REVENUE: 'revenue',
  COST_OF_GOODS_SOLD: 'cost_of_goods_sold',
  OPERATIONAL_EXPENSES: 'operational_expenses',
  TAX_EXPENSES: 'tax_expenses',
  INTRA_COMPANY: 'intra_company',
  CASH_FROM_FINANCING_ACTIVITIES: 'cash_from_financing_activities',
  OTHER_INCOME: 'other_income',
  SPECIAL_ITEMS: 'special_items',
};

const SECTION_SORT_ORDER = [
  SECTION_TYPE.REVENUE,
  SECTION_TYPE.COST_OF_GOODS_SOLD,
  SECTION_TYPE.OPERATIONAL_EXPENSES,
  SECTION_TYPE.TAX_EXPENSES,
  SECTION_TYPE.INTRA_COMPANY,
  SECTION_TYPE.CASH_FROM_FINANCING_ACTIVITIES,
  SECTION_TYPE.OTHER_INCOME,
  SECTION_TYPE.SPECIAL_ITEMS,
];

const BankingProfitLoss = (props) => {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [data, setData] = useState(null);
  const { customerId } = useParams();
  const toast = useToast();

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  useEffect(() => {
    getProfitLossBanking();
  }, [date]);

  const getProfitLossBanking = async () => {
    setLoading(true);
    try {
      const _data = await getBankingProfitLossApi({
        customerId,
        date,
      });
      setData(_data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch banking profit loss data',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const TableHeader = () => {
    const dates = data?.dates;

    return (
      <Thead>
        <Tr>
          <Th borderColor={borderColor}></Th>
          {dates.map((date, index) => (
            <Th
              key={index}
              color="gray.400"
              fontSize={{ sm: '12px', lg: '14px' }}
              borderColor={borderColor}
            >
              <Moment format="MMM YYYY">{date}</Moment>
            </Th>
          ))}
        </Tr>
      </Thead>
    );
  };

  const TableBody = () => {
    const sections = data?.sections;
    const dates = data?.dates;
    if (!sections) {
      return null;
    }

    const colSpan = dates.length + 1;

    let rows = [];

    for (const sectionType of SECTION_SORT_ORDER) {
      const section = sections.find((section) => section.slug === sectionType);
      if (!section) {
        continue;
      }

      rows.push(
        <SectionRows key={sectionType} section={section} colSpan={colSpan} />
      );
    }

    return <>{rows}</>;
  };

  const SectionRows = ({ section, colSpan }) => {
    const metrics = section.metrics;
    metrics.sort((a, b) => (a.indent || 0) - (b.indent || 0));

    const getValue = (metric, value) => {
      if (isNaN(value)) {
        return '-';
      }

      let formattedValue = numberFormat(value);

      if (metric?.is_ratio) {
        return percentageFormat(value, { maximumFractionDigits: 1 });
      }

      let valueColor = '';
      if (!metric?.indent) {
        valueColor = value < 0 ? 'red.500' : 'green.500';
      }

      return <Text color={valueColor}>{formattedValue}</Text>;
    };

    const sectionRow = (
      <Tr>
        <Td colSpan={colSpan} bg={'horizonBlue.200'} fontWeight={800}>
          <Text ml={'-10px'}>{section.name}</Text>
        </Td>
      </Tr>
    );

    const metricRows = metrics.map((metric, index) => {
      const indent = metric.indent || 0;
      return (
        <Tr
          key={index}
          bg={indent > 0 ? 'horizonBlue.50' : ''}
          fontWeight={indent === 1 ? 800 : ''}
        >
          <Td borderColor={borderColor}>{metric.name}</Td>
          {metric.values.map((v, index) => (
            <Td key={index} borderColor={borderColor}>
              {getValue(metric, v?.value)}
            </Td>
          ))}
        </Tr>
      );
    });

    return (
      <>
        {sectionRow}
        {metricRows}
      </>
    );
  };

  return (
    <OverlaySpinnerWrapper show={loading} isFixed={true}>
      <Box minW={'container.lg'} maxW={'max'}>
        <DateInputHeader date={date} onDateChange={setDate} />
        {data?.sections && (
          <Card mt={10}>
            <Heading variant={'sectionHeader'} borderColor={'twelve.green.300'}>
              Banking P&L
            </Heading>
            <Box mx={-5} mt={10}>
              <TableContainer>
                <Table variant={'simple'} borderColor={borderColor}>
                  <TableHeader />
                  <TableBody />
                </Table>
              </TableContainer>
            </Box>
          </Card>
        )}
      </Box>
    </OverlaySpinnerWrapper>
  );
};

BankingProfitLoss.propTypes = {};

export default BankingProfitLoss;
