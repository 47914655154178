import { Icon } from '@chakra-ui/react';

export default function AmazonSellingPartnerIcon(props) {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,60%,0%)',
            fillOpacity: 1,
          }}
          d="M 6 0 L 34 0 C 37.3125 0 40 2.6875 40 6 L 40 34 C 40 37.3125 37.3125 40 34 40 L 6 40 C 2.6875 40 0 37.3125 0 34 L 0 6 C 0 2.6875 2.6875 0 6 0 Z M 6 0 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 22.109375 14.609375 C 17.265625 14.765625 12.65625 16.09375 12.65625 20.9375 C 12.65625 24.296875 14.6875 25.9375 17.421875 25.9375 C 19.84375 25.9375 21.40625 25 22.734375 23.59375 C 23.359375 24.453125 23.515625 24.84375 24.609375 25.703125 C 24.84375 25.859375 25.234375 25.859375 25.390625 25.625 L 27.8125 23.515625 C 28.125 23.28125 27.96875 22.890625 27.8125 22.734375 C 27.265625 21.875 26.640625 21.25 26.640625 19.6875 L 26.640625 14.6875 C 26.640625 12.578125 26.796875 10.625 25.234375 9.21875 C 23.90625 7.96875 22.265625 7.65625 20.390625 7.578125 C 16.25 7.5 13.515625 9.765625 13.125 12.421875 C 13.046875 12.890625 13.4375 13.125 13.671875 13.125 L 16.5625 13.515625 C 17.03125 13.59375 17.265625 13.203125 17.34375 12.890625 C 17.8125 11.171875 19.609375 10.703125 20.703125 11.09375 C 22.265625 11.5625 22.109375 13.359375 22.109375 14.609375 Z M 19.296875 22.8125 C 18.125 22.8125 17.34375 21.796875 17.34375 20.46875 C 17.421875 17.65625 19.609375 17.1875 22.109375 17.1875 L 22.109375 18.59375 C 22.109375 21.09375 20.78125 22.8125 19.296875 22.8125 Z M 32.421875 31.09375 C 33.4375 30.234375 34.453125 28.125 34.375 26.640625 C 34.375 26.09375 34.296875 26.015625 33.75 25.859375 C 32.734375 25.546875 30.15625 25.46875 28.90625 26.640625 C 28.671875 26.875 28.75 27.03125 28.984375 27.03125 C 29.84375 26.875 32.5 26.5625 32.890625 27.1875 C 33.203125 27.734375 32.265625 29.921875 31.953125 30.859375 C 31.796875 31.25 32.109375 31.328125 32.421875 31.09375 Z M 4.53125 26.71875 C 12.03125 33.828125 23.828125 34.0625 31.484375 28.671875 C 32.03125 28.359375 31.484375 27.734375 31.015625 27.96875 C 22.519531 31.5625 12.824219 30.953125 4.84375 26.328125 C 4.53125 26.09375 4.21875 26.484375 4.53125 26.71875 Z M 4.53125 26.71875 "
        />
      </g>
    </Icon>
  );
}
