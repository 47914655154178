import {
  AbsoluteCenter,
  Heading,
  Spinner
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { getCustomerPerformanceApi } from '../api/customerApi';
import ContentBox from '../components/ContentBox';
import CustomerPerformance from '../components/CustomerPerformance';
import { CustomerContext } from '../context/customerContext';

function Home() {
  const { customer } = useContext(CustomerContext);
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (customer) {
      getCustomerPerformanceApi(customer?.id)
        .then((_performanceData) => {
          setPerformanceData(_performanceData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  if (!performanceData) {
    return (
      <AbsoluteCenter>
        <ContentBox>
          <Heading as="h1" size="l" textAlign={'center'}>
            No Data To Show
          </Heading>
        </ContentBox>
      </AbsoluteCenter>
    );
  }

  return <CustomerPerformance data={performanceData} />;
}

export default Home;
