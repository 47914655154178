import AdjustIcon from 'components/icons/connectors/AdjustIcon';
import AmazonAdsIcon from 'components/icons/connectors/AmazonAdsIcon';
import BranchIcon from 'components/icons/connectors/BranchIcon';
import GoogleAdsIcon from 'components/icons/connectors/GoogleAdsIcon';
import IronSourceIcon from 'components/icons/connectors/IronSourceIcon';
import MixpanelIcon from 'components/icons/connectors/MixpanelIcon';
import NetSuiteIcon from 'components/icons/connectors/NetSuiteIcon';
import PaypalIcon from 'components/icons/connectors/PaypalIcon';
import TikTokIcon from 'components/icons/connectors/TikTokIcon';
import { BsBank2 } from 'react-icons/bs';
import AmazonSellingPartnerIcon from '../components/icons/connectors/AmazonSellingPartnerIcon';
import AppStoreIcon from '../components/icons/connectors/AppStoreIcon';
import AppsflyerIcon from '../components/icons/connectors/AppsflyerIcon';
import FacebookIcon from '../components/icons/connectors/FacebookIcon';
import GooglePlayIcon from '../components/icons/connectors/GooglePlayIcon';
import InstagramIcon from '../components/icons/connectors/InstagramIcon';
import QuickbooksIcon from '../components/icons/connectors/QuickbooksIcon';
import ShopifyIcon from '../components/icons/connectors/ShopifyIcon';
import StripeIcon from '../components/icons/connectors/StripeIcon';
import XeroIcon from '../components/icons/connectors/XeroIcon';

export const CONNECTOR_TYPE = {
  PLAID: 'plaid',
  APPSTORE: 'appStore',
  GOOGLEPLAY: 'googlePlay',
  SHOPIFY: 'shopify',
  APPSFLYER: 'appsFlyer',
  QUICKBOOKS: 'quickbooks',
  STRIPE: 'stripe',
  XERO: 'xero',
  SHOPIFY_PARTNER: 'shopifyPartner',
  SHOPIFY_STORE: 'shopifyStore',
  FACEBOOK_ADS: 'facebookAds',
  INSTAGRAM_BUSINESS: 'instagramBusiness',
  PAYPAL: 'paypal',
  ADJUST: 'adjust',
  GOOGLEADS: 'googleAds',
  MIXPANEL: 'mixpanel',
  AMAZON_SELLING_PARTNER: 'amazonSellingPartner',
  BRANCH: 'branch',
  IRON_SOURCE: 'ironSource',
  TIKTOK_ADS: 'tiktokAds',
  AMAZON_ADS: 'amazonAds',
  APPLE_SEARCH_ADS: 'appleSearchAds',
  NET_SUITE: 'netSuite',
};

export const CONNECTOR_INTEGRATION_TYPE = {
  SHOPIFY: 'shopify',
  FIVETRAN: 'fivetran',
  PLAID: 'plaid',
};

export const CONNECTOR_STATUS = {
  INCOMPLETE: 'incomplete',
  CONNECTED: 'connected',
  BROKEN: 'broken',
};

export const CONNECTOR_STATUS_META = {
  [CONNECTOR_STATUS.INCOMPLETE]: {
    level: 'warning',
    text: 'Incomplete',
  },
  [CONNECTOR_STATUS.CONNECTED]: {
    level: 'success',
    text: 'Connected',
  },
  [CONNECTOR_STATUS.BROKEN]: {
    level: 'error',
    text: 'Broken',
  },
};

export const CONNECTOR_INTEGRATION_META = {
  [CONNECTOR_INTEGRATION_TYPE.PLAID]: {
    canRemove: true,
    hasLastSync: true,
  },
  [CONNECTOR_INTEGRATION_TYPE.FIVETRAN]: {
    hasLastSync: true,
  },
  [CONNECTOR_INTEGRATION_TYPE.SHOPIFY]: {
    hasLastSync: true,
  },
};

export const CONNECTOR_INTEGRATION_MAPPING = {
  [CONNECTOR_TYPE.PLAID]: CONNECTOR_INTEGRATION_TYPE.PLAID,
  [CONNECTOR_TYPE.APPSTORE]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.GOOGLEPLAY]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.SHOPIFY]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.APPSFLYER]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.QUICKBOOKS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.STRIPE]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.XERO]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.SHOPIFY_PARTNER]: CONNECTOR_INTEGRATION_TYPE.SHOPIFY,
  [CONNECTOR_TYPE.SHOPIFY_STORE]: CONNECTOR_INTEGRATION_TYPE.SHOPIFY,
  [CONNECTOR_TYPE.FACEBOOK_ADS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.INSTAGRAM_BUSINESS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.PAYPAL]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.ADJUST]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.GOOGLEADS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.MIXPANEL]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.AMAZON_SELLING_PARTNER]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.BRANCH]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.IRON_SOURCE]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.TIKTOK_ADS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.AMAZON_ADS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.APPLE_SEARCH_ADS]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.NET_SUITE]: CONNECTOR_INTEGRATION_TYPE.FIVETRAN,
};

export const CONNECTOR_META_DATA = {
  [CONNECTOR_TYPE.PLAID]: {
    displayName: 'Bank Account',
    icon: BsBank2,
  },
  [CONNECTOR_TYPE.APPSTORE]: {
    displayName: 'App Store',
    icon: AppStoreIcon,
  },
  [CONNECTOR_TYPE.GOOGLEPLAY]: {
    displayName: 'Google Play',
    icon: GooglePlayIcon,
  },
  [CONNECTOR_TYPE.SHOPIFY]: {
    displayName: 'Shopify',
    icon: ShopifyIcon,
  },
  [CONNECTOR_TYPE.APPSFLYER]: {
    displayName: 'AppsFlyer',
    icon: AppsflyerIcon,
  },
  [CONNECTOR_TYPE.QUICKBOOKS]: {
    displayName: 'QuickBooks',
    icon: QuickbooksIcon,
  },
  [CONNECTOR_TYPE.STRIPE]: {
    displayName: 'Stripe',
    icon: StripeIcon,
  },
  [CONNECTOR_TYPE.XERO]: {
    displayName: 'Xero',
    icon: XeroIcon,
  },
  [CONNECTOR_TYPE.SHOPIFY_PARTNER]: {
    displayName: 'Shopify Partner',
    icon: ShopifyIcon,
  },
  [CONNECTOR_TYPE.SHOPIFY_STORE]: {
    displayName: 'Shopify Store',
    icon: ShopifyIcon,
  },
  [CONNECTOR_TYPE.FACEBOOK_ADS]: {
    displayName: 'Facebook Ads',
    icon: FacebookIcon,
  },
  [CONNECTOR_TYPE.INSTAGRAM_BUSINESS]: {
    displayName: 'Instagram Business',
    icon: InstagramIcon,
  },
  [CONNECTOR_TYPE.PAYPAL]: {
    displayName: 'PayPal',
    icon: PaypalIcon,
  },
  [CONNECTOR_TYPE.ADJUST]: {
    displayName: 'Adjust',
    icon: AdjustIcon,
  },
  [CONNECTOR_TYPE.GOOGLEADS]: {
    displayName: 'Google Ads',
    icon: GoogleAdsIcon,
  },
  [CONNECTOR_TYPE.MIXPANEL]: {
    displayName: 'Mixpanel',
    icon: MixpanelIcon,
  },
  [CONNECTOR_TYPE.AMAZON_SELLING_PARTNER]: {
    displayName: 'Amazon Selling Partner',
    icon: AmazonSellingPartnerIcon,
  },
  [CONNECTOR_TYPE.BRANCH]: {
    displayName: 'Branch',
    icon: BranchIcon,
  },
  [CONNECTOR_TYPE.IRON_SOURCE]: {
    displayName: 'Iron Source',
    icon: IronSourceIcon,
  },
  [CONNECTOR_TYPE.TIKTOK_ADS]: {
    displayName: 'TikTok Ads',
    icon: TikTokIcon,
  },
  [CONNECTOR_TYPE.AMAZON_ADS]: {
    displayName: 'Amazon Ads',
    icon: AmazonAdsIcon,
  },
  [CONNECTOR_TYPE.APPLE_SEARCH_ADS]: {
    displayName: 'Apple Search Ads',
    icon: AppStoreIcon,
  },
  [CONNECTOR_TYPE.NET_SUITE]: {
    displayName: 'Net Suite',
    icon: NetSuiteIcon,
  },
};
