import { useCallback, useEffect } from 'react';
import { PlaidLinkStableEvent, usePlaidLink } from 'react-plaid-link';
import {
  createConnectorApi,
  getFivetranLinkApi,
  getPlaidLinkApi,
  getPlaidUpdateLinkApi
} from '../../api/connectorsApi';
import { writeLogApi } from '../../api/logApi';
import {
  sendConnectorPlaidStartedGAEvent
} from '../../ga/connectorsGA';
import { getDomain } from '../../helpers/urlHelper';

export const getFivetranLink = async ({
  customerId,
  connectorId,
  connectorType,
  extraParams,
  onComplete,
  onError,
}) => {
  try {
    if (!connectorId) {
      const createConnectorResponse = await createConnectorApi(
        customerId,
        connectorType,
        extraParams
      );

      connectorId = createConnectorResponse.connectorId;
    }

    const redirectUrl = getDomain() + '/connectors';

    let { link } = await getFivetranLinkApi({
      customerId,
      connectorId,
      redirectUrl,
    });

    onComplete({ link, connectorId });
  } catch (error) {
    onError(error);
  }
};

export const connectTwelve = async ({
  customerId,
  connectorType,
  extraParams,
  onComplete,
  onError,
}) => {
  try {
    await createConnectorApi(customerId, connectorType, extraParams);
    onComplete();
  } catch (error) {
    onError(error);
  }
};

export const getPlaidLinkToken = async ({
  customerId,
  itemId,
  onComplete,
  onError,
}) => {
  try {
    const { linkToken } = itemId
      ? await getPlaidUpdateLinkApi(customerId, itemId)
      : await getPlaidLinkApi(customerId);

    onComplete(linkToken);
  } catch (error) {
    onError(error);
  }
};

export const PlaidLink = ({
  customerId,
  token,
  updateItemId,
  onPlaidSuccessLoading,
  onPlaidSuccessComplete,
  onPlaidOpen,
}) => {
  const onSuccess = useCallback((publicToken, metadata) => {
    onPlaidSuccessComplete && onPlaidSuccessComplete(publicToken, metadata)
  }, []);

  const onEvent = useCallback((eventName, metadata) => {
    if (eventName === PlaidLinkStableEvent.ERROR) {
      const level =
        metadata.error_code === 'INVALID_CREDENTIALS' ? 'warn' : 'error';

      writeLogApi({
        error: 'Error in Plaid client',
        level,
        message: metadata,
        customerId,
      });
    }
  }, []);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onEvent,
  });

  useEffect(() => {
    if (ready) {
      onPlaidOpen && onPlaidOpen();
      sendConnectorPlaidStartedGAEvent();
      open();
    }
  }, [ready, open]);
};
