import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  Radio,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  downloadExternalTearsheetApi,
  getTearsheetListApi,
} from 'api/tearsheetApi';
import {
  createUnderwritingApi,
  getUnderwritingApi,
  updateUnderwritingApi,
} from 'api/underwritingApi';
import Card from 'components/card/Card';
import SelectInput from 'components/inputs/SelectInput';
import BackLink from 'components/links/BackLink';
import {
  INSTALLMENT_OWNER_TYPE,
  INSTALLMENT_OWNER_TYPE_META,
  INSTALLMENT_TYPE,
  INSTALLMENT_TYPE_META,
} from 'constants/installmentConstants';
import {
  POLICY_QUESTIONS,
  POLICY_QUESTION_TEXT,
  POLICY_SECTIONS,
} from 'constants/underwritingPolicyConstants';
import { CockpitUsersContext } from 'context/CockpitUsersContext';
import { generateSelectOptions } from 'helpers/inputHelper';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IoCheckmarkSharp } from 'react-icons/io5';
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const GridRow = ({ label, signPrefix, element }) => {
  return (
    <>
      <Text whiteSpace={'nowrap'}>{label}</Text>
      <Flex gap={'10px'} alignItems={'center'} justifyContent={'flex-end'}>
        <Text>{signPrefix}</Text>
        <Box w={200}>{element}</Box>
      </Flex>
    </>
  );
};

const UnderwritingSettings = ({
  underwriting,
  onSettingsChange,
  tearsheets,
  getUserName,
  onGenerateQuestions,
  areSettingsValid,
  isViewMode,
  customerId,
}) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const tearsheetOptions = useMemo(() => {
    if (!tearsheets) {
      return [];
    }

    return tearsheets.map((tearsheet) => ({
      label: moment(tearsheet.date).format('MM/DD/YY'),
      value: tearsheet.id,
    }));
  }, [tearsheets]);

  const tearsheet = isViewMode
    ? tearsheets?.find((t) => t.id === underwriting.tearsheetId)
    : null;

  const downloadExternalTearsheet = async (tearsheetId) => {
    setIsDownloadLoading(true);
    try {
      await downloadExternalTearsheetApi({
        customerId,
        tearsheetId,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const TearsheetElement = () => {
    if (isViewMode) {
      if (!tearsheet) {
        return <Box fontWeight={800} color={'red.500'}>Tearsheet Not Found</Box>;
      }

      return (
        <Flex alignItems={'center'} gap={5}>
          <Text fontWeight={800}>{moment(tearsheet.date).format('MM/DD/YY')}</Text>
          <Box position={'relative'}>
            <NavLink
              to={
                !tearsheet?.isExternal
                  ? `/customers/${customerId}/performance/tearsheet/${underwriting.tearsheetId}`
                  : ''
              }
              target={!tearsheet?.isExternal ? '_blank' : ''}
              onClick={() => {
                if (tearsheet?.isExternal) {
                  downloadExternalTearsheet(underwriting.tearsheetId);
                }
              }}
            >
              <Button
                borderRadius={12}
                fontSize={'sm'}
                variant={'outline'}
                position={'absolute'}
                top={'50%'}
                mt={'-19px'}
                isLoading={isDownloadLoading}
              >
                View
              </Button>
            </NavLink>
          </Box>
        </Flex>
      );
    }

    return (
      <SelectInput
        options={tearsheetOptions}
        onChange={(value) => {
          onSettingsChange('tearsheetId', value, true);
        }}
        placeholder={'Select Tearsheet'}
        value={underwriting?.tearsheetId || ''}
        fontWeight={800}
      />
    );
  };

  return (
    <SimpleGrid
      columns={2}
      spacing={'10px'}
      gridTemplateColumns={'min-content min-content'}
      alignItems={'center'}
    >
      <GridRow label={'Tearsheet'} element={<TearsheetElement />} />
      <GridRow
        label={'Tearsheet Author'}
        element={
          <Text
            ml={isViewMode ? 0 : 4}
            fontWeight={800}
            h={isViewMode ? 'auto' : 10}
            lineHeight={!isViewMode && 10}
          >
            {getUserName(
              tearsheets?.find((t) => t.id === underwriting.tearsheetId)?.userId
            )}
          </Text>
        }
      />
      <GridRow
        label={'Risk Score'}
        element={
          <Text
            ml={isViewMode ? 0 : 4}
            fontWeight={800}
            h={isViewMode ? 'auto' : 10}
            lineHeight={!isViewMode && 10}
          >
            {
              tearsheets?.find((t) => t.id === underwriting.tearsheetId)
                ?.companyRank
            }
          </Text>
        }
      />
      <GridRow
        label={'Installment'}
        element={
          isViewMode ? (
            <Text fontWeight={800}>
              {INSTALLMENT_TYPE_META[underwriting.installmentType].label}
            </Text>
          ) : (
            <SelectInput
              options={generateSelectOptions({
                metaObj: INSTALLMENT_TYPE_META,
              })}
              value={underwriting?.installmentType}
              fontWeight={800}
              onChange={(value) => {
                onSettingsChange('installmentType', value, true);
              }}
            />
          )
        }
      />
      <GridRow
        label={'Owner'}
        element={
          isViewMode ? (
            <Text fontWeight={800}>
              {INSTALLMENT_OWNER_TYPE_META[underwriting.installmentOwner].label}
            </Text>
          ) : (
            <SelectInput
              options={generateSelectOptions({
                metaObj: INSTALLMENT_OWNER_TYPE_META,
              })}
              value={underwriting?.installmentOwner}
              fontWeight={800}
              onChange={(value) => {
                onSettingsChange('installmentOwner', value, true);
              }}
            />
          )
        }
      />
      {!isViewMode && (
        <GridRow
          element={
            <Button
              w={'full'}
              fontSize={'sm'}
              mt={'20px'}
              variant={'outline'}
              onClick={onGenerateQuestions}
              isDisabled={!areSettingsValid}
            >
              GENERATE
            </Button>
          }
        />
      )}
    </SimpleGrid>
  );
};

const UnderwritingAuthorization = ({
  underwriting,
  onSettingsChange,
  isViewMode,
  getUserName,
}) => {
  return (
    <VStack spacing={'20px'} align={'left'} w={'350px'}>
      <Text fontSize={'md'}>Authorization</Text>
      <Box>
        <FormLabel fontSize={'sm'} fontWeight={400}>
          General comments
        </FormLabel>
        {isViewMode ? (
          <Text whiteSpace={'pre-line'} fontSize={'12px'}>
            {underwriting.comments}
          </Text>
        ) : (
          <Textarea
            variant={'comment'}
            h={100}
            value={underwriting.comments}
            onChange={(event) =>
              onSettingsChange('comments', event.target.value)
            }
            onBlur={(event) =>
              onSettingsChange('comments', event.target.value, true)
            }
          />
        )}
      </Box>
      {isViewMode && (
        <Box>
          <FormLabel fontSize={'sm'} fontWeight={400}>
            Authorized by
          </FormLabel>
          <Text fontWeight={800}>
            {underwriting?.signedUserIds &&
              getUserName(underwriting.signedUserIds[0])}
          </Text>
        </Box>
      )}
    </VStack>
  );
};

const UnderwritingQuestions = ({
  questions,
  complianceItems,
  onAnswerChange,
  onAnswerCommentChange,
  isViewMode,
}) => {
  const complianceItemsMap = useMemo(() => {
    if (!complianceItems) {
      return {};
    }

    return complianceItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [complianceItems]);

  if (!questions) {
    return null;
  }

  return POLICY_SECTIONS.map((section, index) => {
    const sectionQuestions = questions.filter((question) => {
      return question.section === section.id;
    });

    if (!sectionQuestions.length) {
      return null;
    }

    return (
      <Box key={index} mb={'20px'}>
        <Heading variant={'tearsheetSection'}>{section.name}</Heading>
        <Table
          variant={'noPadding'}
          letterSpacing={0}
          sx={{ borderCollapse: 'separate', borderSpacing: '20px 10px' }}
        >
          <Tbody>
            <Tr>
              <Td w={'350px'} fontWeight={800}>
                <Text ml={'-10px'}>{section.policy}</Text>
              </Td>
              <Td textAlign={'center'} w={'50px'}>
                Confirmed
              </Td>
              <Td textAlign={'center'} w={'50px'}>
                Exception
              </Td>
              <Td w={'450px'}></Td>
            </Tr>
            {sectionQuestions.map((question, index) => {
              const isConfirmed = complianceItemsMap[question.id]?.isConfirmed;
              const comment = complianceItemsMap[question.id]?.comment;
              return (
                <Tr key={index}>
                  <Td whiteSpace={'pre-line'} verticalAlign={'center'}>
                    {POLICY_QUESTION_TEXT[question.id]}
                  </Td>
                  <Td textAlign={'center'} verticalAlign={'center'}>
                    {isViewMode && isConfirmed === true && (
                      <Icon
                        as={IoCheckmarkSharp}
                        boxSize={'30px'}
                        color={'brand.300'}
                      />
                    )}
                    {!isViewMode && (
                      <Radio
                        value={true}
                        name={question.id}
                        isChecked={isConfirmed === true}
                        onChange={() => onAnswerChange(question.id, true)}
                      />
                    )}
                  </Td>
                  <Td textAlign={'center'} verticalAlign={'center'}>
                    {isViewMode && isConfirmed === false && (
                      <Icon
                        as={IoCheckmarkSharp}
                        boxSize={'30px'}
                        color={'brand.300'}
                      />
                    )}
                    {!isViewMode && (
                      <Radio
                        value={false}
                        name={question.id}
                        isChecked={isConfirmed === false}
                        onChange={() => onAnswerChange(question.id, false)}
                      />
                    )}
                  </Td>
                  <Td verticalAlign={'center'} h={'34px'}>
                    {isConfirmed === false && (
                      <Box>
                        {isViewMode ? (
                          <Text>{comment}</Text>
                        ) : (
                          <Input
                            variant={'comment'}
                            value={comment}
                            onChange={(event) =>
                              onAnswerCommentChange(
                                question.id,
                                event.target.value
                              )
                            }
                            onBlur={(event) =>
                              onAnswerCommentChange(
                                question.id,
                                event.target.value,
                                true
                              )
                            }
                          />
                        )}
                      </Box>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    );
  });
};

const Underwriting = () => {
  const { customerId, underwritingId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [tearsheets, setTearsheets] = useState(null);
  const [underwriting, setUnderwriting] = useState({
    tearsheetId: searchParams.get('tearsheetId') || null,
    installmentType: INSTALLMENT_TYPE.SUBSEQUENT,
    installmentOwner: INSTALLMENT_OWNER_TYPE.TWELVE,
  });
  const [questions, setQuestions] = useState(null);

  const toast = useToast();
  const navigate = useNavigate();

  const { getUserName } = useContext(CockpitUsersContext);

  const isNewUnderwriting = !underwritingId;

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await getTearsheets();

      if (!!underwritingId) {
        const _underwriting = await getUnderwriting();
        if (_underwriting.complianceItems?.length > 0) {
          const _questions = generateQuestions(_underwriting.installmentType);
          setQuestions(_questions);
        }
        setUnderwriting(_underwriting);
      }

      setLoading(false);
    };

    init();
  }, []);

  const areSettingsValid = useMemo(() => {
    return (
      !!underwriting.tearsheetId &&
      !!underwriting.installmentType &&
      !!underwriting.installmentOwner
    );
  }, [underwriting]);

  const areComplianceItemsValid = useMemo(() => {
    if (!questions) {
      return false;
    }

    return questions.every((question) => {
      const complianceItem = underwriting.complianceItems.find((item) => {
        return item.id === question.id;
      });

      if (complianceItem.isConfirmed === null) {
        return false;
      }

      if (complianceItem.isConfirmed === false && !complianceItem.comment) {
        return false;
      }

      return true;
    });
  }, [questions, underwriting.complianceItems]);

  const getTearsheets = async () => {
    try {
      const tearsheets = await getTearsheetListApi({ customerId });
      setTearsheets(tearsheets);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load tearsheets',
        status: 'error',
      });
      throw error;
    }
  };

  const createUnderwriting = async (data) => {
    setLoading(true);

    try {
      const response = await createUnderwritingApi({
        customerId,
        data,
      });
      return response.id;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create underwriting',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateUnderwriting = async (data) => {
    if (isNewUnderwriting) {
      return;
    }

    try {
      await updateUnderwritingApi({
        customerId,
        underwritingId,
        data,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update underwriting',
        status: 'error',
      });
      throw error;
    }
  };

  const getUnderwriting = async () => {
    try {
      const _underwriting = await getUnderwritingApi({
        customerId,
        underwritingId,
      });
      return _underwriting;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load underwriting',
        status: 'error',
      });
      throw error;
    }
  };

  const generateQuestions = (installmentType) => {
    const _questions = cloneDeep(POLICY_QUESTIONS).filter((question) => {
      let filterOwner = true;

      if (!!question.owners && question.owners.length > 0) {
        filterOwner = question.owners.includes(underwriting.installmentOwner);
      }

      return question.installments.includes(installmentType) && filterOwner;
    });

    return _questions;
  };

  const onGenerate = async () => {
    const _underwriting = cloneDeep(underwriting);

    const _questions = generateQuestions(underwriting.installmentType);

    const complianceItems = _questions.map((question) => {
      return {
        id: question.id,
        isConfirmed: null,
        comment: null,
      };
    });

    _underwriting.complianceItems = complianceItems;

    setQuestions(_questions);
    setUnderwriting(_underwriting);

    if (isNewUnderwriting) {
      const underwritingId = await createUnderwriting(_underwriting);
      navigate(
        `/customers/${customerId}/performance/underwriting/${underwritingId}`
      );
    }

    updateUnderwriting(_underwriting);
  };

  const onSettingsChange = (prop, value, doSave) => {
    const _underwriting = cloneDeep(underwriting);
    _underwriting[prop] = value;

    if (prop === 'installmentType' || prop === 'installmentOwner') {
      setQuestions(null);
      _underwriting.complianceItems = null;
    }

    setUnderwriting(_underwriting);

    if (doSave) {
      updateUnderwriting(_underwriting);
    }
  };

  const onAnswerChange = (questionId, value) => {
    const _underwriting = cloneDeep(underwriting);
    const itemIndex = _underwriting.complianceItems.findIndex((item) => {
      return item.id === questionId;
    });

    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }

    _underwriting.complianceItems[itemIndex].isConfirmed = value;
    setUnderwriting(_underwriting);
    updateUnderwriting(_underwriting);
  };

  const onAcceptAllClick = () => {
    const _underwriting = cloneDeep(underwriting);
    _underwriting.complianceItems = _underwriting.complianceItems.map(
      (item) => {
        return {
          ...item,
          isConfirmed: true,
        };
      }
    );
    setUnderwriting(_underwriting);
    updateUnderwriting(_underwriting);
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  const onAnswerCommentChange = (questionId, value, doSave) => {
    const _underwriting = cloneDeep(underwriting);
    const itemIndex = _underwriting.complianceItems.findIndex((item) => {
      return item.id === questionId;
    });

    _underwriting.complianceItems[itemIndex].comment = value;
    setUnderwriting(_underwriting);
    if (doSave) {
      updateUnderwriting(_underwriting);
    }
  };

  const onApproveAndSubmit = async () => {
    const _underwriting = cloneDeep(underwriting);
    _underwriting.isApproved = true;
    setLoading(true);
    try {
      await updateUnderwriting(_underwriting);
      navigate(`/customers/${customerId}/performance/underwriting`);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box minW={1087} maxW={'max'} fontSize={14} mt={-10}>
      <BackLink
        text={'Back to reports table'}
        path={`/customers/${customerId}/performance/underwriting`}
      />
      <Card mt={'100px'}>
        <Heading
          variant={'sectionHeader'}
          borderColor={'twelve.green.300'}
          mb={10}
        >
          Create an Underwriting Report
        </Heading>
        <Box>
          <UnderwritingSettings
            underwriting={underwriting}
            onSettingsChange={onSettingsChange}
            tearsheets={tearsheets}
            getUserName={getUserName}
            onGenerateQuestions={onGenerate}
            areSettingsValid={areSettingsValid}
            isViewMode={underwriting.isApproved}
            customerId={customerId}
          />
        </Box>
        <Collapse in={!!questions}>
          <Box mt={'50px'}>
            <Flex justify={'end'} mb={2}>
              <Button variant={'outline'} onClick={onAcceptAllClick}>
                Confirm All
              </Button>
            </Flex>
            <UnderwritingQuestions
              questions={questions}
              onAnswerChange={onAnswerChange}
              onAnswerCommentChange={onAnswerCommentChange}
              complianceItems={underwriting.complianceItems}
              isViewMode={underwriting.isApproved}
            />
            <Center h={'60px'}>
              <Divider />
            </Center>
            <UnderwritingAuthorization
              underwriting={underwriting}
              onSettingsChange={onSettingsChange}
              isViewMode={underwriting.isApproved}
              getUserName={getUserName}
            />
            {!underwriting.isApproved && (
              <Button
                variant={'brand'}
                w={'max'}
                mt={'50px'}
                onClick={onApproveAndSubmit}
                isDisabled={!areComplianceItemsValid || !areSettingsValid}
              >
                APPROVE & SUBMIT
              </Button>
            )}
          </Box>
        </Collapse>
      </Card>
    </Box>
  );
};

Underwriting.propTypes = {};

export default Underwriting;
